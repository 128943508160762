import React, { ReactNode } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type PreviewFormProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  quizName: string;
  initialInformation: string;
  children: ReactNode;
};

function PreviewFormModal({
  show,
  setShow,
  quizName,
  initialInformation,
  children
}: PreviewFormProps) {
  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "650px",
          height: "90vh",
          borderRadius: "20px",
          boxShadow: 24,
          border: "2px solid #000000",
          backgroundImage: "linear-gradient(#1a1919, #000000)",
          padding: "10px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: "#fff",
            borderRadius: 8,
            position: "absolute",
            top: "2px",
            right: "20%",
            marginTop: "15px",
            marginBottom: "35px"
          }}
        />
        <Box
          style={{
            overflow: "hidden",
            width: "95%",
            height: "80vh",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: "#FFFFFF",
            margin: "15px",
            marginTop: "40px"
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              backgroundColor: "black",
              color: "white",
              padding: 20,
              fontWeight: "bold",
              borderRadius: "5px",
              marginBottom: "20px"
            }}
          >
            {quizName}
          </Typography>
          <Box
            sx={{
              maxHeight: "70vh",
              overflowY: "auto",
              paddingBottom: 1
            }}
          >
            {initialInformation && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h3"
                style={{
                  borderRadius: "5px"
                }}
              >
                {initialInformation}
              </Typography>
            )}
            {children}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default PreviewFormModal;
