import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CapsuleI, UseSelectorI } from "interfaces";
import store from "app/store/store";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { Card, Col, Form, Row } from "react-bootstrap";
import FormActions from "components/FormActions";
import translate from "@noar-firmenich/commons";
import Swal from "sweetalert2";
import SelectSearch from "components/Selects/SelectSearch";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Loading from "app/pages/home/components/Loading";
import QRCodesGrid from "./QRCodesGrid";
import { QrCode2 } from "@mui/icons-material";

const CapsuleForm = () => {
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const api = new AnanseApiService();
  const schema = Yup.object().shape({
    serialNumber: Yup.string()
      .required(translate(locale).screens.login.validations.required)
      .nullable(),
    dueDate: Yup.date().required(
      translate(locale).screens.login.validations.required
    )
  });

  const [campaignSelected, setCampaignSelected] = useState(
    {} as { campaignId: number; name: string }
  );
  const [loadCampaign, setLoadCampaign] = useState(false);
  const [collections, setCollections] = React.useState<Array<any>>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allowSave, setAllowSave] = React.useState(true);
  const [qrCodes, setQRCodes] = React.useState<Array<any>>([]);
  const [showQRCodes, setShowQRCodes] = React.useState(false);
  const [deviceTestsInCampaigns, setDeviceTestsInCampaigns] = React.useState<
    any
  >("");
  const [remainingTestsChecked, setRemainingTestsChecked] = React.useState(
    false
  );
  const [macAddress, setMacAddress] = React.useState("");
  const [progress, setProgress] = useState(0);
  const [progressSubmit, setProgressSubmit] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerCode: "",
      deviceId: 0,
      fragranceId: 0,
      dueDate: "",
      updatedAt: "",
      deletedAt: "",
      serialNumber: "",
      remainingShots: 0,
      performedShots: 0
    }
  });

  React.useEffect(() => {
    loadingData();
  }, []);

  const loadingData = async () => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: "settings"
      });
      setValue("customerCode", response.customerCode);
    } catch (error) {}
  };

  const updateDeviceByMacAddress = async () => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `devices/mac/${macAddress}`
      });

      const deviceInfo = response;
      const deviceId = deviceInfo.deviceId;

      const updateResponse = await api.makeHttpRequest({
        method: "PUT",
        url: `devices/${deviceId}`,
        data: {
          remainingTests: deviceTestsInCampaigns
        }
      });
    } catch (error) {
      console.error("Erro ao atualizar remainingTests:", error);
      throw error;
    }
  };

  const checkCapsules = async (serialNumber: string, fragranceId: number) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/serial/${serialNumber}/${fragranceId}`
      });
      return { status: false, fragranceId, serialNumber };
    } catch (error) {
      return { status: true, fragranceId, serialNumber };
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let reqBase: any;
      const totalItems = collections.filter(value => value.selected).length;
      let processedCount = 0;

      reqBase = { method: "POST", url: "/capsules" };
      for (const value of collections) {
        const body: CapsuleI = {
          customerCode: data.customerCode,
          deviceId: data.deviceId,
          dueDate: data.dueDate,
          fragranceId: parseInt(value.fragranceId),
          remainingShots: 100,
          serialNumber: data.serialNumber,
          performedShots: 0
        };
        if (value.selected) {
          await api.makeHttpRequest({
            ...reqBase,
            data: body
          });
          processedCount++;
          const currentProgress = Math.ceil(
            (processedCount / totalItems) * 100
          ); // Calculating progress as a percentage
          setProgressSubmit(currentProgress);
        }
      }

      if (remainingTestsChecked) {
        await updateDeviceByMacAddress();
      }

      Swal.fire({
        title: translate(locale).defaultMessages?.success,
        text: translate(locale).crudMessages.successText,
        icon: "success"
      });
    } catch (e) {
      Swal.fire(
        translate(locale)?.defaultMessages?.error,
        translate(locale)?.screens.user.errors.register,
        "error"
      );
    } finally {
      setAllowSave(true);
      setLoading(false);
      setProgressSubmit(0); // Reset progress to zero when finished
    }
  };

  const handleReset = () => {
    reset({});
  };
  const handleChangeSeachCampaign = async (campaign: any) => {
    setLoadCampaign(true);
    const url = `/campaigns/${campaign.campaignId}`;
    const reqBase = { method: "GET", url };
    const resposne = await api.makeHttpRequest({
      ...reqBase
    });
    handleChangeSelect(resposne);
    setLoadCampaign(false);
  };
  const handleChangeSelect = (e: any) => {
    const deviceTests = e.deviceTests;
    const newData = e.collection
      .map((item: any) => {
        return {
          fragranceId: item.fragranceId,
          slot: item.slot,
          name: item.fragrance.name,
          selected: false
        };
      })
      .sort((a: any, b: any) => a.slot - b.slot);
    setCampaignSelected({ campaignId: e.campaignId, name: e.name });
    setCollections(newData);
    setDeviceTestsInCampaigns(deviceTests);
  };

  const handleSearchDevice = async (e: any) => {
    setValue("deviceId", e.id);
    setMacAddress(e.label);
    handleChangeSeachCampaign(e.obj.campaign);
  };

  const handleCheckboxChange = (itemId: any) => {
    const updatedItems: Array<any> = collections.map((item: any) => {
      if (item.fragranceId === itemId) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setCollections(updatedItems);
  };

  const handleSelectAll = (event: any) => {
    const { checked } = event.target;
    if (checked) {
      const allItems = collections.map((item: any) => {
        return { ...item, selected: true };
      });
      setCollections(allItems);
      setSelectAll(true);
    } else {
      const allItems = collections.map((item: any) => {
        return { ...item, selected: false };
      });
      setCollections(allItems);
      setSelectAll(false);
    }
  };

  const checkItemsInAPI = React.useCallback(async () => {
    setLoading(true);
    const { serialNumber } = getValues();
    let prepareData = collections.filter(item => item.selected && serialNumber);
    const totalItems = prepareData.length;
    let processedCount = 0;

    for (const [index, item] of prepareData.entries()) {
      try {
        delete item.deselect;
        const response = await checkCapsules(
          serialNumber,
          parseInt(item.fragranceId)
        );
        const itemExists = response.status;
        if (!itemExists) {
          prepareData[index].selected = response.status;
          prepareData[index].deselect = response.status;
        }
      } catch (error) {
        console.error("Erro ao verificar o item na API", error);
      }
      processedCount++;
      const currentProgress = Math.ceil((processedCount / totalItems) * 100); // Calculating progress as a percentage
      setProgress(currentProgress);
    }

    setCollections(prepareData);
    setAllowSave(false);
    setLoading(false);
    setProgress(0); // Reset progress to zero when finished

    const filterP = prepareData.filter(item => item.deselect === false);
    if (filterP.length > 0) {
      Swal.fire({
        title: translate(locale).defaultMessages?.error,
        text: translate(locale).screens.capsule.messages.validatedCapsules,
        icon: "info"
      });
      setSelectAll(false);
    }
    if (filterP.length === totalItems) {
      setAllowSave(true);
    }
  }, [collections]);

  React.useEffect(() => {
    const isSelected = collections.filter(
      (item: any) => item.selected === true
    );
    if (isSelected.length === collections.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    if (isSelected.length < 1) {
      setAllowSave(true);
      setSelectAll(false);
    }
  }, [collections, allowSave]);

  const checkCampos = React.useCallback(() => {
    const { deviceId, serialNumber } = getValues();
    const isSelected = collections.filter(
      (item: any) => item.selected === true
    );
    if (serialNumber && deviceId && isSelected.length > 0) {
      return false;
    }
    return true;
  }, [getValues, collections]);

  const handleGenerateQRCodes = (data: any) => {
    const combinedData = collections.map(item => ({
      ...item,
      customerCode: data.customerCode,
      dueDate: data.dueDate,
      serialNumber: data.serialNumber
    }));
    setQRCodes(combinedData);
    setShowQRCodes(true);
  };

  return (
    <div>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={
                    translate(locale).screens.capsule?.labels?.serial
                  }
                  disabled
                  {...register("customerCode")}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate(locale).screens.capsule?.labels?.serial} *
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={9}
                  minLength={9}
                  placeholder={
                    translate(locale).screens.capsule?.labels?.serial
                  }
                  {...register("serialNumber")}
                />
                {errors.serialNumber && (
                  <span className="invalid-input">
                    {errors.serialNumber.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate(locale).screens.capsule?.labels?.dueDate}
                </Form.Label>

                <Controller
                  name="dueDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      value={value ? value.split("T")[0] : value}
                      onChange={(value: any) => onChange(value.target.value)}
                    />
                  )}
                />
                {errors.dueDate && (
                  <span className="invalid-input">
                    {errors.dueDate.message}
                  </span>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate(locale).screens.device.title} *
                </Form.Label>
                <SelectSearch
                  label={translate(locale).screens.device.labels.select}
                  required={true}
                  className="kt-width-full"
                  url={`devices?FilterString=`}
                  handleChange={(e: any) => handleSearchDevice(e)}
                  convertObject={(obj: any) => ({
                    id: obj.deviceId,
                    value: obj.deviceId,
                    label: `${obj.macAddress}`,
                    obj
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate(locale).screens.quiz.labels.campaign} *
                </Form.Label>
                <Form.Control
                  type="text"
                  value={campaignSelected.name}
                  disabled
                />
              </Form.Group>
            </Row>
            <Divider color="error" />
            {collections.length ? (
              <Box sx={{ flexGrow: 1, marginTop: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Checkbox
                    color="success"
                    value="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <Typography>{translate(locale).labels.selectAll}</Typography>
                </Stack>
                <Divider light />
                <Grid container spacing={2}>
                  {collections.map((item: any) => (
                    <Grid key={item.fragranceId} xs={3}>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Checkbox
                          color="success"
                          value={item.selected}
                          checked={item.selected}
                          onChange={() =>
                            handleCheckboxChange(item.fragranceId)
                          }
                        />
                        <Button
                          sx={{ cursor: "none", color: "#000" }}
                          variant="text"
                          color="primary"
                          onClick={() => handleCheckboxChange(item.fragranceId)}
                        >
                          {item.slot + " - " + item.name}
                        </Button>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : null}
          </Card.Body>
          <Card.Footer
            style={{
              backgroundColor: "#fff"
            }}
            className="border-0"
          >
            <Stack
              sx={{ marginY: "5px" }}
              justifyContent="flex-start"
              alignItems="flex-end"
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
            >
              <Button
                variant="contained"
                color="info"
                disabled={checkCampos()}
                onClick={checkItemsInAPI}
              >
                {translate(locale).screens.capsule.labels.validateCapsules}
              </Button>
            </Stack>
          </Card.Footer>
          <FormActions
            module="capsules"
            disableSubmit={allowSave}
            onReset={handleReset}
          />
        </Form>
      </Card>
      <Loading
        isLoading={loading || loadCampaign}
        msg={loadCampaign ? translate("screens_login_loadingcampaign") : ""}
        progress={progress || progressSubmit}
      />
    </div>
  );
};

export default CapsuleForm;
