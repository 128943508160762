import { DialogTitle } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import React from "react";

interface Props {
  type: string;
  showMedia: boolean;
}

const MediaTitleComponent = ({ type, showMedia }: Props) => {
  const translate = useTranslation();

  let titleKey;
  if (type === "image") {
    titleKey = showMedia
      ? translate("screenApp.quiz.images.imagesAnswers")
      : translate("screenApp.quiz.images.addImagesResponses");
  } else if (type === "audio") {
    titleKey = showMedia
      ? translate("labels_answerAudio")
      : translate("labels_add_answerAudio");
  } else if (type === "video") {
    titleKey = showMedia
      ? translate("labels_answerVideo")
      : translate("labels_add_answerVideo");
  }

  return <DialogTitle textAlign={"center"}>{titleKey}</DialogTitle>;
};

export default MediaTitleComponent;
