import React from "react";
import { connect } from "react-redux";
import { TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";

import Datatable from "../../../../partials/datatable/Datatable";
import { CapsulesApiService } from "../../../../services/Ananse/CapsulesApiService";
import CrudActions from "app/partials/datatable/CrudActions";
import store from "app/store/store";

class CapsulesListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new CapsulesApiService();
    this.setCapsules = this.setCapsules.bind(this);
  }

  state = {
    capsules: [],
    reload: false
  };

  setCapsules(capsules) {
    this.setState({ capsules });
  }

  render() {
    window.setPageTitle(
      `${this.props.translate.screens.capsule?.title || "Capsule"} - Admin`
    );

    const headRows = [
      { label: this.props.translate.screens.dashboard.action },
      {
        column: "SerialNumber",
        label: this.props.translate.screens.capsule?.labels?.serial
      },
      {
        column: "Fragrance.Name",
        label: this.props.translate.screens.fragrance?.list?.title
      },
      {
        column: "RemainingShots",
        label: this.props.translate.screens.capsule?.labels?.remaining
      },
      {
        column: "Device.MacAddress",
        label: this.props.translate.screens.device?.labels?.macAddress
      },
      {
        column: "DueDate",
        label: this.props.translate.screens.capsule?.labels?.dueDate
      },
      {
        column: "CreatedAt",
        label: this.props.translate.screens.quiz.list.created
      },
      {
        column: "UpdatedAt",
        label: this.props.translate.screens.quiz.list.updated
      }
    ];

    const { auth } = store.getState();
    const isDiffAdmin =
      auth && auth.user && auth.user.profile.nome.toUpperCase() === "ADMIN";

    const formatRow = r => {

      const showButtons = r.deletedAt === null && isDiffAdmin;
      return (
        <TableRow hover tabIndex={-1} key={r.deviceId}>
          <TableCell scope="row">
            {showButtons && (
              <Link
                to={{
                  pathname: `/admin/capsules/update/${r.serialNumber}-${r.fragranceId}`,
                  state: { data: r }
                }}
              >
                <IconButton
                  aria-label="upload picture"
                  component="label"
                  size="small"
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </Link>
            )}
          </TableCell>
          <TableCell scope="row">{r.serialNumber}</TableCell>
          <TableCell scope="row">{r.fragrance?.name}</TableCell>
          <TableCell scope="row">{r.remainingShots}</TableCell>
          <TableCell scope="row">{r.device?.macAddress}</TableCell>
          <TableCell scope="row">
            {moment(r.dueDate).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell scope="row">
            {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    const buttons = isDiffAdmin
      ? [
          {
            label: this.props.translate.datatable.add,
            onClick: null,
            icone: "add",
            path: "/admin/capsules/create"
          }
        ]
      : [];
    return (
      <Datatable
        title={this.props.translate.screens.capsule?.title}
        endpoint="/capsules"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.capsules}
        setRows={this.setCapsules}
        reload={this.state.reload}
        placeholderSearch={
          this.props.translate.screens.capsule?.placeholders?.filter
        }
        buttons={buttons}
      />
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(CapsulesListing);
