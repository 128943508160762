import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { format, addHours } from "date-fns";

import FormActions from "../../components/FormActions";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import verifyCpfCnpj from "../../../../utils/validators/IsValidCpfCnpj";
import verifyCEP from "../../../../utils/validators/IsValidCEP";
import verifyBirthday from "../../../../utils/validators/IsValidBirthday";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import Loading from "../../../home/components/Loading";
import withFormValidation from "../../../../hoc/withFormValidation";
import countriesBR from "../../components/Data/countriesBR.json";
import countriesEN from "../../components/Data/countriesEN.json";
import { AppInput } from "../../../../partials/form";

const initialState = {
  name: "",
  tin: "",
  document: "",
  profile: "",
  profiles: [],
  email: "",
  mobilePhone: "",
  resetInput: false,
  isLoading: false,
  newOption: false,
  showCities: false,
  showRegions: false,
  deletedAt: null,
  birthday: "",
  genre: "",
  genres: [],
  zipcode: "",
  street: "",
  city: "",
  cityLabel: "",
  regions: [],
  region: "",
  regionLabel: "",
  cities: [],
  regionCode: "",
  country: "",
  countryCode: "",
  countries: [],
  nextPaymentDate: null,
  settingsPassword: "",
  showPassword: false
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new AnanseApiService();

    this.viaCepUrl = "https://viacep.com.br";
    this.geoDbUrl = "http://geodb-free-service.wirefreethought.com/v1/geo";
    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const genres = [
        {
          key: "M",
          name: this.props.translate.screens.user.male
        },
        {
          key: "F",
          name: this.props.translate.screens.user.feminine
        },
        {
          key: "O",
          name: this.props.translate.screens.user.other
        }
      ];

      const cityLabel = this.props.translate.screens.user.placeholders.city;
      const regionLabel = this.props.translate.screens.user.placeholders.region;

      this.setState({ genres, cityLabel, regionLabel });

      const reqs = [this.loadProfiles()];

      if (this.props.match.params.id) {
        reqs.push(this.loadUser(this.props.match.params.id));
      }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        this.props.translate.defaultMessages.loadData,
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadUser = async id => {
    const user = await this.api.makeHttpRequest({
      url: `/usuario/${id}`
    });

    this.setState(
      {
        name: user.nome,
        tin: user.cpf ? user.cpf : "",
        document: user.document ? user.document : "",
        email: user.email,
        mobilePhone: user.mobilePhone,
        profile: user.profile.nome,
        ativo: user.ativo,
        birthday:
          user.birthday !== null
            ? format(addHours(new Date(user.birthday), 5), "yyyy-MM-dd")
            : "",
        genre: user.genre !== null ? user.genre : "",
        zipcode:
          user.address !== null && user.address.zipcode !== null
            ? user.address.zipcode
            : "",
        street:
          user.address !== null && user.address.street !== null
            ? user.address.street
            : "",
        country:
          user.address !== null && user.address.country !== null
            ? user.address.country
            : "",
        deletedAt: user.deletedAt,
        nextPaymentDate:
          user.nextPaymentDate !== null
            ? format(addHours(new Date(user.nextPaymentDate), 5), "yyyy-MM-dd")
            : ""
      },
      () => {
        if (user.address) {
          var country = countriesBR.find(
            data => data.code === this.state.country
          )
            ? countriesBR.find(data => data.code === this.state.country)
            : countriesEN.find(data => data.code === this.state.country);

          this.setState(
            {
              countryCode: country.code
            },
            () => {
              this.loadRegions();
              this.setState(
                {
                  region: user.address.state ? user.address.state : "",
                  regionCode: user.address.state ? user.address.state : ""
                },
                () => {
                  if (this.state.region !== "") {
                    this.loadCities();
                    this.setState({
                      city: user.address.city ? user.address.city : ""
                    });
                  }
                  return;
                }
              );
              return;
            }
          );
        }
      }
    );
  };

  loadProfiles = async () => {
    const profiles = await this.api.makeHttpRequest({
      url: "/perfil/"
    });

    this.setState({
      profiles: profiles ? profiles.data : []
    });

    if (profiles.data.length === 1) {
      this.setState({
        profile: profiles.data[0].nome,
        disableProfile: true
      });
    }
  };

  loadCities = async () => {
    this.setState({ cities: [] });

    let limit = 10;
    let offset = 0;
    let resultData = true;
    do {
      var response = await axios.get(
        `${this.geoDbUrl}/countries/${this.state.countryCode}/regions/${this.state.regionCode}/cities?languageCode=${this.props.lang}&limit=${limit}&offset=${offset}&hateoasMode=false`
      );

      const { data } = response.data;

      data.forEach(value => {
        const { id, name } = value;

        let cities = this.state.cities;
        let result = cities.find(city => city.name === name);

        if (!result) {
          cities.push({ id, name });
        }

        this.setState({ cities });
        // return;
      });

      if (!data.length > 0) resultData = false;
      offset += 10;
    } while (resultData);

    this.setState({
      showCities: true,
      cityLabel: this.props.translate.screens.user.placeholders.city
    });
  };

  loadRegions = async () => {
    let limit = 10;
    let offset = 0;
    let resultData = true;
    do {
      var response = await axios.get(
        `${this.geoDbUrl}/countries/${
          this.state.countryCode
        }/regions?limit=${limit}&offset=${offset}&hateoasMode=false${
          this.props.lang === "pt" ? "&languageCode=pt" : ""
        }`
      );
      const { data } = response.data;

      data.forEach(value => {
        const { isoCode, name } = value;

        let regions = this.state.regions;
        regions.push({ isoCode, name });

        this.setState({ regions });
        return;
      });

      if (!data.length > 0) resultData = false;
      offset += 10;
    } while (resultData);

    this.setState({
      showRegions: true,
      regionLabel: this.props.translate.screens.user.placeholders.region
    });
  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/usuario/${id}` }
        : { method: "POST", url: "/usuario" };

      const {
        name,
        tin,
        document,
        email,
        mobilePhone,
        profile,
        genre,
        birthday,
        zipcode,
        street,
        city,
        region,
        country,
        deletedAt,
        nextPaymentDate,
        settingsPassword
      } = this.state;

      const address =
        country !== ""
          ? { zipcode, street, city, state: region, country }
          : null;
      const prepareUser = {
        email,
        mobilePhone,
        nome: name,
        cpf: tin !== "" ? tin : null,
        document: document !== "" ? document : null,
        ativo: !id ? false : deletedAt ? false : true,
        profile,
        birthday,
        genre,
        address,
        nextPaymentDate,
        settingsPassword
      };

      await this.api.makeHttpRequest({
        ...reqBase,
        data: prepareUser
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        e.response && e.response.status === 400
          ? e.response.data
          : this.props.translate.screens.user.errors.register,
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true,
      resetInputFilial: true
    });

    const isEditing = !!this.props.match.params.id;

    const { value } = await Swal.fire({
      title: this.props.translate.defaultMessages.success,
      text: this.props.translate.defaultMessages.saveData,
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing
        ? "Ok"
        : this.props.translate.buttons.newRegister,
      cancelButtonText: this.props.translate.buttons.exit
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/users");
    }
  };

  handleChange = async e => {
    if (e.target.name === "tin" || e.target.name === "document") {
      this.setState({
        document: "",
        tin: ""
      });
    }

    if (
      e.target.name === "zipcode" &&
      this.state.country === "BR" &&
      verifyCEP(e.target.value).isValid
    ) {
      this.loadAddressBr(e.target.value);
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  loadAddressBr = async zipcode => {
    const test = "/[^0-9]/";
    const response = await axios.get(
      `${this.viaCepUrl}/ws/${zipcode.replace(test, "")}/json`
    );

    if (response && response.data?.uf) {
      this.setState(
        {
          region: response.data.uf,
          regionCode: response.data.uf,
          city: response.data.localidade
        },
        () => {
          this.setState({
            cityLabel: this.props.translate.defaultMessages.loading,
            showCities: false
          });
          this.loadCities();
        }
      );
    }
  };

  formIsValid = () => {
    return (
      IsValidName(this.state.name) &&
      IsValidEmail(this.state.email) &&
      this.state.profile &&
      (this.state.birthday
        ? verifyBirthday(this.state.birthday, this.props.lang).isValid
        : true) &&
      (this.state.tin && this.state.country === "BR"
        ? verifyCpfCnpj(this.state.tin).isValid
        : true) &&
      (this.state.zipcode && this.state.country === "BR"
        ? verifyCEP(this.state.zipcode).isValid
        : true)
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });
      this.setState({
        city: "",
        region: "",
        zipcode: "",
        street: "",
        showCities: false,
        showRegions: false
      });

      try {
        await this.loadUser(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          this.props.translate.defaultMessages.error,
          this.props.translate.defaultMessages.cleanForm,
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true,
        resetInputFilial: true
      });

      if (this.state.profiles.length === 1) {
        this.setState({
          profile: this.state.profiles[0].none,
          disableProfile: true
        });
      }
    }
  };

  handleSelectCountry = e => {
    this.setState({
      region: "",
      regions: [],
      city: "",
      cities: [],
      regionCode: "",
      countryCode: "",
      street: "",
      zipcode: "",
      document: "",
      tin: "",
      showRegions: false,
      showCities: false
    });

    if (e.target.value === "") {
      this.setState({ country: "" });
      return;
    }

    const result =
      this.props.lang === "pt"
        ? countriesBR.find(data => data.code === e.target.value)
        : countriesEN.find(data => data.code === e.target.value);

    this.setState(
      {
        country: e.target.value,
        countryCode: result?.code,
        regionLabel: this.props.translate.defaultMessages.loading
      },
      () => {
        this.loadRegions();
      }
    );
  };

  handleSelectRegion = e => {
    this.setState({
      region: "",
      city: "",
      showCities: false
    });

    if (e.target.value === "") {
      this.setState({ region: "" });
      return;
    }

    const result = this.state.regions.find(
      data => data.isoCode === e.target.value
    );
    this.setState(
      {
        region: e.target.value,
        regionCode: result.isoCode,
        showCities: false,
        cityLabel: this.props.translate.defaultMessages.loading
      },
      () => {
        this.loadCities();
      }
    );
  };

  handleChangeDate = event => {
    this.setState({ nextPaymentDate: event.target.value });
  };

  handleChangeSettingsPassword = event => {
    this.setState({ settingsPassword: event.target.value });
  };

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${this.props.translate.screens.user.pageTitle.edit} - Admin`
      );
    } else {
      window.setPageTitle(
        `${this.props.translate.screens.user.pageTitle.create} - Admin`
      );
    }

    const {
      name,
      tin,
      email,
      mobilePhone,
      profiles,
      profile,
      disableProfile,
      genre,
      genres,
      zipcode,
      street,
      regionLabel,
      region,
      regions,
      birthday,
      city,
      cities,
      showCities,
      showRegions,
      cityLabel,
      country,
      document,
      nextPaymentDate,
      settingsPassword,
      showPassword
    } = this.state;

    const filteredProfiles =
      this.props.user.profile.nome === "SUPER-USER"
        ? profiles.filter(p => p.nome !== "ADMIN")
        : profiles;

    return (
      <Card className="mt-3">
        <Loading isLoading={this.state.isLoading} />
        <Card.Header>{this.props.translate.screens.user.mainData}</Card.Header>
        <Card.Body>
          <Form onSubmit={this.submit}>
            {/* row 1 */}
            <Row>
              {/* Nome */}
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.name} *
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="name"
                  placeholder={
                    this.props.translate.screens.user.placeholders.name
                  }
                  maxLength="200"
                  value={name}
                  isInvalid={name && !IsValidName(name)}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>

              {/* Email */}
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.email} *
                </Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  name="email"
                  placeholder={
                    this.props.translate.screens.user.placeholders.email
                  }
                  maxLength="200"
                  value={email}
                  isInvalid={email && !IsValidEmail(email)}
                  onChange={this.handleChange}
                  required
                />
                <span style={{ color: "red" }}>
                  {email && !IsValidEmail(email)
                    ? this.props.translate.screens.user.errors.invalidEmail
                    : ""}
                </span>
              </Form.Group>

              {/* Perfil */}
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.profile} *
                </Form.Label>
                <Form.Control
                  name="profile"
                  value={profile}
                  disabled={disableProfile}
                  onChange={this.handleChange}
                  as="select"
                >
                  <option value="">
                    {this.props.translate.screens.user.placeholders.profile}
                  </option>
                  {filteredProfiles.map(p => (
                    <option key={p.id} value={p.nome}>
                      {p.nome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>

            {/* row 2 */}

            <Row>
              {/* Phone */}
              {/* <Form.Group as={Col} lg="2" xs="12">
                <Form.Label>Fone "ex.: +CCDDYYYYYXXXX"</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="mobilePhone"
                  placeholder={
                    this.props.translate.screens.user.placeholders.email
                  }
                  maxLength="200"
                  value={mobilePhone}
                  onChange={this.handleChange}
                  // required
                />
              </Form.Group> */}
              {/* Documento */}
              {/* <Form.Group as={Col} lg="2" xs="12">
                <Form.Label>
                  {country === "BR"
                    ? this.props.translate.screens.user.labels.cpf
                    : this.props.translate.screens.user.labels.document}
                </Form.Label>
                {country === "BR" ? (
                  <>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      name="tin"
                      placeholder={
                        this.props.translate.screens.user.placeholders.cpf
                      }
                      maxLength="14"
                      value={verifyCpfCnpj(tin).cpfCnpjFormat}
                      isInvalid={!verifyCpfCnpj(tin).isValid}
                      onChange={this.handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {verifyCpfCnpj(tin).error}
                    </span>
                  </>
                ) : (
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="document"
                    placeholder={
                      this.props.translate.screens.user.placeholders.document
                    }
                    maxLength="14"
                    value={document}
                    onChange={this.handleChange}
                  />
                )}
              </Form.Group> */}

              {/* Data de Nascimento */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.birthday}
                </Form.Label>
                <Form.Control
                  type="date"
                  autoComplete="off"
                  value={birthday}
                  name="birthday"
                  isInvalid={!verifyBirthday(birthday).isValid}
                  onChange={this.handleChange}
                />
                <span style={{ color: "red" }}>
                  {!verifyBirthday(birthday).isValid
                    ? this.props.translate.screens.user.errors.invalidBirthday
                    : ""}
                </span>
              </Form.Group> */}

              {/* Gênero */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.genre}
                </Form.Label>
                <Form.Control
                  name="genre"
                  value={genre}
                  onChange={this.handleChange}
                  as="select"
                >
                  <option value="">
                    {this.props.translate.screens.user.placeholders.genre}
                  </option>
                  {genres.map(p => (
                    <option key={p.key} value={p.key}>
                      {p.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group> */}
            </Row>

            {/* row 3 */}
            <Row>
              {/* Country */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.country}
                </Form.Label>
                <Form.Control
                  name="country"
                  value={country}
                  onChange={this.handleSelectCountry}
                  as="select"
                >
                  <option value="">
                    {this.props.translate.screens.user.placeholders.country}
                  </option>
                  {this.props.lang === "pt"
                    ? countriesBR.map(p => (
                        <option key={Math.random()} value={p.code}>
                          {p.name}
                        </option>
                      ))
                    : countriesEN.map(p => (
                        <option key={Math.random()} value={p.code}>
                          {p.name}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group> */}

              {/* CEP */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.zipCode}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="12"
                  autoComplete="off"
                  disabled={country === ""}
                  isInvalid={
                    country === "BR" && zipcode !== ""
                      ? !verifyCEP(zipcode).isValid
                      : false
                  }
                  name="zipcode"
                  placeholder={
                    this.props.translate.screens.user.placeholders.zipCode
                  }
                  value={
                    country === "BR" && zipcode !== ""
                      ? verifyCEP(zipcode).format
                      : zipcode
                  }
                  onChange={this.handleChange}
                />
                <span style={{ color: "red" }}>
                  {country === "BR" && zipcode !== ""
                    ? verifyCEP(zipcode).error
                    : ""}
                </span>
              </Form.Group> */}

              {/* Region */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.region}
                </Form.Label>
                <Form.Control
                  name="region"
                  value={region}
                  disabled={!showRegions}
                  onChange={this.handleSelectRegion}
                  as="select"
                >
                  <option value="">{regionLabel}</option>
                  {regions.map(p => (
                    <option key={Math.random()} value={p.isoCode}>
                      {p.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group> */}
            </Row>

            {/* row 4 */}
            <Row>
              {/* City */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.city}
                </Form.Label>
                <Form.Control
                  name="city"
                  value={city}
                  disabled={!showCities}
                  onChange={this.handleChange}
                  as="select"
                >
                  <option value="">{cityLabel}</option>
                  {cities.map(p => (
                    <option key={Math.random()} value={p.name}>
                      {p.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Street */}
              {/* <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.street}
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  disabled={country === ""}
                  name="street"
                  placeholder={
                    this.props.translate.screens.user.placeholders.street
                  }
                  maxLength="200"
                  value={street}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {
                    this.props.translate.screenApp.createAccount.profile
                      .nextPaymentDate
                  }{" "}
                  *
                </Form.Label>
                <AppInput
                  type="date"
                  autoComplete="off"
                  value={nextPaymentDate}
                  name="nextPaymentDate"
                  // isInvalid={!verifyBirthday(expirationDate).isValid}
                  onChange={this.handleChangeDate}
                />
              </Form.Group>  */}
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.user.labels.passAccess}*
                </Form.Label>
                <AppInput
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  value={settingsPassword}
                  name="settingsPassword"
                  // isInvalid={!verifyBirthday(expirationDate).isValid}
                  onChange={this.handleChangeSettingsPassword}
                />
                <Form.Group controlId="formShowPassword">
                  <Form.Check
                    type="checkbox"
                    label={this.props.translate.screens.user.labels.showPass}
                    checked={showPassword}
                    onChange={this.toggleShowPassword}
                  />
                </Form.Group>
              </Form.Group>
            </Row>

            <FormActions
              module="users"
              isEdit={!!this.props.match.params.id}
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default withRouter(withFormValidation(UserForm, initialState));
