import React from "react";

import "./Styles.scss";

export default function Br() {
  return (
    <div className="privacy-page">
    <h2>
      <strong>POLÍTICA DE PRIVACIDADE</strong>
    </h2>

    <p>
      Nós, da <strong>NOAR</strong> levamos a sua privacidade e a proteção dos seus dados a sério e estamos
      comprometidos
      em cumprir as
      diretrizes da Lei Geral de Proteção de Dados (LGPD) quanto ao uso dos dados pessoais. Nesse sentido desenvolvemos
      o presente <strong>Aviso de Privacidade</strong>, o qual tem como propósito informar a vocês, usuários e parceiros
      da <strong>NOAR</strong>, como
      tratamos seus dados pessoais, quais são os seus direitos e como você pode exercê-los.
      <br />
      <br />
      Esperamos que as informações auxiliem na compreensão do tratamento de seus dados e que demonstrem o
      compromisso da <strong>NOAR</strong> com a privacidade, proteção de dados e segurança das informações.
      <br />
      <br />
      <br />
      <strong>1. CONCEITOS BÁSICOS: O QUE VOCÊ PRECISA SABER PARA COMPREENDER ESTA POLÍTICA?</strong>
      <br />
      <br />
      A fim de descomplicar a sua leitura deste documento, apresentamos algumas definições úteis para sua interpretação,
      conforme a disposição da Lei nº 13.709/18, também conhecida como Lei Geral de Proteção de Dados, ora LGPD:
      <br />
      <br />
      <strong>Dado Pessoal:</strong> É uma informação relacionada a uma pessoa física e que seja capaz de identificar a
      pessoa ou tornar
      possível a sua identificação. São exemplos de dados pessoais que podem permitir a sua identificação: Nome, CPF,
      telefone, e-mail, placa do seu veículo etc.;
      <br />
      <br />
      <strong>Tratamento:</strong> É toda forma de uso que podemos fazer dos seus Dados Pessoais, incluindo, mas não se
      limitando às
      seguintes atividades: coleta, armazenamento, consulta, uso, compartilhamento, transmissão, classificação,
      reprodução, exclusão e avaliação. Este termo (e outros derivadas dele) não serão utilizados com letras maiúsculas
      neste documento.
      <br />
      <br />
      <strong>Titular:</strong> É você, a pessoa física a quem os Dados Pessoais se referem.
      <br />
      <br />
      <strong>Empresa:</strong> Somos nós, a NOAR
      <br />
      <br />
      <br />
      <strong>2. COMO COLETAMOS OS DADOS PESSOAIS NO APP HEALTH?</strong>
      <br />
      <br />
      Os seus dados pessoais poderão ser coletados através das seguintes ações, a saber:
    <ul>
      <li>Cadastro do usuário no aplicativo HEALTH, no qual coletamos o nome, e-mail, CPF e sexo e telefone do usuário.
      </li>
      <br />
      <li>Cadastro dos pacientes no aplicativo, no qual coletamos o nome, e-mail, CPF e sexo e telefone do usuário.</li>
      <br />
      <li>Localização do usuário no momento da realização de um exame</li>
    </ul>
    <br />
    <br />
    <br />
    <strong>3. PORQUE OS DADOS SÃO COLETADOS ?</strong>
    <br />
    <br />
    Os dados de usuário são coletados para permitir o uso e faturamento do app. Os dados de paciente são cadastrados
    para permitir a emissão dos laudos dos exames realizados.
    <br />
    <br />
    A localização do usuário no momento do teste é utilizada para permitir o tratamento estatístico dos laudos emitidos,
    permitindo a elaboração de pesquisa clínica sobre os seus resultados e seu aprimoramento técnico.
    <br />
    <br />
    <br />
    <strong>4. A NOAR COMPARTILHA OS SEUS DADOS PESSOAIS?</strong>
    <br />
    <br />
    Não, a NOAR não compartilha seus dados pessoais. No caso de uso dos dados coletados em estudos clínicos, é feita uma
    anonimização e agregação das informações, impedindo qualquer identificação pessoal.
    <br />
    <br />
    <br />
    <strong>5. ARMAZENAMENTO DE DADOS PESSOAIS</strong>
    <br />
    <br />
    A NOAR possui uma política de retenção de dados pessoais alinhada com a lei aplicável. Os seus dados serão
    armazenados somente pelo período necessário para o atendimento da finalidade para a qual foram coletados,
    principalmente para a execução do contrato, salvo se houver qualquer outra razão para sua manutenção, como, por
    exemplo, cumprimento de obrigações legais, regulatórias, contratuais e exercício regular de direito.
    <br />
    <br />
    Sempre realizamos uma análise técnica para determinar o período de retenção adequado para cada tipo de dados pessoal
    coletado, considerando sua natureza, necessidade de coleta e finalidade para qual ele será tratado, bem como
    eventuais necessidades de retenção para o cumprimento de obrigações ou o resguardo de direitos.
    <br />
    <br />
    Nos demais casos, iremos proceder à exclusão de nossas bases ou realizar a anonimização, ocasião em que você não
    poderá mais ser identificado.
    <br />
    <br />
    <br />
    <strong>6. COMO PROTEGEMOS OS SEUS DADOS PESSOAIS?</strong>
    <br />
    <br />
    Nossa responsabilidade é cuidar dos seus dados pessoais e utilizá-los somente para as finalidades descritas nesta
    Política. Para garantir a sua privacidade e a proteção dos seus dados pessoais, a NOAR adota todas as medidas
    técnicas e organizacionais para a proteção de seus dados. Para tanto, advertimos que não solicitaremos a sua senha,
    exceto quando você fizer login nos canais oficiais da NOAR, e que você é responsável por manter em sigilo a senha de
    acesso na plataforma.
    <br />
    <br />
    <br />
    <strong>7. DIREITOS DO TITULAR</strong>
    <br />
    <br />
    Os dados pessoais são seus e a lei brasileira, através da Lei Geral de Proteção de Dados – LGPD e demais
    legislações, lhe garante uma série de direitos relacionados a eles. Nós estamos comprometidos com o cumprimento
    desses direitos e vamos, na sequência, explicar quais são esses direitos e como você pode exercê-los:
    <br />
    <br />
    <strong>Confirmação e Acesso:</strong> Você pode solicitar à Empresa a confirmação sobre a existência de tratamento
    dos seus Dados
    Pessoais para que, em caso positivo, você possa acessá-los, inclusive por meio de solicitação de cópias dos
    registros que temos sobre você
    <br />
    <br />
    <strong>Correção:</strong> Você pode solicitar a correção dos seus Dados Pessoais caso estes estejam incompletos,
    inexatos ou
    desatualizados
    <br />
    <br />
    <strong>Anonimização, bloqueio ou eliminação:</strong> Você pode solicitar (a) a anonimização dos seus Dados
    Pessoais, de forma que
    eles não possam mais ser relacionados a você e, portanto, deixem de ser Dados Pessoais; (b) o bloqueio dos seus
    Dados Pessoais, suspendendo temporariamente a sua possibilidade de os tratarmos para certas finalidades; e (c) a
    eliminação dos seus Dados Pessoais, caso em que deveremos apagar todos os seus Dados Pessoais sem possibilidade de
    reversão;
    <br />
    <br />
    <strong>Portabilidade:</strong> Você pode solicitar que a Empresa forneça os seus Dados Pessoais em formato
    estruturado e
    interoperável visando à sua transferência para um terceiro, desde que essa transferência não viole a propriedade
    intelectual ou segredo de negócios da Empresa;
    <br />
    <br />
    <strong>Informações a respeito do compartilhamento:</strong> Você tem o direito de saber quais são as entidades
    públicas e privadas
    com as quais a Empresa realiza uso compartilhado dos seus Dados Pessoais. Manteremos, no item 3 dessa Política, uma
    indicação das nossas relações com terceiros que podem envolver o compartilhamento de Dados Pessoais. Em todo caso,
    se você tiver dúvidas ou quiser mais detalhes, você tem o direito de nos solicitar essas informações. A depender do
    caso, podemos limitar as informações fornecidas a você caso a sua divulgação possa violar a propriedade intelectual
    ou segredo de negócios da Empresa;
    <br />
    <br />
    <strong>Informação sobre a possibilidade de não consentir:</strong> Você tem o direito de receber informações claras
    e completas
    sobre a possibilidade e as consequências de não fornecer consentimento, quando ele for solicitado pela Empresa. O
    seu consentimento, quando necessário, deve ser livre e informado. Portanto, sempre que pedirmos seu consentimento,
    você será livre para negá-lo – nesses casos, é possível que alguns serviços não possam ser prestados;
    <br />
    <br />
    <strong>Revogação do consentimento:</strong> Caso você tenha consentido com alguma finalidade de tratamento dos seus
    Dados Pessoais,
    você pode sempre optar por retirar o seu consentimento. No entanto, isso não afetará a legalidade de qualquer
    Tratamento realizado anteriormente à revogação. Se você retirar o seu consentimento, é possível que fiquemos
    impossibilitados de lhe prestar certos serviços, mas iremos avisá-lo quando isso ocorrer;
    <br />
    <br />
    <strong>Oposição:</strong> A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu consentimento ou um
    contrato conosco. Nessas
    situações, somente trataremos seus Dados Pessoais se tivermos motivos legítimos para tanto, como, por exemplo,
    quando for necessário para garantir a segurança de nossas rodovias. Caso você não concorde com alguma finalidade de
    tratamento dos seus Dados Pessoais, você poderá apresentar oposição, solicitando a sua interrupção.
    <br />
    <br />
    <br />
    <strong>AVISOS IMPORTANTES</strong>
    <br />
    <br />
    <strong>
      Para sua segurança, sempre que você apresentar uma requisição para exercer seus direitos, a Empresa poderá
      solicitar algumas informações e/ou documentos complementares para que possamos comprovar a sua identidade,
      buscando impedir fraudes. Fazemos isso para garantir a segurança e a privacidade de todos.
    </strong>
    <br />
    <strong>
      Em alguns casos, a Empresa pode ter motivos legítimos para deixar de atender a uma solicitação de exercício de
      direitos. Essas situações incluem, por exemplo, casos em que uma revelação de informações específicas poderia
      violar
      direitos de propriedade intelectual ou segredos de negócio da Empresa ou de terceiros, bem como casos em que
      pedidos
      de exclusão de dados não possam ser atendidos em razão da existência de obrigação da Empresa de reter dados, seja
      para cumprir obrigações legais, regulatórias ou para possibilitar a defesa da Empresa ou de terceiros em disputas
      de
      qualquer natureza.
    </strong>
    <br />
    <br />
    <strong>
      Ainda, algumas solicitações podem não ser respondidas de forma imediata, mas a Empresa se compromete a responder
      todas as requisições em um prazo razoável e sempre em conformidade com a legislação aplicável.
    </strong>
    <br />
    <br />
    Caso você tenha alguma dúvida sobre essas questões ou sobre como você pode exercer seus direitos, fique à vontade
    para entrar em contato conosco por meio dos canais informados ao final desta Política.
    <br />
    <br />
    <br />
    <strong>8. TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS</strong>
    <br />
    <br />
    Alguns dados poderão ser armazenados em outros países, já que a NOAR utiliza ou utilizará o sistema baseado em Cloud
    Computing. Em sendo o caso, serão atendidas todas as normas referentes à proteção de dados.
    <br />
    <br />
    <br />
    <strong>9. CANAL DE COMUNICAÇÃO</strong>
    <br />
    <br />
    Se você acredita que seus dados pessoais foram tratados de maneira incompatível com esta Política ou com as suas
    escolhas quanto Titular dos seus dados pessoais, ou, ainda, caso tenha dúvidas, comentários ou sugestões
    relacionadas a este Aviso de Privacidade, solicitamos que seja realizado contato diretamente com nosso
    <strong>Encarregado
      de Dados (DPO)</strong>, através do endereço de e-mail <strong>“contato@noarbrasil.com.br”</strong> ou pelo
    <strong>telefone nº 11 2361-5163.</strong>
    <br />
    <br />
    <br />
    <strong>10. ATUALIZAÇÃO DO AVISO DE PRIVACIDADE</strong>
    <br />
    <br />
    Como estamos sempre buscando melhorar nossos serviços e a forma como operamos, o presente Aviso de Privacidade
    poderá ser atualizado a qualquer tempo pela XXXXX para refletir as melhorias realizadas. Desta forma, recomendamos a
    visita periódica desta página para que você tenha conhecimento sobre as modificações efetivadas.
    </p>
  </div>
  );
}
