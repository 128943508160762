import React, { useState, useEffect, useCallback } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  Stack,
  SelectChangeEvent
} from "@mui/material";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";

export type IConvertData = {
  id: number;
  value: number;
  label: string;
  obj: any;
};

interface Props {
  endPoint: string;
  label: string;
  convertObject?: any;
  pagination?: boolean;
  value?: any;
  setLoading?: any;
  notPerPage?: boolean;
  formList?: Array<IConvertData>;
  totalPages?: number;
  handleChange: (e?: IConvertData) => void;
}
const ExampleComponent = ({
  convertObject,
  endPoint,
  label,
  pagination,
  value,
  setLoading,
  notPerPage,
  formList,
  totalPages: upperTotalPages = 1,
  handleChange
}: Props) => {
  const [data, setData] = React.useState<IConvertData[] | undefined>(formList);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(upperTotalPages);

  const api = new AnanseApiService();

  const fetchData = useCallback(async () => {
    let searchedList: Array<IConvertData> = [];
    const response = await api.makeHttpRequest({
      method: "GET",
      url: notPerPage
        ? endPoint
        : `${endPoint}&Page=${currentPage}&PerPage=${perPage}`
    });
    const formList = notPerPage ? response : response.data;

    formList.forEach((item: any) => {
      const converted = convertObject(item);
      if (searchedList.indexOf(converted) === -1) {
        searchedList.push(converted);
      }
    });

    setData(searchedList.sort((a, b) => a.label.localeCompare(b.label)));
    if (pagination) {
      setTotalPages(response.totalPages);
    }
  }, [endPoint]);

  useEffect(() => {
    if (!formList) {
      fetchData();
    }
  }, [currentPage, perPage, fetchData]);

  const handlecharge = (event: SelectChangeEvent<any>) => {
    const filter = data?.find(item => item.id === event.target.value);
    handleChange(filter);
  };

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handlePerPageChange = (event: any) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
      <Stack spacing={2}>
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value ?? ""}
          onChange={handlecharge}
        >
          <MenuItem
            key={0}
            value={0}
            selected={0 === value}
            style={{
              backgroundColor: 0 === value ? "#ccc" : "transparent"
            }}
          >
            {label}
          </MenuItem>
          {data?.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.id}
              selected={item.id === value}
              style={{
                backgroundColor: item.id === value ? "#ccc" : "transparent"
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {pagination && (
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Select
              autoWidth
              label="Age"
              value=""
              onChange={handlePerPageChange}
            >
              <MenuItem value={5}>5 por página</MenuItem>
              <MenuItem value={10}>10 por página</MenuItem>
              <MenuItem value={20}>20 por página</MenuItem>
            </Select>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default ExampleComponent;
