import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import { FragranceApiService } from "../../../../services/Ananse/FragranceApiService";

class FragrancesListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new FragranceApiService();
    this.state = {
      fragrances: [],
      reload: false,
      isActive: "true"
    };

    this.setFragrances = this.setFragrances.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
  }

  setFragrances(fragrances) {
    this.setState({ fragrances });
  }

  async onConfirmChangeStatus(fragrance) {
    if (fragrance.deletedAt) {
      await this.api.activate(fragrance.fragranceId);
    } else {
      await this.api.delete(fragrance.fragranceId);
    }

    this.setState({ reload: !this.state.reload });
  }

  render() {
    window.setPageTitle(
      `${this.props.translate.screens.fragrance.list.title} - Admin`
    );

    const headRows = [
      { label: this.props.translate.screens.fragrance.list.actions },
      { column: "FragranceId", label: this.props.translate.defaultMessages.id },
      {
        column: "Name",
        label: this.props.translate.screens.fragrance.list.name
      },
      {
        column: "Category.Name",
        label: this.props.translate.screens.fragrance.list.category
      },
      {
        column: "CreatedAt",
        label: this.props.translate.screens.fragrance.list.created
      }
    ];

    const formatRow = r => {
      const actions = [];
      if (!r.deletedAt) {
        actions.push("edit");
      }

      if (r.deletedAt || !r.collections || !r.collections.length) {
        actions.push("toggle");
      }

      return (
        <TableRow hover tabIndex={-1} key={r.fragranceId}>
          <TableCell>
            <CrudActions
              actions={actions}
              module="fragrances"
              onConfirmChangeStatus={this.onConfirmChangeStatus}
              id={r.fragranceId}
              obj={r}
            />
          </TableCell>
          <TableCell scope="row">
            {r.fragranceId.toString().padStart(4, "0")}
          </TableCell>
          <TableCell scope="row">{r.name}</TableCell>
          <TableCell scope="row">{r.category ? r.category.name : ""}</TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Datatable
        title={this.props.translate.screens.fragrance.list.title}
        endpoint="/fragrances"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.fragrances}
        setRows={this.setFragrances}
        reload={this.state.reload}
        placeholderSearch={
          this.props.translate.screens.fragrance.placeholders.search
        }
        isActive={this.state.isActive}
        buttons={[
          {
            label: this.props.translate.datatable.add,
            onClick: null,
            icone: "add",
            path: "/admin/fragrances/create"
          }
        ]}
      />
    );
  }
}

export default FragrancesListing;
