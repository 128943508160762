import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media(max-width: 600px) {
    margin: 0 20px;
  }

  @media(min-width: 992px) {
    height: 800px;
    width: 800px;
  }

  @media(min-width: 768px) {
    height: 600px;
    width: 600px;
  }

  @media (min-width: 576px) {
    height: 400px;
    width: 400px;
  }

  @media (max-width: 576px) {
    height: 300px;
    width: 300px;
  }

  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 150%;
`;

export const defaultInnerWidth = 90;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: ${defaultInnerWidth + '%'};
`;

export const PatternButton = `
  border: none;
  width: 43%;
  height: 15%;
  left: 21%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100%;
  cursor: pointer;
  position: relative;
`;

export const ButtonDownloadAndroid = styled.button`
    ${PatternButton}
    background-image: url(${props => props.bgImage});
    bottom: -15%;
`;

export const ButtonDownloadApple = styled.button`
    ${PatternButton}
    background-image: url(${props => props.bgImage});
    bottom: -13%;
`;
