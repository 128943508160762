import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Download } from "@mui/icons-material";
import Swal from "sweetalert2";

import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { SelectPage } from "components";
import { UseSelectorI } from "interfaces";
import { DashboardApiService } from "app/services";
import { getTranslation } from "./utils";
import { PeriodSelector } from "./components/PeriodSelector";
import { IConvertData } from "components/Selects/SelectPage";
import { extractDate } from "./utils/extractDate";
import { AxiosError } from "axios";
import { useTranslation } from "_metronic/i18n/language";

let startDate: Date | null | string = new Date("2024-01-01").toISOString();
let endDate: Date | null | string = new Date().toISOString();

const DashBoard = () => {
  const [loading, setLoading] = useState(false);
  const [formSelected, setFormSelected] = useState({} as any);
  const [formList, setFormList] = useState<Array<any>>([]);
  const [formTotalPages, setFormTotalPages] = useState(1);

  const translate = useTranslation();
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);

  const isFormValid =
    formSelected.id !== null && startDate !== null && endDate !== null;

  const api = new AnanseApiService();
  const dashBoardApi = new DashboardApiService();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let searchedList: Array<IConvertData> = [];
    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/form?&Page=${1}&PerPage=${50}`
    });
    const formList = response.data;
    setFormTotalPages(response.totalPages);
    formList.forEach((item: any) => {
      const converted = {
        id: item.formId,
        value: item.formId,
        label: `${item.formName}`,
        obj: item
      };
      if (searchedList.indexOf(converted) === -1) {
        searchedList.push(converted);
      }
    });

    setFormList(searchedList.sort((a, b) => a.label.localeCompare(b.label)));
  };

  const handleExport = async () => {
    const formatedStartDate = extractDate(startDate);
    const formatedEndDate = extractDate(endDate);
    try {
      setLoading(true);
      await dashBoardApi.download(
        {
          url: `/export-quizanswer?FormId=${formSelected.id}&StartDate=${formatedStartDate}&EndDate=${formatedEndDate}`
        },
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      setLoading(false);
    } catch (error) {
      console.error("Error exporting quiz answers:", error);
      setLoading(false);

      if (!(error instanceof AxiosError)) {
        return;
      }
      if (error?.response?.request.status === 400) {
        Swal.fire({
          title: translate("dashboard.no.data"),
          icon: "error"
        });
      }
      if (error?.response?.request.status === 500) {
        Swal.fire({
          title: translate("dashboard.error"),
          icon: "error"
        });
      }
    }
  };

  const handleStartDateChange = (value: Date | null) => {
    startDate = value;
  };

  const handleEndDateChange = (value: Date | null) => {
    endDate = value;
  };

  const ExportData = () => {
    return (
      <>
        <SelectPage
          label={getTranslation(locale, "label")}
          endPoint={`/form?`}
          value={formSelected?.id}
          handleChange={e => {
            const value = e;
            setFormSelected(value || {});
          }}
          convertObject={(obj: any) => ({
            id: obj.formId,
            value: obj.formId,
            label: `${obj.formName}`,
            obj
          })}
          formList={formList}
          totalPages={formTotalPages}
        />

        {formSelected?.id ? (
          <PeriodSelector
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
          />
        ) : (
          <Typography variant="h4" component="h4" align="center" marginTop={2}>
            {getTranslation(locale, "label")}
          </Typography>
        )}
        <Button
          variant="contained"
          color="success"
          onClick={handleExport}
          disabled={!isFormValid || loading}
          startIcon={<Download />}
        >
          {loading
            ? getTranslation(locale, "exporting")
            : getTranslation(locale, "export_Answers")}
        </Button>
      </>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 5 }}>
      <Loading isLoading={loading} />
      <ExportData />
    </Box>
  );
};

export default DashBoard;
