/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import bsCustomFileInput from 'bs-custom-file-input';

import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { Routes } from "./app/router/Routes";
import GlobalTheme from './app/utils/GlobalTheme';

import './_metronic/i18n/validators/pt';
import Loading from "./app/pages/home/components/Loading";
import { TranslationProvider } from "_metronic/i18n/language";

bsCustomFileInput.init();
const history = createBrowserHistory();

function App({ store, persistor, basename }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      setIsLoading(store.getState().spinner.show);
    });
    // eslint-disable-next-line
  }, []);

  return (
    /* Provide Redux store */
    <Provider store={store} loading={<LayoutSplashScreen />}>
      <StyledThemeProvider theme={GlobalTheme}>
        <PersistGate persistor={persistor}>
          <TranslationProvider>
            <Loading isLoading={isLoading} />
            <React.Suspense fallback={<LayoutSplashScreen />}>
              <Router basename={basename} history={history}>
                <LastLocationProvider>
                  <ThemeProvider>
                    <I18nProvider>
                        <Routes />
                    </I18nProvider>
                  </ThemeProvider>
                </LastLocationProvider>
              </Router>
            </React.Suspense>
          </TranslationProvider>
        </PersistGate>
      </StyledThemeProvider>
    </Provider>
  );
}

export default App;
