import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import FormatDocument from "../../../../utils/FormatDocument";
import { TableRow, TableCell } from "@mui/material";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import countriesBR from "../../components/Data/countriesBR.json";
import countriesEN from "../../components/Data/countriesEN.json";
import { format, addHours } from "date-fns";
import Loading from "../../../home/components/Loading";
import store from "../../../../store/store";

export default class UsersListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new AnanseApiService();

    this.state = {
      users: [],
      reload: false,
      loading: false,
      isActive: "true"
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.handleSendConfirmationMail = this.handleSendConfirmationMail.bind(
      this
    );
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setUsers = this.setUsers.bind(this);
  }

  handleConfirmDelete = async function(id) {
    await this.api.makeHttpRequest({
      method: "DELETE",
      url: `/usuario/${id}`
    });

    this.setState({ reload: !this.state.reload });
  };

  handleConfirmChangeStatus = async function(obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/usuario/${obj.id}`,
        data: {
          ativo: obj.deletedAt ? true : false
        }
      });

      this.setState({ reload: !this.state.reload });
    } catch (e) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        this.props.translate.defaultMessages.errorChangeStatus,
        "error"
      );
    }
  };

  handleSendConfirmationMail = async function(obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/usuario/resend-mail/${obj.id}`
      });

      Swal.fire({
        title: this.props.translate.defaultMessages.success,
        text: this.props.translate.defaultMessages.sendMail,
        icon: "success",
        cancelButtonText: this.props.translate.buttons.exit
      });
    } catch (e) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        this.props.translate.defaultMessages.errorSendMail,
        "error"
      );
    }
  };

  setUsers(users) {
    this.setState({ users });
  }

  formatAddress(address) {
    let str = "";

    if (address.street) str += address.street;

    if (address.city) str += ` ${address.city}`;

    if (address.state) str += ` ${address.state}`;

    if (address.zipcode) str += ` ${address.zipcode}`;

    if (address.country) {
      let country = "";
      if (this.props.lang === "pt") {
        country = countriesBR.find(data => data.code === address.country);
      } else {
        country = countriesEN.find(data => data.code === address.country);
      }
      str += ` ${country && country.name ? country?.name : ""}`;
    }

    return str;
  }

  formatRow(r) {
    const { auth } = store.getState();
    const isDiffAdmin =
      auth && auth.user && auth.user.profile.nome.toUpperCase() !== "ADMIN";
    const isDiffSuperUser =
      auth &&
      auth.user &&
      auth.user.profile.nome.toUpperCase() === "SUPER-USER";
    const crudActions = [];
    if (isDiffSuperUser && r.profile.nome === "ADMIN") {

    } else if ((r.confirmedAt && !isDiffAdmin) || isDiffSuperUser) {
      crudActions.push("toggle", "edit");
    } else if (!r.confirmedAt && !isDiffAdmin) {
      crudActions.push(...["resend", "delete", "edit"]);
    } else {
      crudActions.push(...["resend"]);
      if (r.id === auth.user.id) {
        crudActions.push("edit");
      }
    }

    return (
      <TableRow hover tabIndex={-1} key={r.id}>
        <TableCell>
          <CrudActions
            actions={crudActions}
            module="users"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            onConfirmResendEmail={this.handleSendConfirmationMail}
            onConfirmDelete={this.handleConfirmDelete}
            id={r.id}
            obj={r}
          />
        </TableCell>
        <TableCell scope="row">{r.nome}</TableCell>
        <TableCell scope="row">{r.email}</TableCell>
        {/* <TableCell scope="row">
          {r.cpf ? FormatDocument(r.cpf) : r.document}
        </TableCell>
        <TableCell scope="row">{r.genre}</TableCell>
        <TableCell scope="row">
          {r.birthday
            ? format(addHours(new Date(r.birthday), 5), "dd/MM/yyyy")
            : null}
        </TableCell> */}
        <TableCell scope="row">{r.profile && r.profile.nome}</TableCell>
        {/* <TableCell scope="row">
          {r.address ? this.formatAddress(r.address) : null}
        </TableCell> */}
        <TableCell scope="row">
          {!r.deletedAt
            ? this.props.translate.defaultMessages.active
            : this.props.translate.defaultMessages.inactive}
        </TableCell>
        <TableCell scope="row">
          {format(new Date(r.createdAt), "dd/MM/yyyy")}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    window.setPageTitle(
      `${this.props.translate.screens.user.list.title} - Admin`
    );

    const headRows = [
      { label: this.props.translate.screens.dashboard.action },
      { column: "Nome", label: this.props.translate.screens.user.list.name },
      { column: "Email", label: this.props.translate.screens.user.list.email },
      // {
      //   column: "Documento",
      //   label: this.props.translate.screens.user.list.document
      // },
      // { column: "Genero", label: this.props.translate.screens.user.list.genre },
      // {
      //   column: "Data de nascimento",
      //   label: this.props.translate.screens.user.list.birthday
      // },
      {
        column: "Profile.Nome",
        label: this.props.translate.screens.user.list.profile
      },
      // {
      //   column: "Endereco",
      //   label: this.props.translate.screens.user.list.address
      // },
      { column: "Ativo", label: this.props.translate.screens.user.list.status },
      {
        column: "DataCriacao",
        label: this.props.translate.screens.user.list.created
      }
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title={this.props.translate.screens.user.list.title}
          endpoint="/usuario"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.users}
          setRows={this.setUsers}
          reload={this.state.reload}
          placeholderSearch={
            this.props.translate.screens.user.placeholders.search
          }
          mobile={AdminDropdown}
          width={this.props.width}
          isActive={this.state.isActive}
          buttons={[
            {
              label: this.props.translate.datatable.add,
              onClick: null,
              icone: "add",
              path: "/admin/users/create"
            },
            {
              label: this.props.translate.datatable.export,
              endpoint: "/export",
              icone: this.props.width >= 768 ? "archive" : "download"
            }
          ]}
        />
      </>
    );
  }
}
