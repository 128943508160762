import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

import FormActions from "../../components/FormActions";
import { AppInput, FileUpload } from "../../../../partials/form";
import withFormValidation from "../../../../hoc/withFormValidation";
import { CampaignApiService } from "../../../../services/Ananse/CampaignApiService";
import FormHelper from "../../../../helpers/FormHelper";
import CollectionDevice from "./CollectionDevice.js";
import SelectLanguage from "../../components/SelectLanguage";

const PIE_VARIANT = 5;
const MAX_SLOTS = 20;
const MIN_SLOTS = 1;

function buildInitialCollection() {
  const initialCollection = [];
  for (let i = 1; i <= MAX_SLOTS; i++) {
    let slot = i + PIE_VARIANT;
    if (slot > MAX_SLOTS) {
      slot -= MAX_SLOTS;
    }

    initialCollection.push({
      slot,
      shotSeconds: 5 // default
    });
  }

  return initialCollection;
}

const initialState = {
  name: "",
  cover: null,
  fragranceShots: 100,
  collection: buildInitialCollection(),
  currentUploadedCover: null,
  hasDeviceError: false,
  descriptions: {},
  clone: false
};

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      descriptionLanguage: this.convertLanguage()
    };

    this.api = new CampaignApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.formHelper = new FormHelper(this);
    this.updateCollection = this.updateCollection.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.formHelper.loadFromService();
    }
    if (this.props.match.params.clone) {
      Swal.fire({
        title: this.props.translate.screens.campaigns.clone.title,
        text: this.props.translate.screens.campaigns.clone.text,
        icon: "info"
      });
      this.setState({
        clone: true
      });
    }
  }

  handleChangeLanguage = e => {
    this.setState({ descriptionLanguage: e.value });
  };

  mapModelToState(model) {
    let collection = buildInitialCollection();
    if (model.collection) {
      function calculateIndex(c) {
        let index = c.slot - (PIE_VARIANT + 1);
        if (index < 0) {
          index += MAX_SLOTS;
        }

        return index;
      }

      model.collection.forEach(c => (collection[calculateIndex(c)] = c));
    }

    const descriptions = {};
    let descriptionLanguage = null;
    if (model.descriptions && model.descriptions.length) {
      for (const description of model.descriptions) {
        descriptions[description.language] = description.description;

        if (!descriptionLanguage) {
          descriptionLanguage = description.language;
        }
      }
    } else {
      descriptionLanguage = this.state.descriptionLanguage;
    }

    if (this.state.clone) {
      return {
        name: model.name + " - Cópia",
        collection,
        descriptions,
        descriptionLanguage,
        fragranceShots: model.fragranceShots,
        currentUploadedCover: null,
      };
    }

    return {
      name: model.name,
      collection,
      descriptions,
      descriptionLanguage,
      fragranceShots: model.fragranceShots,
      currentUploadedCover: model.cover
    };
  }

  mapStateToModel() {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("fragranceShots", this.state.fragranceShots);
    if (this.state.cover) {
      formData.append("cover.file", this.state.cover);
    }

    this.state.collection
      .filter(c => c.hasOwnProperty("fragrance"))
      .forEach((c, i) => {
        formData.append(
          `collection[${i}][fragranceId]`,
          c.fragrance.fragranceId
        );
        formData.append(`collection[${i}][slot]`, c.slot);
        formData.append(`collection[${i}][shotSeconds]`, c.shotSeconds);
      });

    Object.keys(this.state.descriptions)
      .filter(lang => !!this.state.descriptions[lang])
      .forEach((lang, i) => {
        formData.append(`descriptions[${i}][language]`, lang);
        formData.append(
          `descriptions[${i}][description]`,
          this.state.descriptions[lang]
        );
      });

    return formData;
  }

  onCleanForm() {
    this.setState({
      ...initialState,
      collection: buildInitialCollection(),
      descriptionLanguage: "en-US"
    });

    document.body.scrollTo(0, 0);
  }

  updateCollection(collection) {
    let hasDeviceError = this.state.hasDeviceError;
    if (hasDeviceError && this.countConfiguredSlots(collection) >= MIN_SLOTS) {
      hasDeviceError = false;
    }

    this.setState({
      ...this.state,
      collection,
      hasDeviceError
    });
  }

  countConfiguredSlots(collection = null) {
    return (collection || this.state.collection).filter(c =>
      c.hasOwnProperty("fragrance")
    ).length;
  }

  submit(e) {
    e.preventDefault();
    const configuredSlots = this.countConfiguredSlots();
    if (configuredSlots < MIN_SLOTS) {
      this.setState({ hasDeviceError: true });
    } else {
      this.setState({ hasDeviceError: false });
    }

    if (this.state.clone) {
      this.formHelper.clone(e);
    } else {
      this.formHelper.submit(e);
    }
  }

  convertLanguage() {
    let { lang } = this.props;

    switch (lang) {
      case "pt":
        return "pt-BR";
      case "en":
        return "en-US";
      default:
        return "en-US";
    }
  }

  render() {
    const t = key => this.props.intl.formatMessage({ id: key });
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${this.props.translate.screens.campaigns.pageTitle.edit} - Admin`
      );
    } else {
      window.setPageTitle(
        `${this.props.translate.screens.campaigns.pageTitle.create} - Admin`
      );
    }

    const {
      name,
      collection,
      currentUploadedCover,
      hasDeviceError,
      cover,
      fragranceShots,
      descriptionLanguage
    } = this.state;

    const defaultImagePath = require("../../../../../assets/images/logo.png");

    return (
      <Form onSubmit={this.submit}>
        <Card className="mt-3">
          <Card.Header>
            {this.props.translate.screens.campaigns.mainData}
          </Card.Header>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="6" xs="12">
                <Form.Label>
                  {this.props.translate.screens.campaigns.labels.name} *
                </Form.Label>
                <AppInput
                  type="text"
                  name="name"
                  placeholder={
                    this.props.translate.screens.campaigns.placeholders.name
                  }
                  maxLength="100"
                  value={name}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|min:3|max:100"}
                />
              </Form.Group>

              <Form.Group as={Col} lg="6" xs="12">
                <Form.Label>
                  {this.props.translate.screens.campaigns.labels.fragranceShots}{" "}
                  *
                </Form.Label>
                <AppInput
                  type="text"
                  name="fragranceShots"
                  maxLength="100"
                  value={fragranceShots}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|numeric"}
                />
              </Form.Group>
            </Row>
            <Row>
              <FileUpload
                label={`${this.props.translate.screens.campaigns.labels.image} *`}
                placeholder={
                  this.props.translate.screens.campaigns.placeholders.image
                }
                defaultImagePath={defaultImagePath}
                name="cover"
                file={cover}
                currentUploadedFile={currentUploadedCover}
                handleChange={this.handleChange}
                validator={this.props.validator}
                validations={"required"}
                accept={"image/*"}
                translate={this.props.translate}
              />

              <Form.Group as={Col} lg="6" xs="12">
                <Form.Group as={Col} lg="12" xs="12">
                  <Row>
                    <div className="col-xs-12 col-md-4 col-lg-4">
                      <Form.Label>
                        {
                          this.props.translate.screens.campaigns.labels
                            ?.language
                        }
                      </Form.Label>
                      <SelectLanguage
                        translate={this.props.translate}
                        handleChangeLanguage={this.handleChangeLanguage}
                        languageValue={this.state.descriptionLanguage}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-6 d-inline-block ml-3 w-75">
                      <Form.Label>
                        {
                          this.props.translate.screens.fragrance.labels
                            .description
                        }{" "}
                        *
                      </Form.Label>
                      <AppInput
                        as="textarea"
                        name="description"
                        placeholder={
                          this.props.translate.screens.fragrance.placeholders
                            .description
                        }
                        maxLength="100"
                        value={
                          this.state.descriptions[descriptionLanguage] || ""
                        }
                        onChange={e =>
                          this.setState({
                            descriptions: {
                              ...this.state.descriptions,
                              [descriptionLanguage]: e.target.value
                            }
                          })
                        }
                        validator={this.props.validator}
                        validations={"required|max:100"}
                      />
                    </div>
                  </Row>
                </Form.Group>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-3 mb-3">
          <Card.Header>
            {this.props.translate.screens.campaigns.labels.collection}
          </Card.Header>
          <Card.Body>
            <CollectionDevice
              t={t}
              collection={collection}
              updateCollection={this.updateCollection}
              hasDeviceError={hasDeviceError}
              defaultIndex={
                this.props.match.params.id
                  ? null
                  : collection.findIndex(c => c.slot === 1)
              }
            />
          </Card.Body>
        </Card>

        <FormActions
          module="campaigns"
          formIsValid={true}
          onCleanForm={this.onCleanForm}
        />
      </Form>
    );
  }
}

export default withRouter(withFormValidation(CampaignForm, initialState));
