import { getTranslation } from "pages/Dashboard/utils";
import store from "../../app/store/store";
import translate from "@noar-firmenich/commons";

export default () => {
  const { auth, i18n } = store.getState();
  const { lang } = i18n;
  const isAdmin = auth?.user?.profile?.nome === "ADMIN";
  const isSuperUser = auth?.user?.profile?.nome === "SUPER-USER";

  const menuItems = {
    user: {
      title: translate(lang).menu.user.title,
      root: true,
      icon: "flaticon2-group",
      bullet: "dot",
      page: "admin/users"
    },
    quiz: {
      title: translate(lang).menu.quiz?.title?.quiz,
      root: true,
      icon: "flaticon2-chat",
      bullet: "dot",
      page: "admin/form"
    },
    flow: {
      title: translate(lang).menu.flow.title,
      root: true,
      icon: "flaticon-arrows",
      bullet: "dot",
      page: "admin/flow"
    },
    device: {
      title: translate(lang).screens.device?.title,
      root: true,
      icon: "flaticon2-pie-chart-2",
      bullet: "dot",
      page: "admin/devices"
    },
    capsule: {
      title: translate(lang).screens.capsule?.title,
      root: true,
      icon: "flaticon2-safe",
      bullet: "dot",
      page: "admin/capsules"
    },
    dashboard: {
      title: getTranslation(lang, "page_title"),
      root: true,
      icon: "flaticon2-dashboard",
      bullet: "dot",
      page: "admin/dashboard"
    },
    fragrance: {
      title: translate(lang).menu.fragrance.title,
      root: true,
      icon: "flaticon2-wifi",
      bullet: "dot",
      page: "admin/fragrances"
    },
    campaign: {
      title: translate(lang).menu.campaign.title,
      root: true,
      icon: "flaticon2-writing",
      bullet: "dot",
      page: "admin/campaigns"
    }
  };

  const menu = { header: { self: {}, items: [] }, aside: { self: {}, items: [] } };

  if (isSuperUser) {
    menu.aside.items.push(menuItems.user);
    menu.aside.items.push(menuItems.quiz);
    menu.aside.items.push(menuItems.flow, menuItems.device, menuItems.capsule, menuItems.dashboard);
  }

  if (isAdmin) {
    menu.aside.items.push(menuItems.user);
    menu.aside.items.push(menuItems.fragrance);
    menu.aside.items.push(menuItems.campaign);
    menu.aside.items.push(menuItems.quiz);
    menu.aside.items.push(menuItems.flow, menuItems.device, menuItems.capsule, menuItems.dashboard);
  }

  return menu;
};
