import axios from "axios";

const API_KEY = "8ZfnSAntnQ25fFpHoIoiZ27d17lSjdN6";

export const getLocales = async () => {
  try {
    const response = await axios.get(`
    https://noar-localise-api.azurewebsites.net/api/localise/languages?key=${API_KEY}
    `);
    return response.data;
  } catch (error) {
    console.error('Erro ', error)
  }
};

export const fetchAllLocales = async () => {
  try {
    const response = await axios.get(`
    https://noar-localise-api.azurewebsites.net/api/localise/locales?key=${API_KEY}
    `);
    return response.data;
  } catch (error) {
    console.error('Erro ', error)
  }
};
