import React, { useEffect, useState } from "react";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { Edit, OpenInBrowser } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";

import Datatable from "app/partials/datatable/Datatable";
import DeletPatient from "components/table/Patient/DeletPatient";
import store from "app/store/store";
import { titlePage } from "utils/titlepage";
import AdminDropdown from "app/partials/content/CustomDropdowns/AdminDropdown";
import { formatDate, formatedDate } from "utils";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import DeleteFlow from "./component/DeleteFlow";
import { useTranslation } from "_metronic/i18n/language";

const api = new AnanseApiService();
const ListForms: React.FC = () => {
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();

  const [isActive] = useState("true");
  const [reload, setReload] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const titleP = translate('menu.flow.title')
  useEffect(() => {
    titlePage(titleP + " - Admin");
    getCampaignsAll();
  }, [reload]);

  const loadingData = async() => {
    setReload(!reload)

  }

  const getCampaignsAll = async () => {
    try {
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: `/campaigns`
      });
      const { data } = await api.makeHttpRequest({
        method: "GET",
        url: `/campaigns?PerPage=${total}`
      });
      setCampaigns(data);
    } catch (error) {}
  };

  const searchCampaignById = (id: number) => {
    const findCampaign: any = campaigns.find((f: any) => f.campaignId === id);
    return { name: findCampaign?.name ?? '' };
  };

  const headRows = [
    { label: translate('screens.dashboard.action') },
    { column: "Name", label: translate('screens.user.list.name')},
    { column: "Cpf", label: translate('screens.quiz.list.campaign') },
    { column: "Email", label: translate('screens.quiz.list.created') },
    { column: "Genre", label: translate('screens.quiz.list.updated') }
  ];

  const formatRow = (r: any) => {
    const { auth } = store.getState();
    const isDiffAdmin =
      auth && auth.user && auth.user.profile.nome.toUpperCase() !== "ADMIN";
    const crudActions = [];
    if (r.confirmedAt && !isDiffAdmin) {
      crudActions.push("toggle" as never, "edit" as never);
    } else if (!r.confirmedAt && !isDiffAdmin) {
      crudActions.push(
        ...["resend" as never, "delete" as never, "edit" as never]
      );
    } else {
      crudActions.push(...["resend" as never]);
      if (r.id === auth.user.id) {
        crudActions.push("edit" as never);
      }
    }
    return (
      <TableRow hover tabIndex={-1} key={r.formId}>
        <TableCell style={{ flexDirection: "row", flex: 1, display: "flex" }}>
          {/* <DeletPatient item={r} /> */}
          <DeleteFlow item={r} loadingData={loadingData} />
          <Link
            to={{
              pathname: `/admin/flow/update/${r.formId}`,
              state: { data: r }
            }}
          >
            <IconButton
              aria-label="upload picture"
              component="label"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
          </Link>
        </TableCell>
        <TableCell scope="row">{r.formName}</TableCell>
        <TableCell scope="row">{searchCampaignById(r.campaignId).name}</TableCell>
        <TableCell>{formatedDate(r.createdAt, true, true, locale)}</TableCell>
        <TableCell>{formatedDate(r.updatedAt, true, true, locale)}</TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <Datatable
        title={translate('menu.flow.title')}
        endpoint={"form"}
        headRows={headRows}
        formatRow={formatRow}
        rows={accounts}
        setRows={setAccounts}
        reload={reload}
        placeholderSearch={
          translate('labels.search')
        }
        mobile={AdminDropdown}
        width={window.innerWidth}
        isActive={isActive}
        buttons={[
          {
            label: translate('datatable.add'),
            onClick: null,
            icone: "add",
            path: "/admin/flow/create"
          }
        ]}
        orderBy="Name"
        order="asc"
      />
    </>
  );
};

export default ListForms;

const d = {
  formId: 7,
  formName: "Teste Create form user quiz",
  campaignId: 3,
  user: {
    formUserId: 5,
    formId: 7,
    userId: 1
  },
  quiz: {
    formQuizId: 5,
    formId: 9,
    sequenceNo: 9,
    quizId: 11
  }
};

const formquiz = {
  formId: 7,
  sequenceNo: 9,
  quizId: 11
};

const formuser = {
  formId: 7,
  userId: 1
};
