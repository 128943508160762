import {
  ArrowUpward,
  Check,
  Close,
  Collections,
  Delete,
  Edit,
  QueuePlayNextTwoTone
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import React from "react";
import { Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import "react-sortable-tree/style.css";
import Swal from "sweetalert2";

import FormHelper from "../../../../../helpers/FormHelper";
import withFormValidation from "../../../../../hoc/withFormValidation";
import ConfirmDeleteDialog from "../../../../../partials/datatable/ConfirmDeleteDialog";
import CrudActions from "../../../../../partials/datatable/CrudActions";
import DatatableStandalone from "../../../../../partials/datatable/DatatableStandalone";
import { CampaignApiService } from "../../../../../services/Ananse/CampaignApiService";
import { QuestionApiService } from "../../../../../services/Ananse/QuestionApiService";
import { QuizApiService } from "../../../../../services/Ananse/QuizApiService";
import IsValidName from "../../../../../utils/validators/IsValidName";
import SelectSearchComponent from "../../../../auth/Components/Select2";
import Loading from "../../../../home/components/Loading";
import SelectLanguage from "../../../components/SelectLanguage";
import {
  FormNextQuestion,
  PreviewFormModal,
  SelectFragrance,
  SelectModal
} from "../components";
import colorsArray from "../components/colors";
import { positions } from "../data";
import { makeAxlePosition } from "../utils/axleHelper";
import { selectData, selectSegmentData } from "./selectData";

import { TranslationContext } from "_metronic/i18n/language";
import ColorPicker from "app/partials/layout/ColorPicker";
import styles from "../Anamnese.module.scss";
import ResponseModalMedia from "../components/ResponseModalMedia/ResponseModalMedia";
import { getStateByModel } from "../utils/mapModel";
import { InputAudio } from "./InputAudio";
import { InputColor } from "./InputColor";
import { InputImage } from "./InputImage";
import { InputMatrixRender } from "./InputMatrixRender";
import { InputMatrixSelectRender } from "./InputMatrixSelectRender";
import { InputVideo } from "./InputVideo";
import SelectItemComponent from "../components/SelectItem";
import TableAnswers from "../components/TableAnswers";
import { up } from "../utils";
import SequenceQuestions, {
  updateSequence
} from "../components/SequenceQuestions";
import ArrangeSequenceModal from "../components/ArrangeSequenceModal";

const initialQuestion = {
  quizQuestionId: 0,
  questionId: 0,
  quest: "",
  answers: [],
  type: "checkbox",
  mandatory: true,
  code: "",
  arrangeQuestionSequence: 0
};

const initialAnswer = {
  quizQuestionAnswerId: 0,
  answerId: 0,
  desc: "",
  description: "",
  isAnswerFinish: false,
  endQuiz: false,
  nextQuizQuestionId: 0,
  nextQuizQuestion: null,
  axle: "",
  axlePosition: "1",
  open: false,
  color: "#ffffff"
};

const initialState = {
  quizName: "",
  quizId: null,
  validatedAt: null,
  campaignId: null,
  campaignName: "",
  fragranceId: 0,
  language: "",
  questions: [],
  collection: [],
  loading: false,
  question: { ...initialQuestion },
  responseQuestion: { ...initialQuestion },
  answer: { ...initialAnswer },
  answerSelectedIndex: null,
  requiredQuestion: false,
  requiredQuestionType: false,
  requiredAnswer: false,
  requiredAnswers: false,
  requiredNextQuestion: false,
  requiredFragrance: false,
  maxShots: null,
  userId: null,
  type: "1",
  select: [],
  selectSegment: [],
  resetInput: false,
  anamneseId: 0,
  mandatoryAnamnese: false,
  requiredCampaing: false,
  initialInformation: "",
  min: 0,
  openModal: false,
  showMedia: false,
  clone: false,
  redirectClone: {
    to: "/",
    redirect: false
  },
  previewFormModal: false,
  selectedColor: "",
  segment: ""
};

const colors = [];
class AnamneseUpdate extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      translate: null,
      confirmDelete: false,
      id: null,
      treeData: []
    };

    this.api = new QuizApiService();
    this.questionApi = new QuestionApiService();
    this.formHelper = new FormHelper(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.clearAnswer = this.clearAnswer.bind(this);
    this.editAnswer = this.editAnswer.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.validateQuiz = this.validateQuiz.bind(this);
    this.publishQuiz = this.publishQuiz.bind(this);
    this.moveAnswerUp = this.moveAnswerUp.bind(this);
    this.onUp = this.onUp.bind(this);
    this.onDown = this.onDown.bind(this);
    this.checkMandatoryQuestion = this.checkMandatoryQuestion.bind(this);
    this.askForColors = this.askForColors.bind(this);
    this.formatColors = this.formatColors.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    // Aguarde até que o contexto seja definido
    const translate = await this.context;
    this.setState({ translate });
  }

  async getQuiz(id) {
    const quiz = await this.api.get(id);
    if (quiz) {
      if (quiz.campaignId) {
        const campaignApi = new CampaignApiService();
        quiz.campaign = await campaignApi.get(quiz.campaignId);
      }
      this.setState({
        loading: false,
        quizId: quiz.quizId,
        validatedAt: quiz.validatedAt,
        campaignId: quiz.campaignId,
        campaignName: quiz.campaign?.name,
        questions: quiz.questions,
        language: quiz.language,
        question: {
          ...this.cleanQuestion(),
          type: "",
          mandatory: true,
          code: "",
          arrangeQuestionSequence: 0
        },
        answer: { ...initialAnswer },
        maxShots: quiz.maxShots,
        type: quiz.type,
        userId: quiz.userId,
        anamneseId: quiz.anamneseId,
        mandatoryAnamnese: quiz.mandatoryAnamnese,
        requiredCampaing: quiz.campaignId ? true : false,
        fragranceId: quiz.fragranceId,
        collection: quiz.campaign?.collection,
        initialInformation: quiz.initialInformation,
        segment: quiz.segment,
        min:
          quiz.questions.length > 0
            ? quiz.questions[quiz.questions.length - 1].quizQuestionId
            : 0
      });
      return quiz;
    } else {
      this.props.history.push("/admin/quiz");
    }
  }

  handleChangeColor = color => {
    if (!colors.includes(color)) {
      colors.push(color);
      this.setState({ selectedColor: color, color });
    }
  };

  async formatTreeData(quiz) {
    await this.initializeTranslation();
    let { questions } = quiz;
    const typeQuestion = "question";
    const typeAnswer = "answer";

    if (!questions.length > 0) {
      return [];
    }
    questions = questions.reverse();

    let questionsFormat = questions.map(q => {
      const {
        question,
        answers,
        questionId,
        quizQuestionId,
        quizId,
        mandatory
      } = q;

      const divStyles = {
        display: "inline-block",
        borderRadius: 5,
        color: "#FFF",
        padding: "0 5px",
        marginRight: "0.3rem"
      };

      return {
        title: (
          <>
            <div style={{ ...divStyles, backgroundColor: "cyan" }}>
              {this.state.translate("screens.quiz.labels.question")}
            </div>
            {question.quest}
          </>
        ),
        answers,
        question,
        questionId,
        quizQuestionId,
        quizId,
        mandatory,
        expanded: true,
        nodeType: typeQuestion,
        children: answers.map(ans => {
          const { answer, fragranceOptions, quizQuestionAnswerId } = ans;
          return {
            title: (
              <>
                <div style={{ ...divStyles, backgroundColor: "#0D2551" }}>
                  {this.state.translate("screens.quiz.labels.answer")}
                </div>
                {answer.label}
              </>
            ),
            nextId: ans.nextQuestion?.question?.questionId,
            quizQuestionAnswerId,
            nodeType: typeAnswer,
            expanded: true,
            children:
              fragranceOptions.length > 0
                ? fragranceOptions.map(frag => {
                    const { fragrance, fragranceOptionId } = frag;
                    return {
                      title: (
                        <>
                          <div
                            style={{ ...divStyles, backgroundColor: "#0abb87" }}
                          >
                            {this.state.translate("screenApp.fragrance}")}
                          </div>
                          {fragrance.name}
                        </>
                      ),
                      children: [],
                      nodeType: typeFragrance,
                      fragranceOptionId,
                      noDragging: false,
                      noChildren: true
                    };
                  })
                : []
          };
        })
      };
    });
    let response = await this.questionsFormated(questionsFormat);
    return response;
  }

  questionsFormated(questionsFormat) {
    return new Promise(function(resolve, reject) {
      setTimeout(() => {
        questionsFormat.reverse().forEach(qf => {
          const { questionId } = qf;
          questionsFormat.forEach(q => {
            let { children } = q;
            let childrenFound = children.filter(c => c.nextId === questionId);

            if (childrenFound.length > 0) {
              childrenFound.forEach(cf => {
                cf.children = [qf];
              });
            }
          });
        });
        resolve([questionsFormat[questionsFormat.length - 1]]);
      }, 2000);
    });
  }

  onCleanForm() {
    this.setState({
      loading: false,
      question: { ...this.cleanQuestion(), type: "" },
      answer: {
        quizQuestionAnswerId: null,
        answerId: null,
        desc: "",
        description: "",
        isAnswerFinish: false,
        endQuiz: false,
        nextQuizQuestionId: null,
        nextQuizQuestion: null,
        fragrances: []
      }
    });
  }

  onPreviewForm = () => {
    this.setShowPreviewForm(!this.state.previewFormModal);
  };

  setShowPreviewForm = previewFormModal => {
    this.setState({ previewFormModal });
  };

  swap = (array, index1, index2) => {
    if (
      index1 >= 0 &&
      index1 < array.length &&
      index2 >= 0 &&
      index2 < array.length &&
      index1 !== index2
    ) {
      const newArray = [...array];
      [newArray[index1], newArray[index2]] = [
        newArray[index2],
        newArray[index1]
      ];

      return newArray.map((value, index) => ({
        ...value,
        arrangeQuestionSequence: index
      }));
    }

    console.error("Invalid indices provided for swap.");
    return array;
  };

  onUp = async id => {
    const { questions } = this.state;

    const result = up(questions, id);

    this.setState({ questions: result });
    for (const q of questions) {
      await this.addUpdateQuestion(q);
    }
  };

  onDown = async id => {
    const { questions } = this.state;

    const result = up(questions, id);

    this.setState({ questions: result });
    for (const q of questions) {
      await this.addUpdateQuestion(q);
    }
  };

  moveAnswerUp(index) {
    const answers = this.state.question.answers;
    const newAnswers = answers.filter((item, i) => i !== index);
    newAnswers.splice(index - 1, 0, answers[index]);
    this.setState({
      question: { ...this.state.question, answers: newAnswers }
    });
  }

  async componentDidMount() {
    const id = this.props.match.params.id;

    await this.initializeTranslation();

    if (!id) {
      return;
    }
    this.setState({ loading: true });
    this.formHelper.loadFromService();

    if (this.props.match.params.clone) {
      Swal.fire({
        title: this.state.translate("screens.quiz.clone.title"),
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.updateQuiz();
        }
      });
      this.setState({
        clone: true
      });
    }

    try {
      await this.makeTreeData(id);
    } catch (error) {
      // console.error("Error making tree data:", error);
      Swal.fire(
        this.state.translate("screens.quiz.errors.title"),
        this.state.translate("screens.quiz.errors.message"),
        "error"
      );
    }
    this.setState({ loading: false });
  }

  makeTreeData = async id => {
    let quiz = await this.getQuiz(id);
    let treeData = await this.formatTreeData(quiz);
    this.setState({ select: selectData, selectSegment: selectSegmentData });
    this.setState({ treeData });
  };

  createQuestion = async e => {
    await this.initializeTranslation();
    e.preventDefault();
    try {
      const reqBase = { method: "POST", url: "add-update" };

      const { quizId, question, questions } = this.state;

      let hasError = false;
      if (!question || !question.type.length) {
        this.setState({ requiredQuestionType: true });
        hasError = true;
      }
      if (!question || (!question.questionId && !question.quest)) {
        this.setState({ requiredQuestion: true });
        hasError = true;
      }

      if (question.answers?.length === 0) {
        this.setState({ requiredAnswers: true });
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.setState({ loading: true });

      const updatedQuestions = questions
        .sort((a, b) => a.arrangeQuestionSequence - b.arrangeQuestionSequence)
        .map((q, index) => ({
          ...q,
          arrangeQuestionSequence: index + 1
        }));
      question.arrangeQuestionSequence =
        question.arrangeQuestionSequence || updatedQuestions.length + 1;
      question.quizId = quizId;
      question.questionId = question.questionId || 0;
      question.quizQuestionId = question.quizQuestionId || 0;
      const response = await this.questionApi.makeHttpRequest({
        ...reqBase,
        data: question
      });

      const totalQuestions = updatedQuestions.length;
      for (let i = 0; i < totalQuestions; i++) {
        const question = updatedQuestions[i];
        await updateSequence(question);
      }
      await this.getQuiz(quizId);

      if (
        question.type === "image" ||
        question.type === "audio" ||
        question.type === "video"
      ) {
        this.setState({
          openModal: true,
          question,
          responseQuestion: response
        });
      }

      this.setState(prevState => ({
        question: { ...prevState.question, type: "" }
      }));

      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("screens.quiz.messages.add"),
        icon: "success",
        cancelButtonText: this.state.translate("buttons.cancel")
      });
    } catch (e) {
      Swal.fire(
        "ERROR",
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.createQuestion"),
        "error"
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  addUpdateQuestion = async question => {
    const data = {
      quizId: question.quizId,
      quizQuestionId: question.quiz,
      questionId: question.questionId,
      quest: question.question.quest,
      fragranceId: question.fragranceId,
      rightAnswer: question.rightAnswer,
      type: question.type,
      mandatory: question.mandatory,
      code: question.code,
      arrangeQuestionSequence: question.arrangeQuestionSequence,
      answers: question.answers
    };
    const reqBase = { method: "POST", url: "add-update" };
    const response = await this.questionApi.makeHttpRequest({
      ...reqBase,
      data
    });
  };

  updateQuiz = async (questions = this.state.questions) => {
    await this.initializeTranslation();
    const {
      quizId,
      quizName,
      maxShots,
      type,
      language,
      userId,
      campaignId,
      anamneseId,
      mandatoryAnamnese,
      fragranceId,
      initialInformation,
      segment
    } = this.state;
    if (this.state.clone) {
      this.clone();
      return;
    }
    this.setState({ loading: true });
    try {
      const body = {
        quizId: quizId,
        campaignId: campaignId,
        name: quizName,
        language: language,
        type: type,
        maxShots: maxShots,
        userId: userId,
        questions: questions,
        anamneseId,
        mandatoryAnamnese,
        fragranceId,
        initialInformation,
        segment
      };

      await this.api.update(quizId, body);
      this.setState({ loading: false });
      await Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("defaultMessages.saveData"),
        icon: "success",
        confirmButtonText: "OK"
      });
    } catch (error) {
      // console.error("Error updating quiz:", error);
      this.setState({ loading: false });
      Swal.fire(
        this.state.translate("screens.quiz.errors.title"),
        this.state.translate("screens.quiz.errors.message"),
        "error"
      );
    }
  };

  handleChangeLanguage = e => {
    this.setState({ language: e.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.maxShots]: e.target.maxShots,
      [e.target.type]: e.target.type,
      [e.target.segment]: e.target.segment
    });
  };

  handleChangeSelect = e => {
    this.setState({
      campaignId: e.id,
      campaignName: e.label,
      resetInput: false
    });
  };

  handleChangeSelectMulti = e => {
    const newId = e.map(usuario => usuario.id).join(";");
    this.setState({
      userId: newId
    });
  };

  handleRadio = e => {
    const { answer } = this.state;
    answer.isAnswerFinish = e.target.value === "true";
    this.setState({
      answer
    });
  };

  handleQuestion = obj => {
    const { question } = this.state;
    question.questionId = obj.id;
    question.quest = obj.label.replace(
      this.state.translate("screens.quiz.placeholders.newQuestion"),
      ""
    );
    this.setState({ question: { ...question }, requiredQuestion: false });
  };

  handleQuestionType = obj => {
    const inputType = obj.target.value;
    const { question } = this.state;
    question.type = inputType;
    if (question.type === "color") {
      this.askForColors();
    }
    this.setState({ question: { ...question }, requiredQuestionType: false });
  };

  handleQuestionCodeSegment = obj => {
    const inputType = obj.target.value;
    const { question } = this.state;
    question[obj.target.name] = inputType;
    this.setState({ question: { ...question }, requiredQuestionType: false });
  };

  handleAnswer = obj => {
    const { answer } = this.state;
    answer.answerId = obj.id;
    answer.desc = obj.label.replace(
      this.state.translate("screens.quiz.placeholders.newAnswer"),
      ""
    );
    answer.description = obj.description ?? "";

    this.setState({ answer, requiredAnswer: false });
  };

  handleAnswerCode = ({ target }) => {
    const answer = this.state.answer;
    answer.description = target.value;
    this.setAnswer(answer);
  };

  setAnswer = answer => {
    this.setState({ answer });
  };

  setRequiredNextQuestion = hasNext =>
    this.setState({ requiredNextQuestion: hasNext });

  addAnswer = (cor = null) => {
    const { question, answer, answerSelectedIndex } = this.state;
    const newAnswer = { ...answer };
    if (question.type === "matriz") {
      newAnswer.axle = answer.axle;
    }
    if (question.type === "color") {
      newAnswer.description = answer.description;
      newAnswer.desc = cor || this.state.selectedColor;
    }
    if (question.type === "matriz-select") {
      newAnswer.axle = makeAxlePosition(
        this.state.answer.axle,
        this.state.answer.axlePosition
      );
    }
    let hasError = false;
    if (!newAnswer || (!newAnswer.answerId && !newAnswer.desc)) {
      this.setState({ requiredAnswer: true });
      hasError = true;
    }

    if (hasError) {
      return false;
    }

    if (answerSelectedIndex !== null) {
      question.answers[answerSelectedIndex] = newAnswer;
    } else {
      question.answers.push(newAnswer);
    }
    const prepareData = {
      ...this.state,
      question: { ...question },
      answer: { ...initialAnswer },
      answerSelectedIndex: null,
      requiredAnswers: false,
      requiredQuestion: false,
      requiredAnswer: false,
      requiredNextQuestion: false,
      requiredFragrance: false
    };
    this.setState(prepareData);
    return true;
  };

  formatFragrances(fragrances) {
    const fragrancesOptions = this.state.collection
      .map(e => ({
        label: e.fragrance?.name,
        value: e.fragrance?.fragranceId
      }))
      .find(e => {
        if (parseInt(fragrances) === e.value) {
          return e.label;
        }
      });
    let result = "";
    result = fragrancesOptions?.label;

    return result;
  }

  formatAnswers(answers) {
    let result = [];

    if (answers) {
      result = answers.map((e, i) => <p key={i}>{e.answer?.label}</p>);
    }

    return result;
  }
  formatAnswersEndQuiz(answers) {
    let result = [];

    if (answers) {
      result = answers.map((e, i) => (
        <p key={i}>{e.endQuiz ? <Check /> : <Close />}</p>
      ));
    }

    return result;
  }

  clearAnswer(answer) {
    const { question } = this.state;

    const newAnswers = question.answers.filter(e => e !== answer);
    const newQuestion = { ...question, answers: newAnswers };
    this.setState({ question: newQuestion });
  }

  editAnswer(answer, index) {
    const str = answer.axle;
    const parts = str?.split(":");
    const color = answer.desc;
    let axlePosition;
    if (parts?.length === 2) {
      axlePosition = parts[1];
    }
    const edit = { ...answer, axle: parts?.[0], axlePosition };
    this.setState({
      answer: edit,
      answerSelectedIndex: index,
      color: color,
      selectedColor: color
    });
  }

  async onConfirmDelete(quizQuestionId) {
    this.setState({ loading: true });
    await this.questionApi.delete(quizQuestionId);
    await this.getQuiz(this.state.quizId);
    this.setState({ confirmDelete: false });
    this.setState({ loading: false });
  }

  onEdit(obj) {
    const editAnswers = obj.answers?.map(item => ({
      quizQuestionAnswerId: item.quizQuestionAnswerId || 0,
      answerId: item.answerId || 0,
      desc: item.answer?.label,
      description: item.answer?.description,
      axle:
        obj.type === "matriz" || obj.type === "matriz-select" ? item.axle : "",
      isAnswerFinish:
        item.nextQuestionId !== null && item.undefined ? true : false,
      endQuiz: item.endQuiz,
      nextQuizQuestionId: item.nextQuestionId || 0,
      nextQuizQuestion: item.nextQuestionId
        ? {
            label: item.nextQuestion?.question?.quest,
            value: item.nextQuestionId
          }
        : undefined,
      fragrances: item.fragranceOptions?.map(option => ({
        label: option.fragrance?.name,
        value: option.fragranceId
      }))
    }));
    const editQuestion = {
      quizQuestionId: obj.quizQuestionId || 0,
      questionId: obj.questionId || 0,
      quest: obj.question?.quest,
      type: obj.type,
      mandatory: obj.mandatory,
      answers: editAnswers,
      code: obj.code || "",
      segment: obj.segment || "",
      arrangeQuestionSequence: obj.arrangeQuestionSequence
    };
    this.setState({
      question: { ...editQuestion },
      // answer: {
      //   id: null,
      //   value: null,
      //   label: "",
      //   description: ""
      // },
      answerSelectedIndex: null
    });
  }

  async askForColors() {
    await this.initializeTranslation();
    Swal.fire({
      title: this.state.translate("defaultMessages.addAllColors"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.state.translate("buttons.yes"),
      cancelButtonText: this.state.translate("buttons.no")
    }).then(result => {
      if (result.isConfirmed) {
        this.formatColors();
        Swal.fire(
          this.state.translate("defaultMessages.added"),
          this.state.translate("defaultMessages.allcolorsAdded"),
          "success"
        );
      } else {
        Swal.fire(
          this.state.translate("defaultMessages.notAdded"),
          this.state.translate("defaultMessages.allcolorsNotAdded"),
          "error"
        );
      }
    });
  }

  formatColors = () => {
    for (let i = 0; i < colorsArray.length; i++) {
      let cor = colorsArray[i];
      this.addAnswer(cor);
    }
  };

  handleTreeOnChange = treeData => {
    this.setState({ treeData });
  };

  canDragNode = (node, nextParentNode) => {
    let can = true;
    let nextType = nextParentNode ? nextParentNode.nodeType : null;

    // TODO: REFATORAR CONDIÇÕES
    if (node.nodeType === "answer") {
      if (nextType !== "question") can = false;
    } else if (node.nodeType === "question") {
      if (nextType && nextType !== "answer") can = false;
    } else if (node.nodeType === "fragrance") {
      if (nextType !== "answer") can = false;
    }

    return can;
  };

  handleMoveNode = async (treeData, node, nextParentNode) => {
    await this.initializeTranslation();
    if (this.canDragNode(node, nextParentNode)) {
      switch (node.nodeType) {
        case "fragrance":
          try {
            const newQuizQuestionAnswerId = nextParentNode.quizQuestionAnswerId;
            const fragranceOptionId = node.fragranceOptionId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                fragranceOptionId,
                newQuizQuestionAnswerId,
                nodeType: "fragrance"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            this.swalErrorMessage();
          }

          this.setState({ loading: false });
          break;
        case "question":
          try {
            const quizQuestionAnswerId = nextParentNode.quizQuestionAnswerId;
            const nextQuestionId = node.quizQuestionId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                quizQuestionAnswerId,
                nextQuestionId,
                nodeType: "question"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            this.swalErrorMessage();
          }

          this.setState({ loading: false });

          break;
        case "answer":
          try {
            const newQuizQuestionId = nextParentNode.quizQuestionId;
            const quizQuestionAnswerId = node.quizQuestionAnswerId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                newQuizQuestionId,
                quizQuestionAnswerId,
                nodeType: "answer"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            this.swalErrorMessage();
          }

          this.setState({ loading: false });
          break;
        default:
      }

      return true;
    } else {
      this.swalErrorMessage();

      setTimeout(() => {
        this.props.history.go();
      }, 2000);
    }

    return false;
  };

  async genericPut(endpoint, msg) {
    await this.initializeTranslation();
    try {
      const { quizId } = this.state;
      const reqBase = { method: "PUT", url: endpoint + "/" + quizId };

      this.setState({ loading: true });

      await this.api.makeHttpRequest({
        ...reqBase
      });

      await this.getQuiz(quizId);
      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: msg,
        icon: "success",
        cancelButtonText: this.state.translate("buttons.cancel")
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire(
        "ERROR",
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.createQuestion"),
        "error"
      );
    }
  }

  async validateQuiz() {
    await this.initializeTranslation();
    await this.genericPut(
      "validate",
      this.state.translate("screens.quiz.messages.validated")
    );
  }

  async publishQuiz() {
    await this.initializeTranslation();
    await this.genericPut(
      "publish",
      this.state.translate("screens.quiz.messages.published")
    );
  }

  toggleConfirmDialog(state) {
    this.setState({ confirmDelete: state });
  }

  checkMandatoryQuestion = q => {
    q.mandatory = !q.mandatory;
    this.setState({ question: q });
  };

  checkEndQuiz = answerItem => {
    const finishQuiz = !answerItem.endQuiz;
    answerItem.endQuiz = finishQuiz;
    this.setState({ answer: answerItem });
  };

  checkMandatoryAnamnese = event => {
    this.setState({
      mandatoryAnamnese: this.state.mandatoryAnamnese ? false : true
    });
  };

  handleChangeSelectFragrances = e => {
    this.setState({ fragranceId: e.id, resetInput: false });
  };

  mapModelToState = model => {
    return getStateByModel(model.name, this.state.clone ? " - Cópia" : "");
  };

  async clone() {
    await this.initializeTranslation();
    this.setState({ isLoading: true });
    try {
      const reqBase = { method: "POST" };
      const {
        campaignId,
        language,
        initialInformation,
        maxShots,
        userId,
        type,
        fragranceId,
        quizName,
        questions
      } = this.state;
      const body = {
        name: quizName,
        campaignId,
        language,
        maxShots,
        type,
        fragranceId,
        userId,
        initialInformation,
        questions
      };
      const response = await this.api.makeHttpRequest({
        ...reqBase,
        data: body
      });
      const redirectToEditId = await this.saveQuestions(
        questions,
        response?.quizId
      );
      if (response?.quizId && redirectToEditId) {
        const cloneRedirect = {
          redirect: true,
          to: `/admin/form/${response?.quizId}/update`
        };
        this.setState({ redirectClone: cloneRedirect });
      }
      await Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("defaultMessages.saveData"),
        icon: "success",
        confirmButtonText: "OK"
      });
    } catch (e) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.create"),
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  saveQuestions = async (questions, quizId) => {
    return new Promise(resolve => {
      setTimeout(() => {
        questions?.forEach(async value => {
          const body = {
            answers: value?.answers || [],
            mandatory: value?.mandatory ?? false,
            quest: value?.question?.quest || "",
            questionId: value?.questionId || 0,
            quizId: quizId || 0,
            type: value?.type || ""
          };
          await this.questionApi.makeHttpRequest({
            method: "POST",
            url: "/add-update",
            data: body
          });
        });
        resolve(true);
      }, 1000);
    });
  };

  swalErrorMessage = () => {
    Swal.fire({
      title: this.state.translate("defaultMessages.error"),
      text: "Error",
      icon: "error",
      confirmButtonText: "OK"
    });
  };

  cleanQuestion = () => ({
    quizQuestionId: null,
    questionId: null,
    quest: "",
    answers: []
  });

  inputRender = value => {
    const translate = this.context;
    const array = ["radio", "checkbox"];
    let radioCheckbox = {};

    if (array.includes(value.type)) {
      radioCheckbox = {
        [value.type]: (
          <div>
            {value.answers.map(v => {
              return (
                <div
                  key={v.answer.label}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "14px"
                  }}
                >
                  <label htmlFor={v.answer.label}>{v.answer.label}</label>
                  <input
                    type={value.type}
                    name={value.question?.quest}
                    id={v.answer.label}
                  />
                </div>
              );
            })}
          </div>
        )
      };
    }
    return {
      select: (
        <select
          style={{
            width: "100%",
            height: "40px",
            padding: "10px",
            border: "none",
            borderBottom: " 1px solid #b3b3b3",
            backgroundColor: "transparent",
            outline: "none"
          }}
        >
          <option>{translate("screenApp_quiz_selectOption")}</option>
          {value.answers.map(v => {
            return <option key={v.answer.label}>{v.answer.label}</option>;
          })}
        </select>
      ),
      text: <input style={{ width: "100%", height: "40px" }} />,
      matriz: <InputMatrixRender data={value.answers} />,
      color: <InputColor data={value.answers} />,
      "matriz-select": <InputMatrixSelectRender data={value.answers} />,
      image: <InputImage data={value.answers} />,
      audio: <InputAudio data={value.answers} />,
      video: <InputVideo data={value.answers} />,
      ...radioCheckbox
    };
  };

  renderPreview = questions => {
    return (
      <>
        {questions.map(value => {
          const input = this.inputRender(value);
          return (
            <div key={value.question?.quest}>
              <div
                style={{
                  backgroundColor: "#F0F0F0",
                  padding: 5,
                  fontSize: 17,
                  fontWeight: "bold",
                  boxShadow: "black 0px 6px 20px -12px",
                  margin: "10px 0"
                }}
              >
                {value.question?.quest}
              </div>
              {input[value.type]}
            </div>
          );
        })}
      </>
    );
  };
  render() {
    const translate = this.context;
    window.setPageTitle(`${translate("screens.quiz.pageTitle.edit")} - Admin`);

    const handleCloseModal = () => {
      this.setState({
        openModal: false,
        showMedia: false,
        question: { ...this.cleanQuestion() },
        responseQuestion: { ...this.cleanQuestion() }
      });
    };

    const handleOpenModal = () => {
      this.setState({
        openModal: true
      });
    };

    const {
      quizName,
      language,
      questions,
      question,
      answer,
      collection,
      answerSelectedIndex,
      requiredQuestion,
      requiredQuestionType,
      requiredAnswers,
      requiredAnswer,
      requiredNextQuestion,
      select,
      resetInput,
      fragranceId,
      openModal,
      showMedia,
      selectSegment,
      segment,
      quizId
    } = this.state;

    const headRows = [
      { label: translate("screens.quiz.list.actions") },
      {
        column: "arrangeQuestionSequence",
        label: translate("screens.quiz.list.sequence")
      },
      {
        column: "type",
        label: translate("screenApp.quiz.questionType")
      },
      {
        column: "code",
        label: translate("screens.quiz.code.label")
      },
      // {
      //   column: "segment",
      //   label: translate("screens.quiz.segment.label")
      // },
      {
        column: "createdAt",
        label: translate("screens.quiz.list.created")
      },
      {
        column: "question",
        label: translate("screens.quiz.labels.question")
      },
      {
        column: "answers",
        label: translate("screens.quiz.labels.answer")
      },
      {
        column: "answers.endQuiz",
        label: translate("end.quiz")
      }
    ];

    const axles = [
      {
        label: translate("screens.quiz.labels.row"),
        value: "X"
      },
      {
        label: translate("screens.quiz.labels.column"),
        value: "Y"
      }
    ];

    const formatRow = r => {
      return (
        <TableRow hover tabIndex={-1} key={r.quizQuestionId}>
          <TableCell>
            <Grid container alignItems="center" spacing={0}>
              <Grid item>
                <CrudActions
                  actions={["edit", "delete"]}
                  module="question"
                  onConfirmDelete={this.onConfirmDelete}
                  onEdit={this.onEdit}
                  id={r.quizQuestionId}
                  obj={r}
                  onUp={this.onUp}
                  onDown={this.onDown}
                  min={this.state.min}
                />
              </Grid>
              {r.type === "image" && (
                <Grid item>
                  <Tooltip
                    title={translate("screenApp.quiz.images.viewImages")}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openModal: true,
                          showMedia: true,
                          question: r,
                          responseQuestion: r
                        });
                        this.setState(prevState => ({
                          question: { ...prevState.question, type: "" }
                        }));
                      }}
                    >
                      <Collections fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {r.type === "audio" && (
                <Grid item>
                  <Tooltip title={translate("labels_play_audios")}>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openModal: true,
                          showMedia: true,
                          question: r,
                          responseQuestion: r
                        });
                        this.setState(prevState => ({
                          question: { ...prevState.question, type: "" }
                        }));
                      }}
                    >
                      <Collections fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {r.type === "video" && (
                <Grid item>
                  <Tooltip title={translate("labels_play_videos")}>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openModal: true,
                          showMedia: true,
                          question: r,
                          responseQuestion: r
                        });
                        this.setState(prevState => ({
                          question: { ...prevState.question, type: "" }
                        }));
                      }}
                    >
                      <Collections fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </TableCell>
          <TableCell scope="row">{r.arrangeQuestionSequence ?? ""}</TableCell>
          <TableCell scope="row">{r.type ?? ""}</TableCell>
          <TableCell scope="row">{r.code}</TableCell>
          {/* <TableCell scope="row">
          {r.segment}
          </TableCell> */}
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell scope="row">{r.question?.quest}</TableCell>
          <TableCell scope="row">{this.formatAnswers(r.answers)}</TableCell>
          <TableCell scope="row">
            {this.formatAnswersEndQuiz(r.answers)}
          </TableCell>
        </TableRow>
      );
    };

    const enableEditQuiz = false;

    return (
      <>
        <ConfirmDeleteDialog
          open={this.state.confirmDelete}
          closeDialog={() => this.toggleConfirmDialog(false)}
          confirm={() => this.onConfirmDelete(this.state.id)}
        />
        <Form>
          <Loading isLoading={this.state.loading} />
          <Card className="mt-3">
            <Card.Header>
              {translate("screens.quiz.labels.principal")}
            </Card.Header>
            <Card.Body>
              <Row>
                <Form.Group
                  as={Col}
                  lg="4"
                  xs="12"
                  className={`${styles.FormGroup} mb-0 pt-2`}
                >
                  <Form.Label>
                    {translate("screens.quiz.labels.name")}*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="quizName"
                    placeholder={translate("screens.quiz.placeholders.name")}
                    maxLength="100"
                    value={quizName}
                    isInvalid={quizName && !IsValidName(quizName)}
                    onChange={this.handleChange}
                    required
                    disabled={enableEditQuiz}
                  />
                </Form.Group>

                {this.state.requiredCampaing && (
                  <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
                    <Form.Label>
                      {translate("screens.quiz.labels.campaign")} *
                    </Form.Label>
                    <SelectSearchComponent
                      resetInput={resetInput}
                      required={true}
                      handleChange={e => this.handleChangeSelect(e)}
                      className="kt-width-full"
                      label={translate("screens.quiz.placeholders.campaign")}
                      url={`/campaigns/without-quiz?str=`}
                      value={{ label: this.state.campaignName }}
                      convertObject={obj => ({
                        id: obj.campaignId,
                        value: obj.campaignId,
                        label: `${obj.name}`,
                        obj
                      })}
                    />
                  </Form.Group>
                )}

                {/* IDIOMA */}
                <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate("screens.quiz.labels.language")}
                  </Form.Label>
                  <SelectLanguage
                    handleChangeLanguage={this.handleChangeLanguage}
                    languageValue={language}
                  />
                </Form.Group>
                <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate("screens.quiz.labels.fragrances")} *
                  </Form.Label>
                  <SelectFragrance
                    collection={collection}
                    onFragranceSelected={fragrance => {
                      const e = {
                        id: fragrance.fragranceId,
                        value: fragrance.fragranceId,
                        label: fragrance.name
                      };
                      this.handleChangeSelectFragrances(e);
                    }}
                    fragranceId={fragranceId}
                    disabled={!collection.length || !fragranceId}
                  />
                </Form.Group>
                {/* Segmento */}
                <SelectItemComponent
                  lg="4"
                  label={translate("screens_quiz_segment_label")}
                  name="segment"
                  dataOptions={selectSegmentData}
                  value={segment}
                  required={requiredQuestionType ? { status: "invalid" } : null}
                  validations={"required"}
                  validator={this.props.validator}
                  translate={this.props.translate}
                  onChange={this.handleChange}
                  as="select"
                  errorMessage={{
                    required: requiredQuestionType,
                    label: translate("screens.quiz.errors.questionTypeRequired")
                  }}
                />
                <Form.Group as={Col} lg="12" xs="12">
                  <Form.Label>
                    {translate("screens.quiz.labels.initialInformation")}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="initialInformation"
                    value={this.state.initialInformation}
                    onChange={this.handleChange}
                    rows={5}
                    cols={100}
                  />
                </Form.Group>
              </Row>
            </Card.Body>
            <Card.Footer
              style={{
                backgroundColor: "#fff"
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={this.updateQuiz}
                  style={{ width: "100%" }}
                >
                  {translate("labels_buttons_edit")}
                </Button>
              </Stack>
            </Card.Footer>
          </Card>
          {/* Inicio Card 2 */}
          <Card className="mt-3">
            <Card.Header>
              {translate("screens.quiz.labels.questionarie")}
            </Card.Header>
            <Card.Body>
              <Form.Group as={Col} lg="12" xs="12" className="mt-3">
                <Row>
                  <Form.Group as={Col} lg="12" xs="12" className="mb-5">
                    {/* Pergunta */}
                    <Row>
                      <Form.Group as={Col} lg="9" xs="12" className="mb-2">
                        <Form.Label>
                          {translate("screens.quiz.labels.question")}
                        </Form.Label>
                        <SelectSearchComponent
                          value={
                            question.questionId && {
                              id: question.questionId,
                              value: question.questionId,
                              label: question.quest,
                              mandatory: question.mandatory
                            }
                          }
                          required={
                            requiredQuestion ? { status: "invalid" } : null
                          }
                          resetInput={question.quest === ""}
                          handleChange={this.handleQuestion}
                          name="question"
                          className="kt-width-full"
                          label={translate(
                            "screens.quiz.placeholders.question"
                          )}
                          url={`/question/by-name?language=${language}&str=`}
                          convertObject={obj => ({
                            id: obj.questionId,
                            value: obj.questionId,
                            label: obj.quest
                          })}
                          createLabelText={translate(
                            "screens.quiz.placeholders.newQuestion"
                          )}
                          newOption={true}
                        />
                        {requiredQuestion ? (
                          <Form.Label style={{ color: "#FF0000" }}>
                            {translate("screens.quiz.errors.questionRequired")}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      {/* Tipo de pergunta */}
                      <SelectItemComponent
                        label={translate("screenApp.quiz.questionType")}
                        name="type"
                        dataOptions={select}
                        value={question.type}
                        required={
                          requiredQuestionType ? { status: "invalid" } : null
                        }
                        validations={"required"}
                        validator={this.props.validator}
                        translate={this.props.translate}
                        onChange={this.handleQuestionType}
                        as="select"
                        errorMessage={{
                          required: requiredQuestionType,
                          label: translate(
                            "screens.quiz.errors.questionTypeRequired"
                          )
                        }}
                      />
                    </Row>
                    <Row>
                      <Form.Group as={Col} lg="6" xs="12">
                        <Form.Label>
                          {translate("screens.quiz.labels.mandatoryQuestion")}
                        </Form.Label>
                        <Checkbox
                          required
                          className="mb-1"
                          checked={question.mandatory}
                          onChange={() => this.checkMandatoryQuestion(question)}
                          name="mandatoryQuestion"
                          color="default"
                        />
                      </Form.Group>
                      {/* Novos campos, Code e Segment */}
                      {/* Code */}
                      <Form.Group as={Col} lg="3" xs="12">
                        <Form.Label>
                          {translate("screens_quiz_code_label")}
                        </Form.Label>
                        <Form.Control
                          name="code"
                          value={question.code}
                          placeholder={translate(
                            "screens_quiz_code_placeholder"
                          )}
                          onChange={this.handleQuestionCodeSegment}
                        />
                      </Form.Group>
                    </Row>
                    {/* Resposta */}
                    <Row>
                      <Form.Group as={Col} lg="9" xs="12" className="mb-2">
                        <Form.Label>
                          {translate("screens.quiz.labels.answer")}
                        </Form.Label>
                        {question.type === "color" ? (
                          <InputGroup className="mb-3">
                            <ColorPicker
                              color={this.state.color}
                              onChange={this.handleChangeColor}
                            />
                          </InputGroup>
                        ) : (
                          <SelectSearchComponent
                            value={
                              answerSelectedIndex !== null
                                ? {
                                    id: answer.answerId,
                                    value: answer.answerId,
                                    label: answer.desc,
                                    description: answer.description,
                                    endQuiz: answer.endQuiz
                                  }
                                : this.props.value
                            }
                            resetInput={answer.desc === ""}
                            handleChange={this.handleAnswer}
                            name="answer"
                            className="kt-width-full"
                            label={translate(
                              "screens.quiz.placeholders.answer"
                            )}
                            url={`/answer/by-name?language=${language}&str=`}
                            convertObject={obj => ({
                              id: obj.answerId,
                              value: obj.answerId,
                              label: obj.label,
                              description: obj?.description,
                              axle: obj?.axle
                            })}
                            newOption={true}
                            createLabelText={translate(
                              "screens.quiz.placeholders.newAnswer"
                            )}
                          />
                        )}

                        {requiredAnswer ? (
                          <Form.Label style={{ color: "#FF0000" }}>
                            {translate("screens.quiz.errors.answerRequired")}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      {(question.type === "radio" ||
                        question.type === "select") && (
                        <Form.Group as={Col} lg="6" xs="12">
                          <Form.Label> {translate("end_quiz")}</Form.Label>
                          <Checkbox
                            required
                            className="mb-1"
                            checked={answer.endQuiz}
                            onChange={() => this.checkEndQuiz({ ...answer })}
                            name="endQuiz"
                            color="default"
                          />
                        </Form.Group>
                      )}
                      <Form.Group
                        as={Col}
                        lg="3"
                        xs="12"
                        className={`${styles.FormGroup} mb-0`}
                      >
                        <Form.Label>{translate("code_answer")}</Form.Label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          name="responseCode"
                          placeholder={translate("insert_code_answer")}
                          maxLength="100"
                          value={answer.description}
                          resetInput={answer.description === ""}
                          onChange={this.handleAnswerCode}
                          required
                          disabled={enableEditQuiz}
                        />
                      </Form.Group>
                      <ResponseModalMedia
                        responseQuestion={this.state.responseQuestion}
                        question={question}
                        modal={openModal}
                        showMedia={showMedia}
                        handleClose={handleCloseModal}
                        handleOpen={handleOpenModal}
                      />
                    </Row>
                    {/* PRÓXIMA PERGUNTA */}
                    <Row>
                      <FormNextQuestion
                        quizLabels={{
                          title: translate("screens.quiz.labels.next"),
                          placeholder: translate(
                            "screens.quiz.placeholders.next"
                          ),
                          errorsNoQuestionOption: translate(
                            "screens.quiz.errors.noQuestionOption"
                          ),
                          errorsNextRequired: translate(
                            "screens.quiz.errors.nextRequired"
                          )
                        }}
                        questions={questions}
                        isAnswerError={
                          !answer.isAnswerFinish && requiredNextQuestion
                        }
                        answer={answer}
                        setAnswer={this.setAnswer}
                        setRequiredNextQuestion={this.setRequiredNextQuestion}
                      />
                    </Row>
                    <Row>
                      {(question.type === "matriz" ||
                        question.type === "matriz-select") && (
                        <SelectModal
                          value={answer.axle || ""}
                          label={translate("screens.quiz.labels.axle")}
                          options={axles}
                          className={styles.FormGroup}
                          onChange={e =>
                            this.setState({
                              answer: { ...answer, axle: e.target.value }
                            })
                          }
                        />
                      )}
                      {question.type === "matriz-select" && (
                        <Form.Group
                          as={Col}
                          lg="4"
                          xs="12"
                          className={styles.FormGroup}
                        >
                          <SelectModal
                            value={answer.axlePosition || ""}
                            label={translate("screens.quiz.labels.position")}
                            options={positions}
                            className={styles.FormGroup}
                            onChange={e =>
                              this.setState({
                                answer: {
                                  ...answer,
                                  axlePosition: e.target.value
                                }
                              })
                            }
                          />
                        </Form.Group>
                      )}
                    </Row>
                    <Row>
                      <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            const color = this.state.selectedColor;
                            const isSaved = this.addAnswer(color);
                            if (isSaved) {
                              Swal.fire({
                                position: "center",
                                icon: "success",
                                title: translate("defaultMessages_added"),
                                showConfirmButton: false,
                                timer: 1000
                              });
                            }
                          }}
                          className="mt-4"
                        >
                          {answerSelectedIndex !== null
                            ? translate("screens_quiz_labels_updateAnswer")
                            : translate("screens_quiz_labels_addAnswer")}
                        </Button>
                      </Form.Group>
                    </Row>
                  </Form.Group>
                </Row>
                {/* inicio da Tabela */}
                <Row>
                  {requiredAnswers ? (
                    <Form.Label style={{ color: "#FF0000" }}>
                      {translate("screens.quiz.errors.answersRequired")}
                    </Form.Label>
                  ) : null}
                  <TableAnswers
                    question={question}
                    clearAnswer={a => this.clearAnswer(a)}
                    editAnswer={(a, i) => this.editAnswer(a, i)}
                    moveAnswerUp={i => this.moveAnswerUp(i)}
                  />
                  <Form.Group as={Col} lg="12" xs="12" className="mt-3 pt-2">
                    <div className="text-left">
                      <Stack
                        sx={{ marginTop: "30px" }}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                      >
                        <Button
                          variant="contained"
                          color="info"
                          onClick={this.onPreviewForm}
                          className="mr-3"
                        >
                          {translate("button_preview")}
                        </Button>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={this.createQuestion}
                          className="mr-3"
                        >
                          {question.quizQuestionId
                            ? translate("screens.quiz.labels.updateQuestion")
                            : translate("screens.quiz.labels.saveQuestion")}
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={this.onCleanForm}
                          className="mr-3"
                        >
                          {translate("buttons.clean")}
                        </Button>
                        <Link to={`/admin/form`} className="mr-3">
                          <Button
                            variant="contained"
                            color="error"
                            type="button"
                          >
                            {translate("buttons.cancel")}
                          </Button>
                        </Link>
                      </Stack>
                    </div>
                  </Form.Group>
                </Row>
              </Form.Group>
              {/* <SequenceQuestions
                questions={questions}
                onSave={v => {
                  this.setState({ questions: v });
                }}
              /> */}
              <ArrangeSequenceModal
                questions={questions}
                onSave={v => {
                  this.getQuiz(quizId);
                }}
              />
              <DatatableStandalone
                headRows={headRows}
                formatRow={formatRow}
                rows={questions}
                orderBy={"arrangeQuestionSequence"}
              />
            </Card.Body>
          </Card>
        </Form>
        <PreviewFormModal
          show={this.state.previewFormModal}
          setShow={this.setShowPreviewForm}
          quizName={quizName}
          initialInformation={this.state.initialInformation}
        >
          {this.renderPreview(
            questions.sort((a, b) => a.quizQuestionId - b.quizQuestionId)
          )}
        </PreviewFormModal>
        {this.state.redirectClone.redirect && (
          <Redirect to={this.state.redirectClone.to} />
        )}
      </>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

const connected = connect(mapStoreToProps, null)(AnamneseUpdate);

export default withRouter(withFormValidation(connected, initialState));
