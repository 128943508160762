import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../../partials/datatable/CrudActions";
import Datatable from "../../../../../partials/datatable/Datatable";
import { CategoryApiService } from "../../../../../services/Ananse/CategoryApiService";

class CategoriesListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new CategoryApiService();
    this.state = {
      categories: [],
      reload: false,
      isActive: "true"
    };

    this.setCategories = this.setCategories.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  setCategories(categories) {
    this.setState({ categories });
  }

  async onConfirmChangeStatus(category) {
    if (category.deletedAt) {
      await this.api.activate(category.categoryId);
    } else {
      await this.api.delete(category.categoryId);
    }

    this.setState({ reload: !this.state.reload });
  }

  async onConfirmDelete(categoryId) {
    await this.api.delete(categoryId, true);
    this.setState({ reload: !this.state.reload });
  }

  render() {
    // window.setPageTitle(`${this.props.translate.screens.category.list.title} - Admin`);
    window.setPageTitle(`Família de Fragrâncias - Admin`);

    const headRows = [
      { label: this.props.translate.screens.category.list.actions },
      {
        column: "Name",
        label: this.props.translate.screens.category.list.name
      },
      {
        column: "CreatedAt",
        label: this.props.translate.screens.category.list.created
      }
    ];

    const formatRow = r => {
      const actions = [];
      if (!r.deletedAt) {
        actions.push("edit");
      }

      if (r.deletedAt || !r.fragrances || !r.fragrances.length) {
        actions.push("toggle");
      }

      if (r.deletedAt && (!r.fragrances || !r.fragrances.length)) {
        actions.push("delete");
      }

      return (
        <TableRow hover tabIndex={-1} key={r.categoryId}>
          <TableCell>
            <CrudActions
              actions={actions}
              module="categories"
              onConfirmChangeStatus={this.onConfirmChangeStatus}
              onConfirmDelete={this.onConfirmDelete}
              id={r.categoryId}
              obj={r}
            />
          </TableCell>
          <TableCell scope="row">{r.name}</TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Datatable
        title={this.props.translate.screens.category.list.type}
        endpoint="/categories"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.categories}
        setRows={this.setCategories}
        reload={this.state.reload}
        placeholderSearch={
          this.props.translate.screens.category.placeholders.search
        }
        isActive={this.state.isActive}
        buttons={[
          {
            label: this.props.translate.datatable.add,
            onClick: null,
            icone: "add",
            path: "/admin/categories/create"
          }
        ]}
      />
    );
  }
}

export default CategoriesListing;
