import * as React from "react";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider
} from "@mui/material";

import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import Variants from "./Variants";

const api = new AnanseApiService();

interface Props {
  endPoint: string;
  value: any;
  handleMultiSelect: (value: any) => void;
  placeholder: string;
}

function not(a: readonly number[], b: readonly number[]) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

export default function TransferList({
  endPoint,
  handleMultiSelect,
  value: getvalue
}: Props) {
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [left, setLeft] = React.useState<Array<any>>([]);
  const [right, setRight] = React.useState<Array<any>>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  React.useEffect(() => {
    if (getvalue) {
      handleUserIds();
    } else {
      handleUserIds();
    }
  }, [getvalue]);

  const handleUserIds = React.useCallback(async () => {
    const leftUsers: Array<any> = await getListFromValue();
    const rightUsers: Array<any> = [];
    if (getvalue) {
      distributeUsers(leftUsers, rightUsers);
    } else {
      setLeft(leftUsers);
    }
  }, [left, getvalue]);

  const distributeUsers = (leftUsers: Array<any>, rightUsers: Array<any>) => {
    const idsArray = getvalue.split(";").filter((id: string) => id !== ""); // Filtrar para remover elementos vazios
    const mapUserId: Array<{ id: number }> = idsArray.map((id: string) => {
      return { id: parseInt(id) };
    });
    for (let i = 0; i < mapUserId.length; i++) {
      const rightUser = setRightUsers(leftUsers, mapUserId[i].id);
      if (!rightUser) {
        continue;
      }
      rightUsers.push(rightUser);
    }
    setRight(rightUsers);
    setLeft(
      leftUsers.filter(
        element => !rightUsers.some(ele => ele.id === element.id)
      )
    );
  };

  const setRightUsers = (leftUsers: Array<any>, mapUserId: number) => {
    return leftUsers.find(element => element.id === mapUserId);
  };

  const getListFromValue = async () => {
    setLoading(true);
    let searchedList: any = [];
    try {
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: `/${endPoint}`
      });
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/${endPoint}?PerPage=${total ?? 99}`
      });
      if (response.data.length) {
        searchedList = [...response.data];
      }
    } catch (error) {
      console.error(`Erro ao fazer a busca: ${error}`);
    } finally {
      setLoading(false);
      return searchedList;
    }
  };

  const handleToggle = (value: any) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    handleMultiSelect(right.concat(leftChecked));
  };

  const numberOfChecked = (items: readonly number[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    handleMultiSelect(not(right, rightChecked));
  };

  const customList = (title: React.ReactNode, items: readonly number[]) => (
    <Grid item>
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              color="success"
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected"
              }}
            />
          }
          subheader={`${numberOfChecked(items)}/${items.length}`}
        />
        <Divider />
        <List
          sx={{
            width: 200,
            height: 230,
            bgcolor: "success",
            overflow: "auto"
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: any) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;

            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={() => handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    color="success"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.nome} />
              </ListItem>
            );
          })}
        </List>
      </Card>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {loading ? (
          <>
            <Grid item>
              <Variants />
            </Grid>
            <Grid item>
              <Variants />
            </Grid>
          </>
        ) : (
          <>
            {customList(
              "Não cadastrados",
              left.sort((a: any, b: any) => a.nome - b.nome)
            )}
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Divider />
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            {customList(
              "Cadastrados",
              right.sort((a: any, b: any) => a.nome - b.nome)
            )}
          </>
        )}
      </Grid>
    </>
  );
}
