import { QuestionApiService } from "app/services/Ananse/QuestionApiService";
import { useEffect, useState } from "react";

type Props = {
  data: Array<QuizQuestionType>;
};

type QuizQuestionType = {
  quizQuestionAnswerId: number;
  answerId: number;
  answer: {
    answerId: number;
    label: string;
    description: string;
    contentLanguage: string;
    files: Array<File> | null;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
  };
  quizQuestionId: number;
  nextQuestionId: number | null;
  axle: string;
  nextQuestion: unknown | null;
  fragranceOptions: Array<unknown>;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

type FileOwnerType = {
  fileId: number;
  fileOwnerId: number;
  fileOwnerType: number;
  name: string;
  path: string;
  tempUri: string;
  file: File | null;
  downloads: number | null;
};

export const InputImage = ({ data }: Props) => {
  const [files, setFiles] = useState<FileOwnerType[]>([]);
  const fileOwnerIdList = data.map(v => v.quizQuestionAnswerId);
  const service = new QuestionApiService();

  useEffect(() => {
    const start = async (): Promise<void> => {
      fileOwnerIdList.forEach(async (value) => {
        const response = await service.getFile(value);
        if (response && response.length > 0 && response[0].tempUri) {
          setFiles(prev => [...prev, response[0]]);
        }
      });
    };
    start();
  }, []);

  return (
    <div>
      {files.map(v => (
        <ImageBox key={v.fileId} image={v.tempUri || ''} />
      ))}
    </div>
  );
};

type ImageBoxProps = {
  image: string;
};

export const ImageBox = ({ image }: ImageBoxProps) => {
  const [checked, setChecked] = useState(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  return (
    <div
      onClick={handleChecked}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: "inline-block",
        margin: 5,
        width: 140,
        height: 140,
        borderRadius: 5,
        position: "relative",
        border: "2px solid white",
        boxShadow: "black 0px 4px 12px -8px"
      }}
    >
      {checked && (
        <input
          type="checkbox"
          checked={checked}
          style={{ position: "absolute" }}
        />
      )}
    </div>
  );
};
