import React from "react";
import { Container, ButtonForm, DownloadButton } from './style';
import translate from '@noar-firmenich/commons';
import { useSelector } from "react-redux";

export default function UserLogin({closeModal, bg}) {
    const locale = useSelector(({ i18n }) => i18n.lang);

    return (
        <Container bg={bg}>
            <h1>{translate(locale).defaultMessages.ops}</h1>
            <h3>{translate(locale).defaultMessages.loginUserMessage}</h3>

            <ButtonForm>
                <DownloadButton onClick={closeModal} className="btn btn-primary" >
                    {translate(locale).defaultMessages.ok}
                </DownloadButton>
            </ButtonForm>
        </Container>
    );
}
