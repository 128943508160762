import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formatToCPF, formatToPhone, isCPF, isPhone } from "brazilian-values";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
  Button,
  Icon,
  Stack,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent,
  MobileStepper,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";

import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import Loading from "app/pages/home/components/Loading";
import store from "app/store/store";
import FormActions from "components/FormActions";
import { SelectPage, SelectSearch, TransferList } from "components";
import { Edit } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TableWithAutoPagination from "./component/TableUser";
import DeleteUser from "./component/DeleteUser";
import { IFormUser } from "types";
import { useTranslation } from "_metronic/i18n/language";

interface IConvertObject {
  id: number;
  value: number;
  label: string;
  obj: any;
}

interface Props {
  form: any;
}
const FormUser: React.FC<Props> = ({ form }) => {
  const params = useParams();
  const history = useHistory();

  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [quizSelected, setQuizSelected] = useState<IConvertObject | undefined>(
    {} as IConvertObject
  );
  const [formQuiz, setFormQuiz] = useState({} as IFormUser);
  const [dataFormUser, setDataFormUser] = useState<IFormUser[]>([]);
  const [users, setUsers] = useState([]);
  const [validateForm, setValidateForm] = useState(false);;

  const schema = Yup.object().shape({
    userId: Yup.number()
      .required(translate('screens.login.validations.required'))
      .min(1, translate('screens.login.validations.minValue'))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      formId: form.formId,
      userId: 0
    }
  });

  useEffect(() => {
    loadUsers();
    loadingData();
  }, []);

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      formId: form.formId
    };

    setLoading(true);
    try {
      let reqBase: any;
      if (data.formUserId) {
        reqBase = { method: "PUT", url: `/formuser/${data.formUserId}` };
      } else {
        reqBase = { method: "POST", url: "/formuser" };
      }

      const response = await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      setFormQuiz(response);
      Swal.fire({
        title: translate('defaultMessages.success'),
        text: translate('crudMessages.successText'),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          loadingData();
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const loadUsers = async () => {
    setLoading(true);
    try {
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: "/usuario"
      });
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/usuario?PerPage=${total}`
      });
      const newData = response.data;
      setUsers(newData);
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const loadingData = async () => {
    setLoading(true);
    try {
      if (form.formId) {
        const { total } = await api.makeHttpRequest({
          method: "GET",
          url: "/formuser"
        });
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/formuser?PerPage=${total}`
        });
        const newData = response.data;
        const filterFormQuiz = newData
          .filter((v: any) => v.formId === form.formId)
          .sort((a: any, b: any) => a.userId - b.userId);
        setDataFormUser(filterFormQuiz);
      }
      loadUsers();
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
    setValidateForm(false);
  };

  const selectUser = (id: number) => {
    const findUser: any = users.find((user: any) => user.id === id);
    return findUser;
  };

  const onEdit = (value: IFormUser) => {
    reset({
      ...value
    });
    const dataQ: IConvertObject = {
      label: "conf",
      id: value.userId,
      value: value.userId,
      obj: {}
    };
    setQuizSelected(dataQ);
  };

  const formatHead = [
    { column: "acoes", label: translate('screens.dashboard.action') },
    { column: "nome", label: translate('screens.quiz.list.name') }
  ];

  const formatRow = (r: any) => {
    return (
      <TableRow key={r.formUserId}>
        <TableCell>
          <IconButton>
            <DeleteUser item={r} loadingData={loadingData} />
          </IconButton>
          <IconButton onClick={() => onEdit(r)}>
            <Edit />
          </IconButton>
        </TableCell>
        <TableCell>{selectUser(r.userId)?.nome}</TableCell>
      </TableRow>
    );
  };

  const validateDataSelected = (e: any, onChange: (e: any) => void) => {
    const checkData = dataFormUser.filter(f => f.userId === e.id);
    if (checkData.length) {
      alert(
        `${translate('defaultMessages.warnning')} /n ${
          translate('defaultMessages.errorRegister')
        }`
      );
      setValidateForm(false);
      return false;
    }
    setQuizSelected(e);
    onChange(e.id);
    setValidateForm(true);
    return true;
  };

  return (
    <>
      <TableWithAutoPagination
        dataFormUser={dataFormUser}
        formatRow={formatRow}
        formatHead={formatHead}
      />
      <Grid2 xs={7}>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card.Header>
              {translate('screens.fragrance.placeholders.selectUser')}{" "}
              "{form.formName}"
            </Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} lg="12" xs="12">
                  <Controller
                    name="userId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectPage
                        endPoint={`/usuario?IsActive=true`}
                        label={translate('screens.user.labels.name')}
                        value={value}
                        convertObject={(obj: any) => ({
                          id: obj.id,
                          label: obj.nome,
                          value: obj.id,
                          obj
                        })}
                        handleChange={e => {
                          validateDataSelected(e, onChange);
                        }}
                      />
                    )}
                  />
                  {errors.userId && (
                    <span className="invalid-input">
                      {errors.userId.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </Card.Body>
            <FormActions
              module="flow"
              onReset={handleReset}
              disableSubmit={!validateForm}
            />
          </Form>
        </Card>
      </Grid2>

      <Loading isLoading={loading} />
    </>
  );
};

export default FormUser;
