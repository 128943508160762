import React from "react";

import "./Styles.scss";

export default function Es() {
  return (
    <div className="privacy-page">
      <h2>
        <strong>POLÍTICA DE PRIVACIDAD</strong>
      </h2>

      <p>
        En <strong>NOAR</strong> nos tomamos muy en serio su privacidad y la
        protección de sus datos y estamos comprometido en el cumplimiento de la
        lineamientos de la Ley General de Protección de Datos (LGPD) respecto al
        uso de datos personales. En este sentido desarrollamos este{" "}
        <strong>Aviso de Privacidad</strong>, cuyo objetivo es informarles a
        ustedes, usuarios y socios de <strong>NOAR</strong>, como tratamos sus
        datos personales, cuáles son sus derechos y cómo puede ejercerlos.
        <br />
        <br />
        Esperamos que la información le ayude a comprender el tratamiento de sus
        datos y que demuestre la
        <strong>NOAR</strong> está comprometida con la privacidad, la protección
        de datos y la seguridad de la información.
        <br />
        <br />
        <br />
        <strong>
          1. CONCEPTOS BÁSICOS: QUÉ NECESITA SABER PARA ENTENDER ESTA POLÍTICA?
        </strong>
        <br />
        <br />
        Para simplificar la lectura de este documento, presentamos algunas
        definiciones útiles para su interpretación, 13.709/18, también conocida
        como Ley General de Protección de Datos, o LGPD:
        <br />
        <br />
        <strong>Datos Personales:</strong> Es información relativa a una persona
        física y que es capaz de identificar a la persona o convertirse
        identificación posible. Ejemplos de datos personales que pueden permitir
        su identificación son: Nombre, CPF, teléfono, correo electrónico,
        matrícula de su vehículo, etc.;
        <br />
        <br />
        <strong>Procesamiento:</strong> Es toda la forma en que podemos usar sus
        datos personales, incluyendo, pero no limitando a siguientes
        actividades: recopilar, almacenar, consultar, usar, compartir,
        transmitir, clasificar, reproducción, borrado y evaluación. Este término
        (y otros derivados de él) no se utilizarán con mayúsculas en este
        documento.
        <br />
        <br />
        <strong>Titular:</strong> es usted, la persona física a quien se
        refieren los datos personales.
        <br />
        <br />
        <strong>Empresa:</strong> Somos NOAR
        <br />
        <br />
        <br />
        <strong>
          2. CÓMO RECOPILAMOS datos personales EN LA APLICACIÓN DE SALUD?
        </strong>
        <br />
        <br />
        Sus datos personales podrán ser recabados a través de las siguientes
        acciones, a saber:
        <ul>
          <li>
            Registro de usuario en la aplicación SALUD, en la que se recoge el
            nombre del usuario, correo electrónico, CPF y sexo y número de
            teléfono.
          </li>
          <br />
          <li>
            Registro de pacientes en la aplicación, en la que recogemos el
            nombre, correo electrónico, CPF y sexo y teléfono del usuario.
          </li>
          <br />
          <li>Ubicación del usuario al realizar un examen</li>
        </ul>
        <br />
        <br />
        <br />
        <strong>3. POR QUÉ SE RECOPILAN DATOS?</strong>
        <br />
        <br />
        Los datos del usuario se recopilan para habilitar el uso de la
        aplicación y la facturación. Los datos del paciente están registrados
        para permitir la emisión de los informes de las pruebas realizadas.
        <br />
        <br />
        Se utiliza la ubicación del usuario en el momento de la prueba para
        permitir el tratamiento estadístico de los informes emitidos,
        permitiendo la elaboración de investigaciones clínicas sobre sus
        resultados y su perfeccionamiento técnico.
        <br />
        <br />
        <br />
        <strong>4. NOAR COMPARTE SUS datos personales?</strong>
        <br />
        <br />
        No, NOAR no comparte sus datos personales. En el caso de utilizar datos
        recogidos en estudios clínicos, una anonimización y agregación de
        información, impidiendo cualquier identificación personal.
        <br />
        <br />
        <br />
        <strong>5. ALMACENAMIENTO DE datos personales</strong>
        <br />
        <br />
        NOAR cuenta con una política de retención de datos personales acorde con
        la legislación aplicable. Tus datos serán almacenados solo durante el
        período necesario para cumplir con el propósito para el que fueron
        recopilados, principalmente para la ejecución del contrato, salvo que
        exista otra razón para mantenerlo, como, por ejemplo, ejemplo, el
        cumplimiento de las obligaciones legales, reglamentarias, contractuales
        y el ejercicio regular de los derechos.
        <br />
        <br />
        Siempre realizamos un análisis técnico para determinar el período de
        retención adecuado para cada tipo de datos personales. recogidos,
        considerando su naturaleza, necesidad de recogida y finalidad para la
        que serán tratados, así como posibles necesidades de retención para el
        cumplimiento de obligaciones o la protección de derechos.
        <br />
        <br />
        En otros casos, eliminaremos nuestras bases de datos o las
        anonimizaremos, en cuyo caso no podrá se puede identificar más.
        <br />
        <br />
        <br />
        <strong>6. CÓMO PROTEGEMOS SUS datos personales?</strong>
        <br />
        <br />
        Nuestra responsabilidad es cuidar sus datos personales y utilizarlos
        únicamente para los fines descritos en este Política. Para garantizar su
        privacidad y la protección de sus datos personales, NOAR adopta todas
        las medidas medidas técnicas y organizativas para la protección de sus
        datos. Por ello, le advertimos que no le pediremos su contraseña,
        excepto cuando inicia sesión en los canales oficiales de NOAR, y usted
        es responsable de mantener la confidencialidad de su contraseña. acceso
        a la plataforma.
        <br />
        <br />
        <br />
        <strong>7. DERECHOS DEL TITULAR</strong>
        <br />
        <br />
        Los datos personales son suyos y la ley brasileña, a través de la Ley
        General de Protección de Datos - LGPD y otros legislación, le garantiza
        una serie de derechos relacionados con los mismos. Estamos comprometidos
        con el cumplimiento de estos derechos y, en secuencia, le explicaremos
        cuáles son estos derechos y cómo puede ejercerlos:
        <br />
        <br />
        <strong>Confirmación y Acceso:</strong> Puede solicitar a la Empresa que
        confirme la existencia de tratamiento de sus datos Personales para que,
        en su caso, pueda acceder a los mismos, incluso solicitando copia de los
        mismos. registros que tenemos sobre usted
        <br />
        <br />
        <strong>Corrección:</strong> puede solicitar la corrección de sus datos
        personales si están incompletos, inexacto o anticuado
        <br />
        <br />
        <strong>Anonimización, bloqueo o eliminación:</strong> Puede solicitar
        (a) la anonimización de sus Datos personal, para que ya no pueden estar
        relacionados con usted y, por lo tanto, dejan de ser datos personales;
        (b) el bloqueo de su datos personales, suspendiendo temporalmente su
        capacidad para procesarlos para ciertos fines; y (c) a eliminación de
        sus datos personales, en cuyo caso debemos eliminar todos sus datos
        personales sin posibilidad de inversión;
        <br />
        <br />
        <strong>Portabilidad:</strong> Usted puede solicitar que la Compañía
        proporcione sus datos personales en un formato estructurado y
        interoperable con vistas a su transferencia a un tercero, siempre que
        dicha transferencia no infrinja la propiedad la propiedad intelectual o
        el secreto comercial de la empresa;
        <br />
        <br />
        <strong>Información sobre compartir:</strong> Tiene derecho a saber qué
        entidades pública y privada con los que la Empresa hace uso compartido
        de sus datos personales. Mantendremos, en el punto 3 de esta Política,
        una indicación de nuestras relaciones con terceros que pueden implicar
        el intercambio de datos personales. En todo caso, si tiene preguntas o
        desea más detalles, tiene derecho a solicitarnos esta información.
        Dependiendo de Si es así, podemos limitar la información que le
        proporcionamos si su divulgación infringiera la propiedad intelectual. o
        secreto comercial de la Compañía;
        <br />
        <br />
        <strong>Información sobre la posibilidad de no consentir:</strong> Tiene
        derecho a recibir información clara y completo sobre la posibilidad y
        consecuencias de no prestar el consentimiento, cuando así lo solicite la
        Empresa. O su consentimiento, en caso necesario, debe ser libre e
        informado. Por lo tanto, siempre que le solicitemos su consentimiento,
        eres libre de negarlo – en estos casos, es posible que algunos servicios
        no puedan ser proporcionados;
        <br />
        <br />
        <strong>Revocación del consentimiento:</strong> si ha dado su
        consentimiento para el procesamiento de su datos personales, siempre
        puede optar por retirar su consentimiento. Sin embargo, esto no afectará
        la legalidad de cualquier Tratamiento realizado con anterioridad a la
        revocación. Si retira su consentimiento, es posible que seamos no poder
        brindarle ciertos servicios, pero le notificaremos cuando esto ocurra;
        <br />
        <br />
        <strong>Oposición:</strong> La ley autoriza el tratamiento de datos
        personales aún sin su consentimiento o una contrato con nosotros. en
        estos situaciones, solo procesaremos sus datos personales si tenemos
        razones legítimas para hacerlo, como, por ejemplo, cuando sea necesario
        para garantizar la seguridad de nuestras carreteras. Si no está de
        acuerdo con algún propósito de tratamiento de sus datos personales,
        podrá oponerse, solicitando su interrupción.
        <br />
        <br />
        <br />
        <strong>AVISOS IMPORTANTES</strong>
        <br />
        <br />
        <strong>
          Para su seguridad, cada vez que envíe una solicitud para ejercer sus
          derechos, la Compañía puede solicitar alguna información y/o
          documentos adicionales para que podamos probar su identidad, buscando
          prevenir el fraude. Hacemos esto para garantizar la seguridad y
          privacidad de todos.
        </strong>
        <br />
        <strong>
          En algunos casos, la Compañía puede tener razones legítimas para no
          cumplir con una solicitud para ejercer derechos. Estas situaciones
          incluyen, por ejemplo, casos en los que la divulgación de información
          específica podría violar derechos de propiedad intelectual o secretos
          empresariales de la Compañía o de terceros, así como los casos en que
          peticiones la exclusión de datos no puede cumplirse debido a la
          existencia de la obligación de la Compañía de conservar los datos, ya
          sea para cumplir con obligaciones legales o reglamentarias o para
          permitir que la Compañía o terceros se defiendan en disputas en
          cualquier naturaleza.
        </strong>
        <br />
        <br />
        <strong>
          Asimismo, es posible que algunas solicitudes no se respondan de
          inmediato, pero la Compañía se compromete a responder todas las
          solicitudes en un plazo razonable y siempre de conformidad con la
          legislación aplicable.
        </strong>
        <br />
        <br />
        Si tiene alguna pregunta sobre estos temas o sobre cómo puede ejercer
        sus derechos, no dude en ponerse en contacto con nosotros. para
        contactarnos a través de los canales provistos al final de esta
        Política.
        <br />
        <br />
        <br />
        <strong>8. TRANSFERENCIA INTERNACIONAL DE datos personales</strong>
        <br />
        <br />
        Algunos datos pueden almacenarse en otros países, ya que NOAR usa o
        usará el sistema basado en la nube Informática. En su caso, se cumplirá
        con todas las normas de protección de datos.
        <br />
        <br />
        <br />
        <strong>9. CANAL DE COMUNICACIÓN</strong>
        <br />
        <br />
        Si cree que sus datos personales han sido tratados de manera
        incompatible con esta Política o su elecciones como Titular de sus datos
        personales, o, si tiene dudas, comentarios o sugerencias relacionado con
        este Aviso de Privacidad, le solicitamos que se comunique con nuestro
        <strong>A cargo Datos (DPO)</strong>, a través de la dirección de correo
        electrónico <strong>“contato@noarbrasil.com.br”</strong> o por
        <strong>número de teléfono 11 2361-5163.</strong>
        <br />
        <br />
        <br />
        <strong>10. ACTUALIZACIÓN DEL AVISO DE PRIVACIDAD</strong>
        <br />
        <br />
        Como siempre buscamos mejorar nuestros servicios y la forma en que
        operamos, este Aviso de Privacidad puede ser actualizado en cualquier
        momento por XXXXX para reflejar las mejoras realizadas. De esta manera,
        recomendamos Visita periódica a esta página para que esté al tanto de
        los cambios realizados.
      </p>
    </div>
  );
}
