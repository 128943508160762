import Swal from "sweetalert2";

export default class FormHelper {
  constructor(componentRef) {
    this.component = componentRef;
    this.submit = this.submit.bind(this);
  }

  async loadFromService() {
    if (!this.component.props.match.params.id) {
      return;
    }

    this.component.props.showSpinner();

    try {
      const model = await this.component.api.get(
        this.component.props.match.params.id
      );
      this.component.setState({ ...model, ...this.component.mapModelToState(model) });
    } catch (error) {
      Swal.fire(
        this.component.props.translate.defaultMessages.error,
        this.component.props.translate.crudMessages.failLoad,
        "error"
      );
    }

    this.component.props.hideSpinner();
  }

  async submit(evt) {
    evt.preventDefault();
    if (!this.component.props.validateForm()) {
      return;
    }

    this.component.props.showSpinner();
    try {
      const id = this.component.props.match.params.id;
      const payload = this.component.mapStateToModel();
      if (id) {
        await this.component.api.update(id, payload);
      } else {
        await this.component.api.create(payload);
      }

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        this.component.props.translate.defaultMessages.error,
        e.response && e.response.status === 400
          ? e.response.data
          : this.component.props.translate.crudMessages.saveFailed,
        "error"
      );
    }

    this.component.props.hideSpinner();
  }

  async afterSubmit() {
    this.component.onCleanForm();

    const isEditing = !!this.component.props.match.params.id;
    const { value } = await Swal.fire({
      title: this.component.props.translate.defaultMessages.success,
      text: this.component.props.translate.crudMessages.successText,
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing
        ? this.component.props.translate.defaultMessages.ok
        : this.component.props.translate.buttons.newRegister,
      cancelButtonText: this.component.props.translate.buttons.exit
    });

    if (!value || isEditing) {
      this.component.props.history.push(
        "/admin/" + window.location.pathname.split("/")[2]
      );
    }
  }

  async clone(evt) {
    evt?.preventDefault();
    if (!this.component.props.validateForm()) {
      return;
    }

    this.component.props.showSpinner();
    try {
      const id = this.component.props.match.params.id;
      const payload = this.component.mapStateToModel();

      // if (id) {
      //   await this.component.api.update(id, payload);
      // } else {
      // }
      await this.component.api.create(payload);

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        this.component.props.translate.defaultMessages.error,
        e.response && e.response.status === 400
          ? e.response.data
          : this.component.props.translate.crudMessages.saveFailed,
        "error"
      );
    }

    this.component.props.hideSpinner();
  }
}
