import React from "react";

import "./Styles.scss";

export default function Fr() {
  return (
    <div className="privacy-page">
  <h2>
    <strong>POLITIQUE DE CONFIDENTIALITÉ</strong>
  </h2>

  <p>
    Chez <strong>NOAR</strong>, nous prenons votre vie privée et la protection de vos données au sérieux et nous
    sommes
    engagé
    dans l'accomplissement de la
    directives de la loi générale sur la protection des données (LGPD) concernant l'utilisation des données
    personnelles. Dans ce sens, nous avons développé
    cet <strong>avis de confidentialité</strong>, qui vise à vous informer, utilisateurs et partenaires
    de <strong>NOAR</strong>, comme
    nous traitons vos données personnelles, quels sont vos droits et comment vous pouvez les exercer.
    <br />
    <br />
    Nous espérons que les informations vous aideront à comprendre le traitement de vos données et qu'elles démontrent
    la
    <strong>NOAR</strong> s'engage à respecter la confidentialité, la protection des données et la sécurité des
    informations.
    <br />
    <br />
    <br />
    <strong>1. CONCEPTS DE BASE: QUE DEVEZ-VOUS SAVOIR POUR COMPRENDRE CETTE POLITIQUE ?</strong>
    <br />
    <br />
    Afin de faciliter votre lecture de ce document, nous vous présentons quelques définitions utiles à votre
    interprétation,
    13.709/18, également connue sous le nom de Loi générale sur la protection des données, ou LGPD:
    <br />
    <br />
    <strong>Données personnelles:</strong> il s'agit d'informations relatives à une personne physique et qui sont
    capables d'identifier le
    personne ou devenir
    possibilité d'identification. Exemples de données personnelles pouvant permettre votre identification: Nom,
    CPF,
    téléphone, e-mail, plaque d'immatriculation de votre véhicule, etc. ;
    <br />
    <br />
    <strong>Traitement:</strong> nous pouvons utiliser vos données personnelles, y compris, mais pas
    se limiter à
    activités suivantes: collecter, stocker, interroger, utiliser, partager, transmettre, classer,
    reproduction, suppression et évaluation. Ce terme (et d'autres qui en dérivent) ne sera pas utilisé avec des
    majuscules
    dans ce document.
    <br />
    <br />
    <strong>Titulaire:</strong> C'est vous, la personne physique à laquelle se réfèrent les Données Personnelles.
    <br />
    <br />
    <strong>Société:</strong> nous sommes NOAR
    <br />
    <br />
    <br />
    <strong>2. COMMENT COLLECTONS-NOUS LES DONNÉES PERSONNELLES DANS L'APPLICATION SANTÉ ?</strong>
    <br />
    <br />
    Vos données personnelles peuvent être collectées à travers les actions suivantes, à savoir:
  <ul>
    <li>Enregistrement de l'utilisateur dans l'application HEALTH, dans lequel nous collectons le nom, l'e-mail,
      le CPF, le sexe et le numéro de téléphone de l'utilisateur.
    </li>
    <br />
    <li>Enregistrement des patients dans l'application, dans laquelle nous collectons le nom, l'e-mail, le CPF
      et le sexe et le numéro de téléphone de l'utilisateur.</li>
    <br />
    <li>Localisation de l'utilisateur lors de la réalisation d'un examen</li>
  </ul>
  <br />
  <br />
  <br />
  <strong>3. POURQUOI LES DONNÉES SONT-ELLES COLLECTÉES ?</strong>
  <br />
  <br />
  Les données utilisateur sont collectées pour permettre l'utilisation et la facturation de l'application. Les
  données des patients sont enregistrées
  pour permettre la délivrance des procès-verbaux des essais effectués.
  <br />
  <br />
  La localisation de l'utilisateur au moment du test est utilisée pour permettre le traitement statistique des
  rapports émis,
  permettant l'élaboration d'une recherche clinique sur ses résultats et son amélioration technique.
  <br />
  <br />
  <br />
  <strong>4. NOAR PARTAGE-T-IL VOS DONNÉES PERSONNELLES ?</strong>
  <br />
  <br />
  Non, NOAR ne partage pas vos données personnelles. Dans le cas de l'utilisation de données recueillies
  dans des études cliniques, un
  l'anonymisation et l'agrégation des informations, empêchant toute identification personnelle.
  <br />
  <br />
  <br />
  <strong>5. CONSERVATION DES DONNEES PERSONNELLES</strong>
  <br />
  <br />
  NOAR a une politique de conservation des données personnelles conforme à la loi applicable. Vos données
  seront
  conservées uniquement pendant la durée nécessaire à la réalisation de la finalité pour laquelle elles
  ont été collectées,
  principalement pour l'exécution du contrat, à moins qu'il n'existe une autre raison de le maintenir,
  telle que, par exemple,
  exemple, le respect des obligations légales, réglementaires, contractuelles et l'exercice régulier des
  droits.
  <br />
  <br />
  Nous effectuons toujours une analyse technique pour déterminer la période de conservation appropriée
  pour chaque type de données personnelles
  collectées, compte tenu de leur nature, de la nécessité de la collecte et de la finalité pour laquelle
  elles seront traitées, ainsi que
  d'éventuels besoins de conservation pour l'exécution d'obligations ou la protection de droits.
  <br />
  <br />
  Dans d'autres cas, nous supprimerons nos bases de données ou les anonymiserons, auquel cas vous ne
  peuvent être davantage identifiés.
  <br />
  <br />
  <br />
  <strong>6. COMMENT PROTÉGEONS-NOUS VOS DONNÉES PERSONNELLES ?</strong>
  <br />
  <br />
  Notre responsabilité est de prendre soin de vos données personnelles et de les utiliser uniquement aux
  fins décrites dans ce
  Politique. Pour garantir votre vie privée et la protection de vos données personnelles, NOAR adopte
  toutes les mesures
  mesures techniques et organisationnelles pour la protection de vos données. Par conséquent, nous vous
  prévenons que nous ne vous demanderons pas votre mot de passe,
  sauf lorsque vous vous connectez aux canaux officiels de NOAR, et vous êtes responsable de la
  confidentialité de votre mot de passe.
  accès à la plateforme.
  <br />
  <br />
  <br />
  <strong>7. DROITS DU TITULAIRE</strong>
  <br />
  <br />
  Les données personnelles sont les vôtres et la loi brésilienne, à travers la loi générale sur la
  protection des données - LGPD et autres
  législation, vous garantit une série de droits y afférents. Nous nous engageons à respecter
  de ces droits et nous vous expliquerons, dans l'ordre, quels sont ces droits et comment vous pouvez
  les exercer:
  <br />
  <br />
  <strong>Confirmation et accès:</strong> vous pouvez demander à la Société de confirmer l'existence
  du traitement
  de vos Données
  personnelles afin que, le cas échéant, vous puissiez y accéder, y compris en demandant des copies
  des
  dossiers que nous avons sur vous
  <br />
  <br />
  <strong>Correction:</strong> Vous pouvez demander la correction de vos Données Personnelles si
  elles sont incomplètes,
  inexact ou
  dépassé
  <br />
  <br />
  <strong>Anonymisation, blocage ou suppression:</strong> Vous pouvez demander (a) l'anonymisation de
  vos Données
  personnel, de sorte que
  elles ne peuvent plus vous être liées et cessent donc d'être des données personnelles ; (b) le
  blocage de votre
  Données personnelles, suspendant temporairement votre capacité à les traiter à certaines fins ; et
  (c) à
  suppression de vos Données Personnelles, auquel cas nous devons supprimer toutes vos Données
  Personnelles sans possibilité de
  renversement;
  <br />
  <br />
  <strong>Portabilité:</strong> vous pouvez demander à la Société de fournir vos données personnelles
  dans un format
  structuré et
  interopérable en vue de son transfert à un tiers, à condition que ce transfert ne porte pas atteinte
  au droit de propriété
  la propriété intellectuelle ou le secret commercial de l'entreprise ;
  <br />
  <br />
  <strong>Informations concernant le partage:</strong> vous avez le droit de savoir quelles entités
  publique et privée
  avec lesquels la Société fait un usage partagé de vos Données Personnelles. Nous maintiendrons, au
  point 3 de la présente politique, un
  indication de nos relations avec des tiers pouvant impliquer le partage de données personnelles.
  Dans tous les cas,
  si vous avez des questions ou souhaitez plus de détails, vous avez le droit de nous demander ces
  informations. En fonction de la
  Si tel est le cas, nous pouvons limiter les informations qui vous sont fournies si leur divulgation
  enfreignait la propriété intellectuelle
  ou secret d'affaires de la Société ;
  <br />
  <br />
  <strong>Information sur la possibilité de ne pas consentir:</strong> vous avez le droit de recevoir
  des informations claires
  et complet
  sur la possibilité et les conséquences de ne pas donner son consentement, à la demande de la
  Société. O
  leur consentement, le cas échéant, doit être libre et éclairé. Par conséquent, chaque fois que nous
  demandons votre consentement,
  vous êtes libre de le refuser – dans ces cas, il est possible que certains services ne puissent pas
  être fournis ;
  <br />
  <br />
  <strong>Révocation du consentement:</strong> si vous avez consenti au traitement de vos
  Données personnelles,
  vous pouvez toujours choisir de retirer votre consentement. Toutefois, cela n'affectera pas la
  légalité de tout
  Traitement effectué avant la révocation. Si vous retirez votre consentement, nous pourrions être
  incapable de vous fournir certains services, mais nous vous informerons lorsque cela se produit ;
  <br />
  <br />
  <strong>Obposition:</strong> la loi autorise le traitement des données personnelles même sans votre
  consentement ou une
  contrat avec nous. dans ces
  situations, nous ne traiterons vos données personnelles que si nous avons des raisons légitimes de
  le faire, telles que, par exemple,
  lorsque cela est nécessaire pour assurer la sécurité de nos routes. Si vous n'êtes pas d'accord avec
  l'un des objectifs de
  traitement de vos Données Personnelles, vous pouvez vous y opposer en demandant son interruption.
  <br />
  <br />
  <br />
  <strong>AVIS IMPORTANTS</strong>
  <br />
  <br />
  <strong>
    Pour votre sécurité, chaque fois que vous soumettez une demande d'exercice de vos droits, la
    Société peut
    demander des informations et/ou documents complémentaires afin que nous puissions prouver votre
    identité,
    cherchant à prévenir la fraude. Nous le faisons pour assurer la sécurité et la confidentialité de
    chacun.
  </strong>
  <br />
  <strong>
    Dans certains cas, la Société peut avoir des raisons légitimes de ne pas se conformer à une
    demande d'exercice
    droits. Ces situations incluent, par exemple, les cas où la divulgation d'informations
    spécifiques pourrait
    violer
    droits de propriété intellectuelle ou secrets d'affaires de la Société ou de tiers, ainsi que
    les cas où
    demandes
    l'exclusion des données ne peut être satisfaite en raison de l'existence d'une obligation de
    conservation des données de la part de la Société, que ce soit
    pour se conformer aux obligations légales, réglementaires ou pour permettre à la Société ou à
    des tiers de se défendre en cas de litige
    dans
    toute nature.
  </strong>
  <br />
  <br />
  <strong>
    De plus, certaines demandes peuvent ne pas recevoir de réponse immédiate, mais la Société
    s'engage à répondre
    toutes les demandes dans un délai raisonnable et toujours dans le respect de la loi
    applicable.
  </strong>
  <br />
  <br />
  Si vous avez des questions sur ces problèmes ou sur la manière dont vous pouvez exercer vos
  droits, n'hésitez pas à nous contacter.
  pour nous contacter via les canaux fournis à la fin de cette politique.
  <br />
  <br />
  <br />
  <strong>8. TRANSFERT INTERNATIONAL DE DONNEES PERSONNELLES</strong>
  <br />
  <br />
  Certaines données peuvent être stockées dans d'autres pays, car NOAR utilise ou utilisera le
  système basé sur le cloud
  L'informatique. Le cas échéant, toutes les règles de protection des données seront
  respectées.
  <br />
  <br />
  <br />
  <strong>9. CANAL DE COMMUNICATION</strong>
  <br />
  <br />
  Si vous pensez que vos données personnelles ont été traitées d'une manière incompatible
  avec cette politique ou votre
  choix en tant que Titulaire de vos données personnelles, ou, si vous avez des questions,
  des commentaires ou des suggestions
  concernant cet avis de confidentialité, nous vous demandons de contacter notre
  <strong>En charge
    Données (DPO)</strong>, via l'adresse e-mail
  <strong>"contato@noarbrasil.com.br"</strong> ou par
  <strong>numéro de téléphone 11 2361-5163.</strong>
  <br />
  <br />
  <br />
  <strong>10. MISE À JOUR DE L'AVIS DE CONFIDENTIALITÉ</strong>
  <br />
  <br />
  Comme nous cherchons toujours à améliorer nos services et notre façon de fonctionner,
  cet avis de confidentialité
  peut être mis à jour à tout moment par XXXXX pour refléter les améliorations apportées.
  De cette façon, nous recommandons
  Visite périodique de cette page afin que vous soyez au courant des modifications
  apportées.
  </p>
</div>
  );
}
