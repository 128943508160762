import React, { useState } from "react";
import { Link } from "react-router-dom";
import queryString from "querystring";
import styled from "styled-components";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField, Modal } from "@mui/material";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import UserLogin from "./UserLogin";
import Loading from "../home/components/Loading";
import LanguageSelector from "../../partials/layout/LanguageSelector";
import translate from "@noar-firmenich/commons";

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 100%;

  position: relative;
  margin: auto;
  outline: none;
`;

function Login(props) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgetPassModal, setForgetPassModal] = useState(false);
  const [register, setRegister] = useState(false);
  const [error] = useState(null);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setIsLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setIsLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const isLoginExpired = () => {
    const query = queryString.parse(window.location.search.replace("?", ""));
    return query && query.hasOwnProperty("expired");
  };

 async function checkIsUser() {
    return new Promise(resolve => {
      setTimeout(async() => {
        const auth = localStorage.getItem("persist:auth");
        const json = JSON.parse(auth);
        const user = JSON.parse(json.user);
        const body = { auth, json, user };
        resolve(user.profile.nome === "POSTNEWS");
      }, 2000);
    });
  }

  window.setPageTitle("Login");

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-header__topbar__login d-flex flex-row-reverse">
            <LanguageSelector iconType="" />
          </div>
          <div className="kt-login__title">
            <h3>{translate(locale).screens.login.title}</h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = translate(
                  locale
                ).screens.login.validations.required;
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = translate(
                  locale
                ).screens.login.validations.invalid;
              }

              if (!values.password) {
                errors.password = translate(
                  locale
                ).screens.login.validations.required;
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              login(values.email, values.password)
                .then(async authData => {
                  props.login(authData);

                  const isUser = await checkIsUser();
                  if (isUser) {
                    setIsLoading(false);
                    setSubmitting(false);
                    setLoading(false);
                    setIsUserLogged(true);
                    setStatus("");
                  } else {
                    disableLoading();
                    props.history.push("/admin");
                  }
                })
                .catch(e => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    e.response && e.response.data && e.response.data.message
                      ? e.response.data.message
                      : translate(locale).screens.login.validations.loginInvalid
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {(status || isLoginExpired()) && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                      {status ||
                        translate(locale).screens.login.validations
                          .sessionExpired}
                    </div>
                  </div>
                )}

                {error && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{error}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label={translate(locale).screens.login.email}
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    variant="filled"
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={translate(locale).screens.login.password}
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    variant="filled"
                  />
                </div>

                <div className="kt-login__actions mb-0 pb-0">
                  <button
                    type="button"
                    className="btn btn-outline kt-login__btn-secondary pl-2 pr-2"
                    onClick={() => setRegister(true)}
                  >
                    {translate(locale).screens.login.noAccount}
                  </button>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-elevate kt-login__btn-primary ${clsx({
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    })}`}
                    style={loadingButtonStyle}
                  >
                    {translate(locale).screens.login.login}
                  </button>
                </div>
                <div className="kt-login__actions justify-content-center">
                  <Link
                    to="#"
                    className="kt-link kt-login__link-forgot"
                    onClick={() => setForgetPassModal(true)}
                  >
                    {translate(locale).screens.login?.forgetPassword}
                  </Link>
                  <Modal
                    open={forgetPassModal}
                    onClose={() => setForgetPassModal(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <ModalContainer>
                      <ForgotPassword
                        bg="white"
                        closeModal={() => setForgetPassModal(false)}
                      />
                    </ModalContainer>
                  </Modal>
                  <Modal
                    open={register}
                    onClose={() => setRegister(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <ModalContainer>
                      <Register
                        className="shadow"
                        bg="white"
                        closeModal={() => setRegister(false)}
                      />
                    </ModalContainer>
                  </Modal>

                  <Modal
                    open={isUserLogged}
                    onClose={() => setIsUserLogged(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <ModalContainer>
                      <UserLogin
                        bg="white"
                        closeModal={() => setIsUserLogged(false)}
                      />
                    </ModalContainer>
                  </Modal>
                </div>
                <div className="kt-login__actions justify-content-center">
                  <Link
                    to="/privacy-policy"
                    className="kt-link kt-login__link-privacy-policy"
                  >
                    {translate(locale).defaultMessages?.privacyPolicy}
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
