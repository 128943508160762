import { Question } from "app/pages/admin/pages/Anamnese/components/SequenceQuestions";

const useSortSequenceQuestion = (questions: Array<Question>) => {
  const sortedQuestions = [...questions];

  const descSequence = () => {
    return sortedQuestions.sort((a, b) => {
        if (a.arrangeQuestionSequence < b.arrangeQuestionSequence) {
          return -1;
        }
        if (a.arrangeQuestionSequence > b.arrangeQuestionSequence) {
          return 1;
        }
        return 0;
      })
  };

  const ascSequence = () => {
    return sortedQuestions.sort((a, b) => {
        if (a.arrangeQuestionSequence > b.arrangeQuestionSequence) {
          return -1;
        }
        if (a.arrangeQuestionSequence < b.arrangeQuestionSequence) {
          return 1;
        }
        return 0;
      })
  };

  return { ascSequence, descSequence };
};

export default useSortSequenceQuestion;
