import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import React, { useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Card,
  CardContent,
  Grid,
  Box,
  styled,
  Paper,
  Tooltip,
  Button
} from "@mui/material";
import Datatable from "app/partials/datatable/Datatable";
import CrudActions from "app/partials/datatable/CrudActions";
import store from "app/store/store";
import TableData from "app/partials/datatable/TableData";
import { Edit, OpenInBrowser } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, useParams } from "react-router-dom";
import DataTable from "./components/DataTable";
import Swal from "sweetalert2";
import translate from "@noar-firmenich/commons";
import { useSelector } from "react-redux";
import ModalResult from "./components/ModalResult";
import jsPDF from "jspdf";
import { resultSheet } from "./components/renderPDF";
import { IAccount, UseSelectorI } from "interfaces";
import { format, addHours } from "date-fns";
import { formatedDate } from "utils";
import BackWithTitle from "./components/BackWithTitle";
import { titlePage } from "utils/titlepage";
import Loading from "app/pages/home/components/Loading";

interface ParamsI {
  id: number | undefined;
  name: string;
}

interface ResultsI {
  testResultId: any;
  quizAnswer: Array<any>;
  anamneseResultId?: any;
}

const data = [
  {
    testResultId: "jdjfjj-kkdsj-s99f94n-jmk33n3n3",
    quizAnswer: [{ rightAnswer: "menta" }],
    anamneseResultId: "458223-kkdsj-s99f94n-jmk33n3n3"
  },
  {
    testResultId: "jdjfjj-noar-s99f94n-jmk33n3n3",
    quizAnswer: [{ rightAnswer: "menta" }],
    anamneseResultId: null
  },
  {
    testResultId: "458223-kkdsj-s99f94n-jmk33n3n3",
    quizAnswer: [{ rightAnswer: "N/A" }],
    anamneseResultId: null
  }
];
const ResultListing = () => {
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const api = new AnanseApiService();
  const params: any = useParams();
  const account: ParamsI | undefined = params;
  const endPoint = `/quiz/accountid/${account?.id}`;
  const [isActive, setIsActive] = useState("true");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [results, setResults] = useState<Array<ResultsI>>([]);
  const [anamneses, setAnamneses] = useState<Array<ResultsI>>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [itemData, setItemData] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [itIsAnamnese, setIsItAnamnese] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [accountSelected, setAccountSelected] = React.useState({} as IAccount);

  const loadRecords = async () => {
    setLoading(true);
    try {
      getAccount(account?.id);
      const result = await api.makeHttpRequest({ url: endPoint });
      const filterAnamnese = result.filter((ai: any) =>
        ai.quizAnswer.some((v: any) => v.rightAnswer === "N/A")
      );
      const filterResults = result.filter((ai: any) =>
        ai.quizAnswer.some((v: any) => v.rightAnswer !== "N/A")
      );
      setAnamneses(filterAnamnese);
      setResults(filterResults);
      const response = await api.makeHttpRequest({ url: "/question" });
      const resultQuestion = await api.makeHttpRequest({
        url: `/question?PerPage=${response.total}`
      });
      setQuestions(resultQuestion.data);
    } catch (error) {
      Swal.fire(
        translate(locale).defaultMessages.error,
        translate(locale).defaultMessages.loadData,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRecords();
  }, []);

  const getAccount = async (
    accountId: number | undefined
  ): Promise<IAccount | any> => {
    try {
      const response: IAccount = await api.makeHttpRequest({
        url: `/account/${accountId}`
      });
      setAccountSelected(response);
    } catch (error) {
      console.error("error getAccount: ", error);
    }
  };

  const headColumns = [
    { id: "none", label: translate(locale).screens.dashboard.action },
    { id: "id", label: translate(locale).screenApp.results.examDate },
    { id: "id", label: translate(locale).screenApp.results.numberTest },
    { id: "id", label: translate(locale).screenApp.results.title },
    { id: "id", label: translate(locale).screens.login.email },
    { id: "id", label: translate(locale).screens.device.title }
  ];

  const gerarResultado = (value: any) => {
    setItemData(value);
    setIsItAnamnese(false);
    setOpenModal(true);
  };

  const gerarAnamnese = (item: any) => {
    const filterAnamnese: any = anamneses?.find(
      value => value?.testResultId === item?.quizAnswer[0]?.anamneseResultId
    );
    setItemData(filterAnamnese);
    setIsItAnamnese(true);
    setOpenModal(true);
  };

  const formatRows = (row: any, labelId: any) => {
    return (
      <TableRow
        hover
        tabIndex={-1}
        key={row.testResultId}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <Tooltip
            title={translate(locale).screenApp.anamnese.list.viewResults}
          >
            <Button
              // disabled
              variant="contained"
              // endIcon={<OpenInNewIcon fontSize="small" />}
              onClick={() => gerarResultado(row)}
            >
              {translate(locale).screenApp.results.screenName}
            </Button>
          </Tooltip>
          {row?.anamneseResultId && !!row?.anamneseResultId ? (
            <Tooltip
              title={translate(locale).screenApp.anamnese.list.viewAnamnese}
            >
              <Button
                // disabled
                variant="contained"
                // endIcon={<OpenInNewIcon fontSize="small" />}
                onClick={() => gerarAnamnese(row)}
              >
                {translate(locale).screenApp.anamnese.list.anamneseTitle}
              </Button>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell align="inherit">
          {row.quizAnswer[0]?.createdAt
            ? formatedDate(row.quizAnswer[0]?.createdAt, true, true)
            : null}
        </TableCell>
        <TableCell id={labelId} align="inherit">
          {row.testResultId}
        </TableCell>
        <TableCell id={labelId} align="inherit">
          {row.quizAnswer[0]?.doctor}
        </TableCell>
        <TableCell id={labelId} align="inherit">
          {row.quizAnswer[0]?.email}
        </TableCell>
        <TableCell align="inherit">{row.quizAnswer[0]?.macAddress}</TableCell>
      </TableRow>
    );
  };

  const handleSearch = React.useCallback((dataSearch: any, search: string) => {
    const filteredResults =
      dataSearch.filter((item: any) => {
        const quizAnswers = item.quizAnswer;
        return quizAnswers.some((answer: any) =>
          answer.testResultId.toLowerCase().includes(search.toLowerCase())
        );
      }) ?? dataSearch;
    return filteredResults;
  }, []);
  const titleP = translate(locale).screenApp.listPacient.account.titleList;
  React.useEffect(() => {
    titlePage(nameTitle + " - Admin");
  }, [accountSelected?.name]);

  const nameTitle =
    translate(locale).screenApp.anamnese.list.resultsOf + accountSelected?.name;

  return (
    <div>
      <ModalResult
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={itemData}
        locale={locale}
        accountId={account?.id}
        itIsAnamnesis={itIsAnamnese}
      />
      <Loading isLoading={loading} />
      <DataTable
        titleTable={<BackWithTitle title={nameTitle} />}
        headColumns={headColumns}
        rows={handleSearch(results, search)}
        formatRows={formatRows}
        placeholderSearch={
          translate(locale).screenApp.anamnese.list.search +
          " " +
          translate(locale).screenApp.results.numberTest
        }
        loading={loading}
        search={search}
        setSearch={setSearch}
        onRefresh={loadRecords}
      />
    </div>
  );
};

export default ResultListing;
