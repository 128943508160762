const colorsArray = [
  "linear-gradient(to bottom left, #f0c11a,#fee530,#fffa34,#f5cb26,#d6a521,#c08e19,#a6750e,#906209,#855803,#7e5204,#7c4f03,#744200)",
  "#ffcf30",
  "#ffe715",
  "#f2e7b1",
  "#d4ba97",
  "linear-gradient(to right, #b35323,#c25e27,#eb8537,#f79143,#e9833a,#bf5d23,#813117,#782d17,#531912,#481512,#451410,#42120e,#40110e,#3e0b0a)",
  "#9a7352",
  "#8d6f60",
  "#ef5327",
  "#f76a2c",
  "#ec935e",
  "#ffbb7c",
  "#f7cee0",
  "#e1c9d6",
  "#bdb0d0",
  "#5f4a8b",
  "#6a282c",
  "#bf1931",
  "#bd4538",
  "#f77463",
  "#f3d8cd",
  "#f7bac2",
  "#f98ea2",
  "#f97394",
  "#2c4c32",
  "#7cb082",
  "#2cae66",
  "#c6e679",
  "#00b8a0",
  "#2c4fa1",
  "#0f4c82",
  "#35485e",
  "#252429",
  "#67696c",
  "linear-gradient(to right, #636670,#72757e,#84878e,#9699a0,#c0c1c6,#d3d3d6,#f0f0f2,#dedee2,#c0c2cc,#c8cbd4,#afb1bd,#bbbbc5,#c3c4cb,#c7c7cf,#c7c7cf,#b7b7bf,#92939f,#71737f,#5f616d,#666874,#565865)",
  "#dbd9ce",
  "#f4f4f6",
  "#92dce9",
  "#addfde",
  "#97bae0"
];


export default colorsArray;
