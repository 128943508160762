import React, { useCallback, useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { CollectionItem, Fragrance } from "types";
import { useTranslation } from "_metronic/i18n/language";
interface Props {
  collection: CollectionItem[];
  onFragranceSelected: (fragrance: Fragrance | null) => void;
  fragranceId?: number;
  disabled?: boolean;
}

const SelectFragrance: React.FC<Props> = ({
  collection = [],
  onFragranceSelected,
  fragranceId,
  disabled
}) => {
  const [selectedFragrance, setSelectedFragrance] = useState<Fragrance | null>(
    null
  );
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();

  const handleComboBoxChange = (event: any) => {
    const selectedIndex = event.target.value as number;
    if (selectedIndex !== -1) {
      const selectedFragranceInner = collection[selectedIndex].fragrance;
      setSelectedFragrance(selectedFragranceInner);
      onFragranceSelected(selectedFragranceInner);
    } else {
      setSelectedFragrance({} as Fragrance);
      onFragranceSelected({} as Fragrance);
    }
  };

  const recordData = useCallback(() => {
    const selectedIndex = collection.findIndex(
      item => item.fragrance.fragranceId === fragranceId
    );

    if (selectedIndex !== -1) {
      const selectedFragrance = collection[selectedIndex].fragrance;
      setSelectedFragrance(selectedFragrance);
      onFragranceSelected(selectedFragrance);
    } else {
      setSelectedFragrance({} as Fragrance);
      onFragranceSelected({} as Fragrance);
    }
  }, [collection, fragranceId]);

  useEffect(() => {
    recordData();
  }, [recordData]);

  return (
    <div>
      <FormControl fullWidth>
        <Form.Control
          disabled={disabled}
          name="type"
          as="select"
          value={
            selectedFragrance
              ? collection.findIndex(
                  item => item.fragrance === selectedFragrance
                )
              : -1
          }
          onChange={handleComboBoxChange}
        >
          {collection.length ? (
            <option value={-1}>
              {translate('screenApp.quiz.selectOption')}
            </option>
          ) : (
            <option value={-1}>
              {translate('screenApp.quiz.selectCampaign')}
            </option>
          )}
          {collection.map((item, index) => (
            <option key={item.collectionId} value={index}>
              {item.fragrance.name}
            </option>
          ))}
        </Form.Control>
      </FormControl>
    </div>
  );
};

export default SelectFragrance;
