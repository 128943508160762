import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import FormActions from "../../../components/FormActions";
import { AppInput } from "../../../../../partials/form";
import { withFormValidation } from "../../../../../hoc";
import { CategoryApiService } from "../../../../../services/Ananse/CategoryApiService";
import FormHelper from "../../../../../helpers/FormHelper";

const initialState = {
  name: ""
};

class CategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new CategoryApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.props.onCleanForm.bind(this);

    this.formHelper = new FormHelper(this);
  }

  componentDidMount() {
    this.formHelper.loadFromService();
  }

  mapModelToState(model) {
    return {
      name: model.name
    };
  }

  mapStateToModel() {
    return {
      name: this.state.name
    };
  }

  render() {
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${this.props.translate.screens.category.pageTitle.edit} - Admin`
      );
    } else {
      window.setPageTitle(
        `${this.props.translate.screens.category.pageTitle.create} - Admin`
      );
    }

    const { name } = this.state;

    return (
      <Card className="mt-3">
        <Card.Header>
          {this.props.translate.screens.category.mainData}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.formHelper.submit}>
            <Row>
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {this.props.translate.screens.category.labels.name} *
                </Form.Label>
                <AppInput
                  type="text"
                  name="name"
                  placeholder={
                    this.props.translate.screens.category.placeholders.name
                  }
                  maxLength="100"
                  value={name}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|min:3|max:100"}
                />
              </Form.Group>
            </Row>

            <FormActions
              module="categories"
              formIsValid={true}
              onCleanForm={this.onCleanForm}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default withRouter(withFormValidation(CategoryForm, initialState));
