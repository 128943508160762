import React from "react";
import { Dropdown } from "react-bootstrap";

const DashboardDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      id="kt_dashboard_dashboard"
      type="button"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
      className="btn btn-clean btn-sm btn-icon btn-icon-lg"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i className="flaticon-more-1"></i>
    </button>
  );
});

export default class DashboardDropdown extends React.Component {
  disableDropdown() {
    document.querySelectorAll("#kt_dashboard_dashboard")[0].click();
  }

  render() {
    return (
      <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
        <Dropdown.Toggle as={DashboardDropdownToggle} id="dropdown-toggle-top">
          <i className="flaticon-more-1" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
          <ul className="kt-nav">
            <li className="kt-nav__section kt-nav__section--first">
              <span className="kt-nav__section-text">
                {this.props.translate.screens?.dashboard?.action}
              </span>
            </li>

            <li className="kt-nav__item">
              <a className="kt-nav__link">
                <i className="kt-nav__link-icon flaticon2-refresh"></i>
                <span
                  className="kt-nav__link-text"
                  onClick={() => {
                    this.props.onClickRefresh();
                    this.disableDropdown();
                  }}
                >
                  {this.props.translate.datatable?.refresh}
                </span>
              </a>
            </li>

            <li className="kt-nav__section kt-nav__section--first">
              <span className="kt-nav__section-text">
                {this.props.translate.datatable?.export}
              </span>
            </li>
            <li className="kt-nav__item">
              <a className="kt-nav__link">
                <i className="kt-nav__link-icon flaticon2-download"></i>
                <span
                  className="kt-nav__link-text"
                  onClick={() => {
                    this.props.onClickExport();
                    this.disableDropdown();
                  }}
                >
                  {this.props.translate.screens?.dashboard?.download}
                </span>
              </a>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
