import {
  Button,
  Icon,
  Stack,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import translate from "@noar-firmenich/commons";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { useEffect, useState } from "react";
import Loading from "app/pages/home/components/Loading";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import store from "app/store/store";
import FormActions from "components/FormActions";
import { ToggleOff, ToggleOn } from "@mui/icons-material";

const DeleteQuiz = ({ item, loadingData }: any) => {
  const params = useParams();
  const history = useHistory();

  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const onDelete = async () => {
    const { formQuizId, formId }: any = item;
    let payload = item;
    setLoading(true);
    try {
      if (!item.deletedAt) {
        payload = { ...payload, deletedAt: new Date() };
        await api.makeHttpRequest({
          method: "DELETE",
          url: `/formquiz/${formQuizId}`,
        });
      } else {
        payload = { ...payload};
        const result = await api.makeHttpRequest({
          method: "PUT",
          url: `/formquiz/activate/${formQuizId}`
        });
      }
      Swal.fire({
        title: translate(locale).defaultMessages?.success,
        text: translate(locale).defaultMessages?.deletedSuccess,
        icon: "success",
        cancelButtonText: translate(locale).buttons?.exit
      }).then(result => {
        if (result.isConfirmed) {
          loadingData()
        }

      });
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: translate(locale).defaultMessages?.success,
        text: translate(locale).defaultMessages?.deletedSuccess,
        icon: "success",
        cancelButtonText: translate(locale).buttons?.exit
      }).then(result => {
        if (result.isConfirmed) {
          loadingData()
        }
      });
    } finally {
      setLoading(false);
      setVisible(false);
      loadingData()
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        // className={classes.button}
        title={item.deletedAt
          ? translate(locale).changeStatus.active
          : translate(locale).changeStatus.inactive}
        onClick={() => setVisible(!visible)}
      >
        {item.deletedAt ? (
          <ToggleOff fontSize="small" />
        ) : (
          <ToggleOn fontSize="small" />
        )}
      </IconButton>
      <Loading isLoading={loading} />

      <Dialog
        open={visible}
        onClose={setVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate(locale).changeStatus.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate(locale).changeStatus.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setVisible(false)}
            variant="contained"
            color="error"
            type="button"
            className="mr-3"
          >
            {translate(locale).buttons.cancel}
          </Button>
          <Button
            onClick={onDelete}
            variant="contained"
            color="success"
            className="btb-sucess"
            type="submit"
          >
            {translate(locale).buttons.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteQuiz;
