import { fetchAllLocales } from "app/services/Localise/localiseApi";
import React, { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { useSelector } from "react-redux";

interface RootState {
  i18n: {
    lang: string;
  };
}

export const TranslationContext = createContext<any>(null);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {

  const locale = useSelector((state: RootState) => state.i18n.lang);

  const [translationsData, setTranslationsData] = useState<any>(null);

  useEffect(() => {
    const fetchLocales = async () => {
      try {
        const localesData = await fetchAllLocales();
        setTranslationsData(localesData);
      } catch (error) {
        console.error('Erro ao buscar os locais:', error);
      }
    };

    fetchLocales();
  }, [locale]);

  const localeMapping: Record<string, keyof typeof translationsData> = {
    pt: 'pt-BR',
    en: 'en-US',
    fr: 'fr-FR'
  };

  const formattedLocale = localeMapping[locale];

  const translate = (key: string, vars?: Record<string, any>) => {
    const underscoreKey = key.replace(/\./g, "_");

    const translations = translationsData[formattedLocale];

    if (
      translations &&
      (translations[underscoreKey as keyof typeof translations] ||
        translations[key as keyof typeof translations])
    ) {
      const translation =
        translations[underscoreKey as keyof typeof translations] ||
        translations[key as keyof typeof translations];

      let translatedString = translation;

      if (vars && typeof vars === "object") {
        for (const prop in vars) {
          if (vars.hasOwnProperty(prop)) {
            translatedString = translatedString.replace(
              new RegExp(`{{${prop}}}`, "g"),
              vars[prop]
            );
          }
        }
      }

      return translatedString;

    } else {
      return key;
    }
  };

  if (!translationsData) {
    return null;
  }

  return (
    <TranslationContext.Provider value={translate}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
