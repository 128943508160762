import { CRUDApiService } from "./CRUDApiService";

export class QuestionApiService extends CRUDApiService {
  constructor() {
    super('question');
  }

  async getFile(fileOwnerId) {
    const reqBase = {
      method: "GET",
      url: `by-fileownerid?fileOwnerId=${fileOwnerId}`
    };
    const response = await this.makeHttpRequest(reqBase);
    return response;
  }
}
export class QuestionAnswerFileApiService extends CRUDApiService {
  constructor() {
    super('question/questionanswerfile');
  }
}
