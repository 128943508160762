import { useTranslation } from "_metronic/i18n/language";
import { FileUpload, RenderAudio, RenderImage, RenderVideo } from "components";
import React from "react";

interface MediaUploadComponentProps {
  type: string;
  showMedia: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, a: any) => void;
  a: any;
  media: any;
}

const MediaUploadComponent: React.FC<MediaUploadComponentProps> = ({
  type,
  showMedia,
  handleChange,
  a,
  media
}) => {
  const translate = useTranslation();
  let mediaComponent;
  let placeholderText: string = '';

  if (showMedia) {
    if (type === "image") {
      mediaComponent = <RenderImage uri={media?.tempUri} />;
    } else if (type === "audio") {
      mediaComponent = <RenderAudio uri={media?.tempUri} />;
    } else if (type === "video") {
      mediaComponent = <RenderVideo uri={media?.tempUri} />;
    }
  } else {
    if (type === "image") {
      placeholderText = translate("screens.fragrance.placeholders.selectImage") as string;
    } else if (type === "audio") {
      placeholderText = translate("labels_selectAudio") as string;
    } else if (type === "video") {
      placeholderText = translate("labels_selectVideo") as string;
    }

    mediaComponent = (
      <FileUpload
        handleChange={e => handleChange(e, a)}
        label={a.desc}
        name={type}
        placeholder={placeholderText}
        file={media?.file}
        accept={`${type}/*`}
        currentUploadedFile={media}
      />
    );
  }

  return <>{mediaComponent}</>;
};

export default MediaUploadComponent;
