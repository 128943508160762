import { useState } from "react";

type Props = {
  data: {
    answerId: number;
    answer: {
      label: string;
    };
    axle: string;
  }[];
};

type AnswersType = {
  [key: number]: number;
};

type PositionType = {
  rowId: number;
  colId: number;
};

export const InputMatrixRender = ({ data }: Props) => {
  const [answers, setAnswers] = useState<AnswersType>({});
  const rowAnswers = data.filter(item => item.axle === "Y");
  const columnAnswers = data.filter(item => item.axle === "X");

  const handleSelect = ({ rowId, colId }: PositionType): void => {
    setAnswers(prev => ({ ...prev, [rowId]: colId }));
  };

  return (
    <div style={{ overflowY: "auto", width: "100%" }}>
      <table style={{ margin: "10px" }}>
        <thead>
          <tr>
            <th></th>
            {columnAnswers.map(col => (
              <th style={{ paddingInline: "10px" }} key={col.answerId}>
                {col.answer.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowAnswers.map(row => (
            <tr key={row.answerId} style={{ padding: "10px", margin: "10px" }}>
              <td>{row.answer.label}</td>
              {columnAnswers.map(col => (
                <td key={col.answerId}>
                  <input
                    type="checkbox"
                    name={row.answerId.toString()}
                    checked={answers[row.answerId] === col.answerId}
                    style={{ margin: "10px" }}
                    onChange={() =>
                      handleSelect({ rowId: row.answerId, colId: col.answerId })
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
