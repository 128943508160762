import { useState } from "react";

type Props = {
  data: {
    answerId: number;
    answer: {
      label: string;
    };
    axle: string;
  }[];
};

export const InputColor = ({ data }: Props) => {
  return (
    <div>
      {data.map(v => {
        return <ColorBox key={v.answer.label} color={v.answer.label} />;
      })}
    </div>
  );
};

type ColorBoxProps = {
  color: string;
};

export const ColorBox = ({ color }: ColorBoxProps) => {
  const [checked, setChecked] = useState(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  return (
    <div
      onClick={handleChecked}
      style={{
        backgroundColor: color,
        display: "inline-block",
        margin: "5px",
        width: "66px",
        height: "66px",
        borderRadius: 5,
        border: "2px solid white",
        boxShadow: "black 0px 4px 12px -7px",
        position: "relative"
      }}
    >
      {checked && (
        <input
          type="checkbox"
          checked={checked}
          style={{ position: "absolute" }}
        />
      )}
    </div>
  );
};
