import React from "react";

const RenderImage: React.FC<{ uri: string | undefined }> = ({ uri }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img
        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        srcSet={`${uri}?w=248&fit=crop&auto=format&dpr=2 2x`}
        src={uri}
        alt=""
        loading="lazy"
      />
    </div>
  );
};

export default RenderImage;
