import React from "react";
import { connect } from "react-redux";
import { Form, Col, Card, Row } from "react-bootstrap";
import { Checkbox, Stack, Typography } from "@mui/material";
import Swal from "sweetalert2";

import FormActions from "../../components/FormActions";
import IsValidName from "../../../../utils/validators/IsValidName";
import { QuizApiService } from "../../../../services/Ananse/QuizApiService";
import Loading from "../../../home/components/Loading";
import SelectSearchComponent from "../../../auth/Components/Select2";
import SelectLanguage from "../../components/SelectLanguage";
import { FormControl } from "./components";
import styles from "./Anamnese.module.scss";
import SelectFragrance from "./components/SelectFragrance";
import { TranslationContext } from "_metronic/i18n/language";
import SelectItemComponent from "./components/SelectItem";
import { selectSegmentData } from "./AnamneseUpdate/selectData";

const initialState = {
  name: "",
  campaignId: null,
  initialInformation: "",
  language: "",
  campaign: {},
  maxShots: 0,
  userId: "0",
  type: "2",
  fragranceId: 0,
  fragranceName: "",
  segment: ""
};

class AnamneseForm extends React.Component {

  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      translate: null,
      language: this.convertLanguage()
    };

    this.api = new QuizApiService();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.submit = this.submit.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
    this.handleChangeSelectFragrances = this.handleChangeSelectFragrances.bind(
      this
    );
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  submit = async e => {
    await this.initializeTranslation();
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const reqBase = { method: "POST" };
      const {
        name,
        campaignId,
        language,
        initialInformation,
        maxShots,
        userId,
        type,
        fragranceId,
        requiredCampaing,
        segment
      } = this.state;
      const body = {
        name,
        campaignId,
        language,
        maxShots,
        type,
        fragranceId,
        userId,
        initialInformation,
        segment
      };

      if (!requiredCampaing) {
        body.fragranceId = undefined;
      }

      const result = await this.api.makeHttpRequest({
        ...reqBase,
        data: body
      });
      this.afterSubmit(result);
    } catch (e) {
      Swal.fire(
        this.state.translate('defaultMessages.error'),
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate('screens.quiz.errors.create'),
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async result => {
    this.setState({
      ...initialState,
      resetInput: true
    });
    this.props.history.push("/admin/form/" + result.quizId + "/update");
  };

  handleChangeLanguage = e => {
    this.setState({ language: e.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.segment]: e.target.segment
    });
  };

  handleChangeSelect = e => {
    this.setState({
      campaignId: e.id,
      campaignName: e.label,
      resetInput: false,
      campaign: e.obj
    });
  };

  handleChangeSelectFragrances = e => {
    if (!!e?.id) {
      this.setState({
        fragranceId: e.id,
        fragranceName: e?.label,
        resetInput: false
      });
    }
  };

  handleChangeSelectMulti = e => {
    this.setState({
      userId: e
    });
  };

  formIsValid = () => {
    const { name, campaignId } = this.state;
    const isValid = IsValidName(name) && campaignId !== null;
    return isValid;
  };

  onCleanForm = async () => {
    this.setState({
      ...initialState,
      resetInput: true,
      resetInputFilial: true
    });
  };

  checkedRequiredCampaing = e => {
    const { checked } = e.target;
    this.setState({ requiredCampaing: checked });
  };

  convertLanguage() {
    let { lang } = this.props;

    switch (lang) {
      case "pt":
        return "pt-BR";
      case "en":
        return "en-US";
      default:
        return "en-US";
    }
  }

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate('screens.quiz.pageTitle.create')} - Admin`
    );

    const {
      name,
      resetInput,
      requiredCampaing,
      campaign,
      fragranceId,
      segment
    } = this.state;

    return (
      <Card className="mt-3">
        <Loading isLoading={this.state.isLoading} />
        <Card.Header>
          {`${translate('labels.register')} ${translate('screens.quiz.form')}`}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.submit}>
            {/* row 1 */}
            <Row className={styles.row}>
              <FormControl
                value={name}
                name={"name"}
                label={translate('screens.quiz.labels.name')}
                placeholder={translate('screens.quiz.placeholders.name')}
                onChange={this.handleChange}
              />

              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate('screens.quiz.labels.campaign')} *
                </Form.Label>
                <SelectSearchComponent
                  resetInput={resetInput}
                  required={true}
                  handleChange={e => this.handleChangeSelect(e)}
                  className="kt-width-full"
                  label={
                    translate('screens.quiz.placeholders.campaign')
                  }
                  url={`/campaigns/without-quiz?str=`}
                  convertObject={obj => ({
                    id: obj.campaignId,
                    value: obj.campaignId,
                    label: `${obj.name}`,
                    obj
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate('screens.quiz.labels.language')}
                </Form.Label>
                <SelectLanguage
                  handleChangeLanguage={this.handleChangeLanguage}
                  languageValue={this.state.language}
                />
              </Form.Group>
            </Row>
            <Row className={styles.row}>
              <Form.Group as={Col} lg="2" xs="12" className="mb-0 pt-3">
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Checkbox
                    color="success"
                    value={requiredCampaing}
                    checked={requiredCampaing}
                    onChange={this.checkedRequiredCampaing}
                  />
                  <Typography>
                    {translate('screens.quiz.labels.indicateFragrabce')}
                  </Typography>
                </Stack>
              </Form.Group>
              {/* Segmento */}
              <SelectItemComponent
                  lg="4"
                  label={translate("screens_quiz_segment_label")}
                  name="segment"
                  dataOptions={selectSegmentData}
                  value={segment}
                  // required={requiredQuestionType ? { status: "invalid" } : null}
                  validations={"required"}
                  validator={this.props.validator}
                  translate={this.props.translate}
                  onChange={this.handleChange}
                  as="select"
                  // errorMessage={{
                  //   required: requiredQuestionType,
                  //   label: translate("screens.quiz.errors.questionTypeRequired")
                  // }}
                />

              {requiredCampaing && (
                <Form.Group as={Col} lg="6" xs="12">
                  <Form.Label>
                    {translate('screens.quiz.labels.fragrances')} *
                  </Form.Label>
                  <SelectFragrance
                    collection={campaign.collection}
                    fragranceId={fragranceId}
                    onFragranceSelected={fragrance => {
                      const e = {
                        id: fragrance?.fragranceId,
                        value: fragrance?.fragranceId,
                        label: fragrance?.name
                      };
                      this.handleChangeSelectFragrances(e);
                    }}
                  />
                </Form.Group>
              )}

              {requiredCampaing && (
                <Form.Group as={Col} lg="4" xs="12">
                  <Form.Label>
                    {translate('screens_quiz_maxShotLabel')}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="maxShots"
                    value={this.state.maxShots}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              )}
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {translate('screens.quiz.labels.initialInformation')}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="initialInformation"
                  placeholder={
                    translate('screens.quiz.labels.initialInformation')
                  }
                  value={this.state.initialInformation}
                  onChange={this.handleChange}
                  rows={5}
                  cols={100}
                />
              </Form.Group>
            </Row>
            <FormActions
              module="form"
              isEdit={false}
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
              submitLabel={translate('buttons.save')}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(AnamneseForm);
