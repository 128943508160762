import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { injectIntl, WrappedComponentProps } from "react-intl";
import RenderImage from "./Image";
import RenderAudio from "./Audio"; // Importar o componente RenderAudio
import RenderVideo from "./Video"; // Importar o componente RenderVideo
import { useTranslation } from "_metronic/i18n/language";

interface FileUploadProps extends WrappedComponentProps {
  name: string;
  label: string;
  placeholder: string;
  accept?: string;
  validator?: any; // Replace with your validator's type
  validations?: any; // Replace with your validations' type
  handleChange: (event: any) => void;
  file?: any; // Define a more specific type if possible
  currentUploadedFile?: any; // Define a more specific type if possible
}

const MAX_FILE_SIZE_MB = 20;

const FileUpload: React.FC<FileUploadProps> = ({
  intl,
  name,
  label,
  placeholder,
  accept,
  validator,
  validations,
  handleChange,
  file,
  currentUploadedFile
}) => {
  const translate = useTranslation();
  const [inputId] = useState(`${Math.random().toString()}${name}`);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileType, setFileType] = useState<string | null>(null);

  const openFileBrowser = () => {
    const element = document.getElementById(inputId) as HTMLInputElement;
    element.click();
  };

  const toMaxFileSizeMB = {
    maxFileSizeMB: MAX_FILE_SIZE_MB
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.item(0);
    if (!file) {
      return;
    }

    if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
      return Swal.fire({
        title: translate("labels_exceeded_fileSize"),
        text: translate("labels_maximum_fileSize", toMaxFileSizeMB),
        icon: "error",
        confirmButtonText: translate("buttons_ok")
      });
    }

    setSelectedFileName(file.name);
    setFileType(file.type);

    handleChange({
      target: {
        value: file,
        imageUrl: file.type.includes("image")
          ? URL.createObjectURL(file)
          : null,
        name
      }
    });

    e.target.value = "";
  };

  const shouldShowSelectedFileName = file && selectedFileName;

  return (
    <Form.Group as={Col} lg="6" xs="12">
      <Form.Label>{label}</Form.Label>
      <div>
        <Button
          className="text-white"
          variant="contained"
          color="success"
          onClick={openFileBrowser}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div>
              <i className="flaticon-upload" />
              <span style={{ whiteSpace: "nowrap" }}>{placeholder}</span>
            </div>
            <span style={{ fontSize: "8px" }}>
              {translate("labels_max_maxFileSizeMB", toMaxFileSizeMB)}
            </span>
          </div>
        </Button>
        {shouldShowSelectedFileName ? (
          <>
            {fileType && fileType.includes("image") && (
              <RenderImage
                uri={
                  file ? URL.createObjectURL(file) : currentUploadedFile.tempUri
                }
              />
            )}
            {fileType && fileType.includes("audio") && (
              <RenderAudio
                uri={
                  file ? URL.createObjectURL(file) : currentUploadedFile.tempUri
                }
              />
            )}
            {fileType && fileType.includes("video") && (
              <RenderVideo
                uri={
                  file ? URL.createObjectURL(file) : currentUploadedFile.tempUri
                }
              />
            )}
            {!fileType ||
              (!fileType.includes("image") &&
                !fileType.includes("audio") &&
                !fileType.includes("video") && (
                  <span className="mt ml-3 text-dark selected-file-name">
                    {selectedFileName}
                  </span>
                ))}
          </>
        ) : (
          currentUploadedFile && (
            <a
              href={currentUploadedFile?.tempUri}
              target="__blank"
              className="selected-file-name"
              download
            >
              {accept && accept?.includes("image") ? (
                <RenderImage uri={currentUploadedFile?.tempUri} />
              ) : accept && accept?.includes("audio") ? (
                <RenderAudio uri={currentUploadedFile.tempUri} />
              ) : accept && accept?.includes("video") ? (
                <RenderVideo uri={currentUploadedFile.tempUri} />
              ) : (
                <span className="mt ml-3 text-info">
                  {currentUploadedFile?.name}
                </span>
              )}
            </a>
          )
        )}
      </div>

      <Form.Control
        type="file"
        className="d-none"
        accept={accept}
        id={inputId}
        name={name}
        onChange={handleFileChange}
      />

      <div style={{ zIndex: 1000 }}>
        {validator?.message(
          name,
          shouldShowSelectedFileName ||
            (currentUploadedFile && currentUploadedFile.name),
          validations
        )}
      </div>
    </Form.Group>
  );
};

export default injectIntl(FileUpload);
