import React from "react";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { injectIntl } from "react-intl";
import moment from "moment";
import { FaQrcode, FaPrint } from "react-icons/fa";
import { Image, Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import { CampaignApiService } from "../../../../services/Ananse/CampaignApiService";
import QRCode from "qrcode.react";
import PrintProvider, { Print } from "react-easy-print";

class CampaignsListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new CampaignApiService();
    this.state = {
      campaigns: [],
      reload: false,
      isActive: "true",
      campaignToGenerateQrCode: null
    };

    this.translate = this.props.translate;
    this.setCampaigns = this.setCampaigns.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
    this.defineDefaultCampaign = this.defineDefaultCampaign.bind(this);
    this.toggleQrCodeModal = this.toggleQrCodeModal.bind(this);
    this.printQrCode = this.printQrCode.bind(this);
  }

  setCampaigns(campaigns) {
    this.setState({ campaigns });
  }

  toggleQrCodeModal(campaign) {
    this.setState({ campaignToGenerateQrCode: campaign || null });
  }

  printQrCode() {
    window.print();
  }

  async onConfirmChangeStatus(campaign) {
    if (campaign.deletedAt) {
      await this.api.activate(campaign.campaignId);
    } else {
      await this.api.delete(campaign.campaignId);
    }

    this.reloadListing();
  }

  reloadListing() {
    this.setState({ reload: !this.state.reload });
  }

  async defineDefaultCampaign(id) {
    const { value } = await Swal.fire({
      title: this.translate.screens.campaigns.list.defineTitle,
      text: this.translate.screens.campaigns.list.defineText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.buttons.yes,
      cancelButtonText: this.translate.buttons.no
    });

    if (!value) {
      return;
    }

    this.props.showSpinner();

    try {
      await this.api.setDefault(id);
      this.reloadListing();
    } catch (e) {
      Swal.fire(
        this.translate.defaultMessages.error,
        this.translate.defaultMessages.loadData,
        "error"
      );
    }

    this.props.hideSpinner();
  }

  generateCampaignCode() {
    return this.state.campaignToGenerateQrCode
      ? "campaign|" +
          process.env.REACT_APP_CUSTOMER_CODE +
          "|" +
          this.state.campaignToGenerateQrCode.campaignId +
          "|" +
          this.state.campaignToGenerateQrCode.updatedAt
      : "";
  }

  render() {
    window.setPageTitle(
      `${this.translate.screens.campaigns.list.title} - Admin`
    );

    const headRows = [
      { label: this.translate.screens.campaigns.list.actions },
      {
        column: "Downloads",
        label: this.translate.screens.campaigns.list.downloads
      },
      {
        column: "FragranceShots",
        label: this.translate.screens.campaigns.labels?.fragranceShots
      },
      { column: "Name", label: this.translate.screens.campaigns.list.name },
      {
        column: "CreatedAt",
        label: this.translate.screens.campaigns.list.created
      },
      { column: "UpdatedAt", label: this.translate.screens.quiz.list.updated }
    ];

    const formatRow = r => {
      const actions = ["toggle",'clone'];
      if (!r.deletedAt) {
        actions.push("edit");
      }

      return (
        <TableRow hover tabIndex={-1} key={r.campaignId}>
          <TableCell className="is-default-campaign">
            <CrudActions
              actions={actions}
              module="campaigns"
              onConfirmChangeStatus={this.onConfirmChangeStatus}
              id={r.campaignId}
              obj={r}
            />

            <IconButton
              size="small"
              title={this.translate.screens.campaigns.list.qrCode}
              onClick={() => this.toggleQrCodeModal(r)}
            >
              <FaQrcode />
            </IconButton>

            {!r.deletedAt &&
              (r.isDefault ? (
                <Image
                  src="/media/icons/star-yellow.png"
                  alt={this.translate.buttons.yes}
                />
              ) : (
                <Image
                  src="/media/icons/star-empty.png"
                  alt={this.translate.buttons.no}
                  onClick={() => this.defineDefaultCampaign(r.campaignId)}
                />
              ))}
          </TableCell>
          <TableCell scope="row">{r.downloads}</TableCell>
          <TableCell scope="row">{r.fragranceShots}</TableCell>
          <TableCell scope="row">{r.name}</TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell scope="row">
            {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <PrintProvider>
        <Modal
          centered
          show={!!this.state.campaignToGenerateQrCode}
          onHide={this.toggleQrCodeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.translate.screens.campaigns.list.qrCode}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Print single name="qrcode-print">
              <QRCode value={this.generateCampaignCode()} />
            </Print>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" size="sm" onClick={this.printQrCode}>
              <FaPrint className="mr-2" />
              {this.translate.screens.campaigns.list.print}
            </Button>
          </Modal.Footer>
        </Modal>
        <Datatable
          title={this.translate.screens.campaigns.list.title}
          endpoint="/campaigns"
          headRows={headRows}
          formatRow={formatRow}
          rows={this.state.campaigns}
          setRows={this.setCampaigns}
          reload={this.state.reload}
          placeholderSearch={
            this.translate.screens.campaigns.placeholders.search
          }
          isActive={this.state.isActive}
          buttons={[
            {
              label: this.translate.datatable.add,
              onClick: null,
              icone: "add",
              path: "/admin/campaigns/create"
            }
          ]}
        />
      </PrintProvider>
    );
  }
}

export default injectIntl(CampaignsListing);
