export function swap<T>(array: Array<T>, index1: number): Array<T> {
  const idValidIndex = index1 >= 1 && index1 < array.length;

  if (idValidIndex) {
    const index2 = index1 - 1;
    const newArray = [...array];
    const temp = newArray[index1];
    newArray[index1] = newArray[index2];
    newArray[index2] = temp;
    return newArray;
  }
  console.error("Invalid indices provided for swap.");
  return array;
}

type Question = {
  quizQuestionId: number;
  arrangeQuestionSequence: number;
};

export function up(questions: Question[], questionId: number): Question[] {
  // Encontra o índice da pergunta que será movida para cima
  const index = questions.findIndex(q => q.quizQuestionId === questionId);

  if (index <= 0) {
    // Se a pergunta já está no topo ou não foi encontrada, não faz nada
    return questions;
  }

  // Troca a pergunta com a anterior
  const temp = questions[index];
  questions[index] = questions[index - 1];
  questions[index - 1] = temp;

  // Reordena o arrangeQuestionSequence para todos os elementos
  for (let i = 0; i < questions.length; i++) {
    questions[i].arrangeQuestionSequence = i;
  }

  return questions;
}

export function down(questions: Question[], questionId: number): Question[] {
  // Encontra o índice da pergunta que será movida para baixo
  const index = questions.findIndex(q => q.quizQuestionId === questionId);

  if (index === -1 || index >= questions.length - 1) {
    // Se a pergunta não for encontrada ou já está no final, não faz nada
    return questions;
  }

  // Troca a pergunta com a próxima
  const temp = questions[index];
  questions[index] = questions[index + 1];
  questions[index + 1] = temp;

  // Reordena o arrangeQuestionSequence para todos os elementos
  for (let i = 0; i < questions.length; i++) {
    questions[i].arrangeQuestionSequence = i;
  }

  return questions;
}
