import React from "react";
import { Container, ButtonDownloadAndroid, ButtonDownloadApple } from './style';
import { toAbsoluteUrl } from "../../../../_metronic";

export default function Register({closeModal, bg}) {
    // const locale = useSelector(({ i18n }) => i18n.lang);

    const handleDownload = (link) => {
      window.open(link);
    }

    return (
        <Container bgImg={toAbsoluteUrl('/media/bg/bg-10.jpg')}>
            <button
              style={{'position': 'relative', 'top': '-30%', 'right': '-45%'}}
              type="button" className="close" aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <ButtonDownloadApple
              onClick={() => handleDownload(process.env.REACT_APP_LINK_APPLE_STORE)}
              bgImage={toAbsoluteUrl('/media/bg/btnApple.svg')}
              className="btn"
            >
            </ButtonDownloadApple>
            <ButtonDownloadAndroid
              onClick={() => handleDownload(process.env.REACT_APP_LINK_PLAY_STORE)}
              bgImage={toAbsoluteUrl('/media/bg/btnAndroid.svg')}
              className="btn"
            >
            </ButtonDownloadAndroid>
        </Container>
    );
}
