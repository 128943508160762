import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ErrorPage from "../errors/ErrorPage";
import translate from "@noar-firmenich/commons";

import {
  // Dashboard,
  Home,
  Profiles,
  UsersListing,
  UserForm,
  CategoriesListing,
  CategoryForm,
  CampaignsListing,
  CampaignForm,
  FragrancesListing,
  FragranceForm,
  QuizListing,
  QuizForm,
  DevicesListing,
  CapsulesListing,
  AnamneseForm,
  AnamneseListing,
  AnamneseUpdate
} from "./pages";
import store from "../../store/store";
import { showSpinner, hideSpinner } from "../../store/actions/spinner.actions";
import {
  AccountListing,
  ResultListing,
  AccountEdit,
  CapsuleForm,
  UserFormNew,
  DashBoard,
  CapsuleUpdate,
  ListForms,
  CreateFormUserQuiz
} from "pages";

const spinnerActions = {
  showSpinner: () => store.dispatch(showSpinner()),
  hideSpinner: () => store.dispatch(hideSpinner())
};

function AdminPage({ history, translate, user, lang }) {
  const [width, setWidth] = useState(window.innerWidth);

  const subPagesProps = {
    ...spinnerActions,
    history,
    translate,
    user,
    lang,
    width
  };

  useEffect(() => {
    const resizeEvent = () => {
      if (window.innerWidth !== width) {
        setWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [width]);

  const { auth } = store.getState();
  const isAdmin = auth && auth.user && auth.user.profile.nome === "ADMIN";
  const isSuperUser =
    auth && auth.user && auth.user.profile.nome === "SUPER-USER";
  const isUser = auth && auth.user && auth.user.profile.nome === "USER";

  const userRoutes = [
    { path: "/admin/dashboard", component: DashBoard },
    { path: "/admin/users/:id/update", component: UserFormNew }
    // { path: "/admin/account", component: AccountListing, exact: true },
    // { path: "/admin/result/:id", component: ResultListing, exact: true },
    // { path: "/admin/account/edit/:id", component: AccountEdit, exact: true },
    // { path: "/admin/account/create", component: AccountEdit, exact: true }
  ];

  const adminRoutes = [
    { path: "/admin/home", component: Home },
    { path: "/admin/users", component: UsersListing, exact: true },
    { path: "/admin/users/create", component: UserForm },
    { path: "/admin/users/:id/update", component: UserForm },
    { path: "/admin/categories", component: CategoriesListing, exact: true },
    { path: "/admin/categories/create", component: CategoryForm },
    { path: "/admin/categories/:id/update", component: CategoryForm },
    { path: "/admin/campaigns", component: CampaignsListing, exact: true },
    { path: "/admin/campaigns/create", component: CampaignForm },
    { path: "/admin/campaigns/:id/update", component: CampaignForm },
    { path: "/admin/campaigns/:id/:clone", component: CampaignForm },
    { path: "/admin/fragrances", component: FragrancesListing, exact: true },
    { path: "/admin/fragrances/create", component: FragranceForm },
    { path: "/admin/fragrances/:id/update", component: FragranceForm },
    { path: "/admin/quiz", component: QuizListing, exact: true },
    { path: "/admin/quiz/create", component: QuizForm },
    { path: "/admin/devices", component: DevicesListing, exact: true },
    { path: "/admin/capsules", component: CapsulesListing, exact: true },
    { path: "/admin/capsules/create", component: CapsuleForm },
    {
      path: "/admin/capsules/update/:serialNumber-:fragranceId",
      component: CapsuleUpdate
    },
    { path: "/admin/form", component: AnamneseListing, exact: true },
    { path: "/admin/form/create", component: AnamneseForm },
    { path: "/admin/form/:id/update", component: AnamneseUpdate },
    { path: "/admin/form/:id/:clone", component: AnamneseUpdate },
    { path: "/admin/flow", component: ListForms, exact: true },
    { path: "/admin/flow/create", component: CreateFormUserQuiz },
    { path: "/admin/flow/update/:id", component: CreateFormUserQuiz },
    { path: "/admin/perfis", component: Profiles, condition: isAdmin },
    { path: "/admin/dashboard", component: DashBoard },
  ];

  const superUserRoutes = [
    { path: "/admin/home", component: Home },
    { path: "/admin/users", component: UsersListing, exact: true },
    { path: "/admin/users/create", component: UserForm },
    { path: "/admin/users/:id/update", component: UserForm },
    { path: "/admin/quiz", component: QuizListing, exact: true },
    { path: "/admin/quiz/create", component: QuizForm },
    { path: "/admin/devices", component: DevicesListing, exact: true },
    { path: "/admin/capsules", component: CapsulesListing, exact: true },
    { path: "/admin/form", component: AnamneseListing, exact: true },
    { path: "/admin/form/create", component: AnamneseForm },
    { path: "/admin/form/:id/update", component: AnamneseUpdate },
    { path: "/admin/form/:id/:clone", component: AnamneseUpdate },
    { path: "/admin/flow", component: ListForms, exact: true },
    { path: "/admin/flow/create", component: CreateFormUserQuiz },
    { path: "/admin/flow/update/:id", component: CreateFormUserQuiz },
    { path: "/admin/dashboard", component: DashBoard },
  ];

  const renderRoutes = routes =>
    routes.map(
      (
        { path, component: Component, exact = false, condition = true },
        index
      ) =>
        condition ? (
          <Route key={index} path={path} exact={exact}>
            <Component {...subPagesProps} />
          </Route>
        ) : null
    );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Redirect
          exact
          from="/admin"
          to={isUser ? "/admin/account" : "/admin/home"}
        />
        {isUser && renderRoutes(userRoutes)}
        {isAdmin && renderRoutes(adminRoutes)}
        {isSuperUser && renderRoutes(superUserRoutes)}
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Suspense>
  );
}

const mapStateToProps = store => ({
  translate: translate(store.i18n.lang),
  lang: store.i18n.lang,
  user: store.auth.user
});

export default connect(mapStateToProps)(AdminPage);
