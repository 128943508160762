import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import { AnswerType } from "types";

type LabelsType = {
  title: string;
  placeholder: string;
  errorsNoQuestionOption: string;
  errorsNextRequired?: string;
};

type LabelProps = {
  text: string;
  style?: { color: string };
};

type FormNextQuestionProps = {
  quizLabels: LabelsType;
  questions: Array<any>;
  isAnswerError: boolean;
  answer: AnswerType;
  setAnswer: (answer: AnswerType) => void;
  setRequiredNextQuestion: (requiredNextQuestion: boolean) => void;
};

const Label = ({ text, style }: LabelProps) => (
  <Form.Label style={style}>{text}</Form.Label>
);

const FormNextQuestion = ({
  quizLabels: {errorsNoQuestionOption,placeholder,title,errorsNextRequired},
  questions,
  isAnswerError,
  answer,
  setAnswer,
  setRequiredNextQuestion
}: FormNextQuestionProps) => {
  const questionOptions = questions.map(e => ({
    value: e.quizQuestionId,
    label: e.question?.quest
  }));

  const handleSelectNextQuestion = (obj: any) => {
    answer.nextQuizQuestionId = obj.value;
    answer.nextQuizQuestion = obj;
    setAnswer(answer);
    setRequiredNextQuestion(false);
  };

  return (
    <Form.Group as={Col} lg="7" xs="12" className="mb-1">
      <Label text={title} />
      <Select
        value={answer.nextQuizQuestion as { value: any; label: any }}
        placeholder={placeholder}
        noOptionsMessage={obj =>
          obj?.inputValue ? obj?.inputValue : errorsNoQuestionOption
        }
        name="questionsToLink"
        options={questionOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleSelectNextQuestion}
      />
      {isAnswerError && (
        <Label
          text={errorsNextRequired || " "}
          style={{ color: "#FF0000" }}
        />
      )}
    </Form.Group>
  );
};
export default FormNextQuestion;
