import React from "react";
import translate from "@noar-firmenich/commons";
import { useSelector } from "react-redux";

import "./Styles.scss";
import Br from "./br";
import En from "./en";
import Es from "./es";
import Fr from './fr'
import De from './de'

export default function PrivacyPolicyPage() {
  const locale = useSelector(({ i18n }) => i18n.lang);

  console.log("Policy Page lang:", locale);
  const Privacy = () => {
    if (locale === "pt") {
      return <Br />;
    }
    if (locale === "en") {
      return <En/>;
    }
    if (locale === "fr") {
      return <Fr/>;
    }
    if (locale === "de") {
      return <Fr/>;
    }
    return <Es />;
  };

  window.setPageTitle(translate(locale).defaultMessages?.privacyPolicy);
  return <Privacy />;
}
