interface TranslationObject {
  export_Title: string;
  label: string;
  start_Date: string;
  end_Date: string;
  save: string;
  exporting: string;
  export_Answers: string;
  page_title: string;
  INSERT_CODE_ANSWER: string;
  Select_Next_Question: string;
  add_Answer: string;
  update_Answer: string;
}

const mockTranslate:any = {
  pt: {
    export_Title: "Exportar Respostas",
    label: "Selecione o Fluxo desejado",
    start_Date: "Data inicial",
    end_Date: "Data final",
    save: "Salvar",
    exporting: "Exportando...",
    export_Answers: "Exportar respostas",
    page_title: "Dashboard",
    INSERT_CODE_ANSWER: "Insira o código da resposta",
    Select_Next_Question: "Escolha a pergunta a seguir",
    add_Answer: "Adicionar Resposta",
    update_Answer: "Atualizar Resposta"
  },
  en: {
    export_Title: "Export Responses",
    label: "Select the desired Flow",
    start_Date: "Start Date",
    end_Date: "End Date",
    save: "Save",
    exporting: "Exporting...",
    export_Answers: "Export answers",
    page_title: "Dashboard",
    INSERT_CODE_ANSWER: "Enter the answer code",
    add_Answer: "Add Answer",
    update_Answer: "Update Answer"
  },
  fr: {
    export_Title: "Exporter les réponses",
    label: "Sélectionnez le flux désiré",
    start_Date: "Date de début",
    end_Date: "Date de fin",
    save: "Enregistrer",
    exporting: "Export en cours...",
    export_Answers: "Exporter les réponses",
    page_title: "Dashboard",
    INSERT_CODE_ANSWER: "Entrez le code de réponse",
    add_Answer: "Ajouter Réponse",
    update_Answer: "Mettre à jour Réponse"
  }
};

type TranslationKeys = keyof typeof mockTranslate["pt"];

export function getTranslation(
  language: keyof typeof mockTranslate,
  key: TranslationKeys
): string {
  if (!(language in mockTranslate)) {
    return "Language not available";
  }

  if (!(key in mockTranslate[language])) {
    return "Translation not available for this key";
  }

  return mockTranslate[language][key];
}
