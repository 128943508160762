import { Edit, Info } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Datatable from "app/partials/datatable/Datatable";
import store from "app/store/store";
import { titlePage } from "utils/titlepage";

interface Capsule {
  capsuleId: number;
  deviceId: number;
  // ... outras propriedades
}

interface CapsulesListProps {
  translate: any;
}

const CapsulesList: React.FC<CapsulesListProps> = ({ translate }) => {
  const [capsules, setCapsules] = useState<Capsule[]>([]); // Adjust the type accordingly
  const [reload, setReload] = useState<boolean>(false);

  const { auth } = store.getState();
  const isAdmin = auth.user.profile.nome === "ADMIN";

  useEffect(() => {
    titlePage(`${translate.screens.capsule?.title || "Capsule"} - Admin`);
  }, [translate.screens.capsule?.title]);

  const headRowsInitial = [
    {
      key: 2,
      column: "SerialNumber",
      label: translate.screens.capsule?.labels?.serial
    },
    {
      key: 3,
      column: "Fragrance.Name",
      label: translate.screens.fragrance?.list?.title
    },
    {
      key: 4,
      column: "RemainingShots",
      label: translate.screens.capsule?.labels?.remaining
    },
    {
      key: 5,
      column: "Device.MacAddress",
      label: translate.screens.device?.labels?.macAddress
    },
    {
      key: 6,
      column: "DueDate",
      label: translate.screens.capsule?.labels?.dueDate
    },
    { key: 7, column: "CreatedAt", label: translate.screens.quiz.list.created },
    { key: 8, column: "UpdatedAt", label: translate.screens.quiz.list.updated }
  ];
  const [headRows, setHeadRows] = useState(headRowsInitial);

  useEffect(() => {
    const isUser = isAdmin;

    const moreHeadRows = [
      {
        key: 1,
        column: "",
        label: translate.screens.quiz.list.actions
      }
    ];
    if (isUser) {
      setHeadRows(v => [...v, ...moreHeadRows]);
    }
  }, [auth]);

  const formatRow = (r: any) => {
    const crudActions = [];
    if (r.deletedAt === null) {
      crudActions.push("edit");
    }

    return (
      <TableRow hover tabIndex={-1} key={r.deviceId}>
        {isAdmin && (
          <TableCell scope="row">
            <>
              <Link
                to={{
                  pathname: `/admin/capsules/update/${r.serialNumber}-${r.fragranceId}`,
                  state: { data: r }
                }}
              >
                <IconButton
                  aria-label="upload picture"
                  component="label"
                  size="small"
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </Link>
              <Link
                to={{
                  pathname: `/admin/capsules/detailing/${r.serialNumber}-${r.fragranceId}-${r.fragrance?.name}`,
                  state: { data: r }
                }}
              >
                <IconButton
                  aria-label="upload picture"
                  component="label"
                  size="small"
                  title="Detalhamento"
                >
                  <Info fontSize="inherit" />
                </IconButton>
              </Link>
            </>
          </TableCell>
        )}
        <TableCell scope="row">{r.serialNumber}</TableCell>
        <TableCell scope="row">{r.fragrance?.name}</TableCell>
        <TableCell scope="row">{r.remainingShots}</TableCell>
        <TableCell scope="row">{r.device?.macAddress}</TableCell>
        <TableCell scope="row">
          {moment(r.dueDate).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <Datatable
        title={translate.screens.capsule?.title}
        endpoint={`/capsules?FilterInt=${auth.user.id}`}
        headRows={headRows.sort((a, b) => a.key - b.key)}
        formatRow={formatRow}
        rows={capsules}
        setRows={setCapsules}
        reload={reload}
        placeholderSearch={translate.screens.capsule?.placeholders?.filter}
        buttons={
          isAdmin
            ? [
                {
                  label: translate.datatable.add,
                  onClick: null,
                  icone: "add",
                  path: "/admin/capsules/create"
                }
              ]
            : []
        }
      />
    </>
  );
};

const mapStoreToProps = (store: any) => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(CapsulesList);
