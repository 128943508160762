import * as React from "react";
import { Typography } from "@mui/material";
import translate from "@noar-firmenich/commons";
import { useSelector } from "react-redux";

import packageJson from "../../package.json";
import { UseSelectorI } from "interfaces";

const VersionApp = () => {
  const version = packageJson.version;
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);

  return <Typography textAlign={"center"}>{translate(locale).screenApp.version}: {version}</Typography>;
};
export default VersionApp;
