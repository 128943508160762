import { useTranslation } from "_metronic/i18n/language";
import React from "react";
import { Col, Form, FormControlProps } from "react-bootstrap";

type Props = {
  label: string;
  value?: string | number | string[] | undefined;
  dataOptions: { value: string; name: string }[];
  lg?: string;
  xs?: string;
  errorMessage?: { required: boolean; label: string };
} & FormControlProps;

const SelectItemComponent: React.FC<Props> = ({
  label,
  dataOptions,
  value,
  errorMessage,
  lg = "3",
  xs = "12",
  ...rest
}) => {
  const translate = useTranslation();
  return (
    <Form.Group as={Col} lg={lg} xs={xs}>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...rest} value={value}>
        <option value="">{translate("screenApp.quiz.selectOption")}</option>
        {dataOptions.map(option => (
          <option
            key={option.value}
            value={option.value}
            selected={option.value === value}
          >
            {option.name}
          </option>
        ))}
      </Form.Control>
      {errorMessage?.required && (
        <Form.Label style={{ color: "#FF0000" }}>
          {errorMessage.label}
        </Form.Label>
      )}
    </Form.Group>
  );
};

export default SelectItemComponent;
