/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import AdminPage from "../pages/admin/AdminPage";
import HomePage from "../pages/home/HomePage";
import LogoutPage from "../pages/auth/Logout";
import PrivacyPolicyPage from "../pages/policy/PrivacyPolicyPage";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/auth" component={AuthPage} />
        <Route path="/auth/login" component={AuthPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />

        {isAuthorized ? (
          <>
            <Route path="/admin">
              <Layout>
                <AdminPage history={history} userLastLocation={userLastLocation} />
              </Layout>
            </Route>

            <Route path="/" component={HomePage} />
          </>
        ) : (
          <Redirect from="/admin" to="/auth/login" />
        )}

        <Route path="/" component={HomePage} />
      </Switch>
    </LayoutContextProvider>
  );
});
