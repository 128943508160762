import React from "react";
import ReactPlayer from "react-player";

const RenderVideo: React.FC<{ uri: string | undefined }> = ({ uri }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ReactPlayer
        url={uri}
        controls={true}
        width="100%"
        height="100%" // Ou defina uma altura específica para o player de vídeo
      />
    </div>
  );
};

export default RenderVideo;
