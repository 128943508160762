import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography
} from "@mui/material";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { QuestionApiService } from "app/services/Ananse/QuestionApiService";
import { useTranslation } from "_metronic/i18n/language";

type Answer = {
  quizQuestionAnswerId: number;
  answerId: number;
  answer: {
    answerId: number;
    label: string;
    description: string | null;
    contentLanguage: string;
    files: null | string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  };
  quizQuestionId: number;
  nextQuestionId: number | null;
  axle: string;
  nextQuestion: null | string;
  fragranceOptions: [];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type Question = {
  quizQuestionId: number;
  questionId: number;
  quizId: number;
  question: {
    questionId: number;
    quest: string;
    contentLanguage: string;
    fragranceId: null | number;
    rightAnswer: null | string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  };
  fragranceId: null | number;
  rightAnswer: null | string;
  type: string;
  mandatory: boolean | null;
  code: null | string;
  arrangeQuestionSequence: number;
  answers: Answer[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

type Props = {
  questions: Question[];
  onSave: (updatedQuestions: Question[]) => void;
};

export const updateSequence = async (question: Question) => {
  try {
    if (!question.quizQuestionId) {
      console.log("error updateSequence");
      return;
    }
    await questionApi.makeHttpRequest({
      method: "PUT",
      url: `/update-sequence/${question.quizQuestionId}/${question.arrangeQuestionSequence}`
    });
  } catch (error) {
    console.log("updateSequence: ", error);
  }
};

const questionApi = new QuestionApiService();
const SequenceQuestions: React.FC<Props> = ({ questions, onSave }) => {
  const translate = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [updatedQuestions, setUpdatedQuestions] = useState<Question[]>([
    ...questions
  ]);

  useEffect(
    () =>
      setUpdatedQuestions(
        questions
          .sort((a, b) => a.arrangeQuestionSequence - b.arrangeQuestionSequence)
          .map((q, index) => ({
            ...q,
            arrangeQuestionSequence: index + 1
          }))
      ),
    [questions]
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectChange = (index: number, newSequence: number) => {
    const updated = updatedQuestions.map((question, idx) => {
      if (idx === index) {
        return {
          ...question,
          arrangeQuestionSequence: newSequence
        };
      }

      if (question.arrangeQuestionSequence === newSequence) {
        return {
          ...question,
          arrangeQuestionSequence:
            updatedQuestions[index].arrangeQuestionSequence
        };
      }
      return question;
    });

    updated.sort(
      (a, b) => a.arrangeQuestionSequence - b.arrangeQuestionSequence
    );

    setUpdatedQuestions(updated);
  };

  const handleSave = async () => {
    setLoading(true);
    const totalQuestions = updatedQuestions.length;

    for (let i = 0; i < totalQuestions; i++) {
      const question = updatedQuestions[i];
      await updateSequence(question);

      setProgress(((i + 1) / totalQuestions) * 100);
    }

    onSave(updatedQuestions);
    handleClose();
    setProgress(0);
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Modal open={loading}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                marginBottom: 2
              }}
            >
              <CircularProgress
                variant={progress > 0 ? "determinate" : "indeterminate"}
                color="success"
                value={progress}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h5">
              {" "}
              {translate("screenApp.campaign.updateCampaign")}
            </Typography>
          </Box>
        </Modal>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            p: 4,
            borderRadius: 2,
            maxHeight: "90vh",
            overflow: "auto"
          }}
        >
          <Typography
            variant="h5"
            mb={2}
            sx={{ fontWeight: "bold", color: "#2c3e50" }}
          >
            {translate("manage.questions")}
          </Typography>
          <Grid container spacing={2}>
            {updatedQuestions.map((question, index) => (
              <Grid item xs={12} key={question.quizQuestionId}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 3,
                    backgroundColor: "#f8f9fa",
                    borderRadius: 2
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#34495e" }}>
                    {question.question.quest}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#7f8c8d", marginBottom: 1 }}
                  >
                    {translate("screens.fragrance.labels.type")}:{" "}
                    {question.type}
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 6
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{ fontSize: 14, color: "#2c3e50" }}
                    >
                      {translate("current.order")}{" "}
                      {question.arrangeQuestionSequence}
                    </Typography>
                    <Select
                      value={question.arrangeQuestionSequence}
                      onChange={event =>
                        handleSelectChange(index, event.target.value as number)
                      }
                      fullWidth
                      sx={{
                        width: 150,
                        "& .MuiSelect-select": {
                          padding: "8px"
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 2
                        }
                      }}
                    >
                      {Array.from(
                        { length: questions.length + 1 },
                        (_, i) =>
                          i > 0 && (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Box mt={4} display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" color="success" onClick={handleClose}>
              {translate("buttons.close")}
            </Button>
            <Button variant="contained" color="success" onClick={handleSave}>
              {translate("buttons.save")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Button variant="contained" color="success" onClick={handleOpen}>
        {translate("order.questions")}
      </Button>
    </>
  );
};

export default SequenceQuestions;
