import {format} from 'date-fns';
import {ptBR, enUS, es, de} from 'date-fns/locale';

const language = (value: any) => {
  if (value === 'en') {
    return enUS;
  }
  if (value === 'de') {
    return de;
  }
  if (value === 'es') {
    return es;
  }
  return ptBR;
};

export function formatedDate(
  date: Date | string = new Date(),
  type?: boolean,
  hours?: boolean,
  locale?: any,
) {
  // const date = new Date('2023-01-01');
  const newvalue = new Date(date);
  if (type && hours) {
    return format(newvalue, 'dd/MM/yyyy - HH:mm');
  }
  if (type) {
    return format(newvalue, 'dd/MM/yyyy');
  }
  const formattedDate = format(newvalue, 'dd MMMM yyyy', {
    locale: language(locale),
  });
  return formattedDate;
}

export function formatedHours(date: Date = new Date()) {
  const newvalue = new Date(date);
  return newvalue.toTimeString().toString().slice(0, 8);
}

function leftZero(value: number) {
  return value.toString().padStart(2, '0');
}

export const maskDate = (value: any) => {
  let birthDate = value;

  if (value?.length === 8) {
  }
  birthDate = birthDate.replace(/\D/g, '');
  birthDate = birthDate.replace(/(\d{2})/, '$1/');
  birthDate = birthDate.replace(/(\d{2})(\d{4})$/, '$1/$2');

  return birthDate;
};

export const formatDateTime = () => {
  const newDate = new Date();
  return (
    leftZero(newDate.getDate()) +
    '/' +
    leftZero(newDate.getMonth() + 1) +
    '/' +
    newDate.getFullYear() +
    ' ' +
    newDate.toTimeString().toString().slice(0, 5)
  );
};
export const formatDate = (date: any) => {
  const newDate = new Date(date);
  return newDate.toUTCString().toString().slice(5, 16);
};
