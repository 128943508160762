import React from "react";
import { connect } from "react-redux";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import { QuizApiService } from "../../../../services/Ananse/QuizApiService";

class QuizListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new QuizApiService();
    this.state = {
      quiz: [],
      reload: false,
      isActive: "true"
    };

    this.setQuiz = this.setQuiz.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
  }

  setQuiz(quiz) {
    this.setState({ quiz });
  }

  async onConfirmChangeStatus(obj) {
    if (!obj.deletedAt) {
      await this.api.delete(obj.quizId);
    } else {
      await this.api.activate(obj.quizId);
    }

    this.setState({ reload: !this.state.reload });
  }

  render() {
    window.setPageTitle(
      `${this.props.translate.screens.quiz.title || "Testes"} - Admin`
    );

    const headRows = [
      { label: this.props.translate.screens.quiz.list.actions },
      { column: "Name", label: this.props.translate.screens.quiz.list.name },
      {
        column: "Campaign.Name",
        label: this.props.translate.screens.quiz.list.campaign
      },
      {
        column: "Language",
        label: this.props.translate.screens.quiz.labels.language
      },
      {
        column: "Questions",
        label: this.props.translate.screens.quiz.list.questions
      },
      {
        column: "Type",
        label: "Type"
      },
      // {
      //   column: "AnamneseId",
      //   label: "Anamnese"
      // },
      {
        column: "CreatedAt",
        label: this.props.translate.screens.quiz.list.created
      },
      {
        column: "UpdatedAt",
        label: this.props.translate.screens.quiz.list.updated
      }
    ];

    const formatRow = r => (
      <TableRow hover tabIndex={-1} key={r.quizId}>
        <TableCell>
          <CrudActions
            actions={["toggle", "edit"]}
            module="quiz"
            onConfirmChangeStatus={this.onConfirmChangeStatus}
            id={r.quizId}
            obj={r}
          />
        </TableCell>
        <TableCell scope="row">{r.name}</TableCell>
        <TableCell scope="row">{r.campaign?.name}</TableCell>
        <TableCell scope="row">{r.language}</TableCell>
        <TableCell scope="row">{r.questions?.length}</TableCell>
        {/* <TableCell scope="row">
          {r.type === "1" ? "Teste de Cheiro" : "Teste de Anamnese"}
        </TableCell> */}
        <TableCell scope="row">{r.anamneseId === 0 ? "None" : "Has"}</TableCell>
        <TableCell scope="row">
          {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
      </TableRow>
    );

    const filterTeste = this.state.quiz//.filter(value => value.type === "1");

    return (
      <Datatable
        title={this.props.translate.screens.quiz.list.title}
        endpoint="/quiz?Type=1"
        headRows={headRows}
        formatRow={formatRow}
        rows={filterTeste}
        setRows={this.setQuiz}
        reload={this.state.reload}
        placeholderSearch={
          this.props.translate.screens.quiz.placeholders.search
        }
        isActive={this.state.isActive}
        buttons={[
          {
            label: this.props.translate.datatable.add,
            onClick: null,
            icone: "add",
            path: "/admin/quiz/create"
          }
        ]}
      />
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(QuizListing);
