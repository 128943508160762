import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Card, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Divider } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";

import { CapsuleI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import FormActions from "components/FormActions";
import Loading from "app/pages/home/components/Loading";
import { useTranslation } from "_metronic/i18n/language";

const CapsuleUpdate = () => {
  const params: any = useParams();
  const history = useHistory();
  const api = new AnanseApiService();
  const translate = useTranslation();
  const schema = Yup.object().shape({
    serialNumber: Yup.string()
      .required(translate("screens.login.validations.required"))
      .nullable(),
    dueDate: Yup.date().required(
      translate("screens.login.validations.required")
    )
  });

  const [loading, setLoading] = React.useState(false);
  const [allowSave, setAllowSave] = React.useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerCode: "",
      capsuleId: 0,
      deviceId: 0,
      fragranceId: 0,
      dueDate: "",
      updatedAt: "",
      deletedAt: "",
      serialNumber: "",
      remainingShots: 0,
      performedShots: 0
    }
  });

  React.useEffect(() => {
    loadingData();
  }, []);

  const loadingData = async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: "settings"
      });
      const result = await getCapsule(params.serialNumber, params?.fragranceId);
      console.log("getCapsule: ", result);
      setValue("customerCode", response.customerCode);
      setValue("capsuleId", result.capsuleId);
      setValue("deviceId", result.deviceId);
      setValue("fragranceId", result.fragranceId);
      setValue("dueDate", result.dueDate);
      setValue("serialNumber", result.serialNumber);
      setValue("remainingShots", result.remainingShots);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getCapsule = async (serialNumber: string, fragranceId: number) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/serial/${serialNumber}/${fragranceId}`
      });
      return response;
    } catch (error) {
      return { status: fragranceId, serialNumber };
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let reqBase: any;

      reqBase = { method: "PUT", url: `/capsules/${data.capsuleId}` };
      const body: CapsuleI = {
        customerCode: data.customerCode,
        capsuleId: data.capsuleId,
        deviceId: data.deviceId,
        fragranceId: data.fragranceId,
        dueDate: data.dueDate,
        serialNumber: data.serialNumber,
        remainingShots: data.remainingShots,
        performedShots: data.performedShots || 1,
        deletedAt: null
      };
      console.log("onSubmit: ", body);
      await api.makeHttpRequest({
        ...reqBase,
        data: body
      });
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/capsules");
        }
      });
    } catch (e) {
      Swal.fire(
        translate(".defaultMessages.error"),
        translate(".screens.user.errors.register"),
        "error"
      );
    } finally {
      setAllowSave(true);
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <div>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("labels_code")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate("screens.capsule.labels.serial")}
                  disabled
                  {...register("customerCode")}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.serial")} *
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={9}
                  minLength={9}
                  disabled
                  placeholder={translate("screens.capsule.labels.serial")}
                  {...register("serialNumber")}
                />
                {errors.serialNumber && (
                  <span className="invalid-input">
                    {errors.serialNumber.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.dueDate")}
                </Form.Label>

                <Controller
                  name="dueDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      value={value ? value.split("T")[0] : value}
                      onChange={(value: any) => onChange(value.target.value)}
                    />
                  )}
                />
                {errors.dueDate && (
                  <span className="invalid-input">
                    {errors.dueDate.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.dueDate")}
                </Form.Label>
                <Form.Control
                  type="number"
                  maxLength={9}
                  minLength={9}
                  placeholder={translate("screens.capsule.labels.serial")}
                  {...register("remainingShots")}
                />
              </Form.Group>
            </Row>

            <Divider color="error" />
          </Card.Body>
          <FormActions module="capsules" onReset={handleReset} />
        </Form>
      </Card>
      <Loading isLoading={loading} />
    </div>
  );
};

export default CapsuleUpdate;
