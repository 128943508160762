import React, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";

import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { IFormQuiz, IFormUser } from "types";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  dataFormUser: Array<IFormQuiz | IFormUser>;
  formatRow: any;
  formatHead: any[];
}

const TableWithAutoPagination: React.FC<Props> = ({
  dataFormUser,
  formatRow,
  formatHead
}) => {
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);

  const handlePageChange = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to the first page when changing items per page
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToShow = dataFormUser.slice(startIndex, endIndex);

  return (
    <Grid2 md={12} lg={5}>
      <Table aria-labelledby="tableTitle" size="small">
        <TableHead>
          <TableRow>
            {formatHead.map(head => (
              <TableCell {...head.rest} key={head.column}>
                {head.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsToShow.length ? (
            itemsToShow.map(row => formatRow(row))
          ) : (
            <TableRow>
              <TableCell colSpan={formatHead.length} className="text-center">
                {translate('datatable.nothingData')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${translate('datatable.of')} ${count}`
              }
              colSpan={10}
              count={dataFormUser.length || 0}
              rowsPerPage={itemsPerPage}
              page={currentPage}
              labelRowsPerPage={translate('datatable.labelRowsPerPage')}
              backIconButtonProps={{
                "aria-label": translate('datatable.backButton')
              }}
              nextIconButtonProps={{
                "aria-label": translate('datatable.nextButton')
              }}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleItemsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Grid2>
  );
};

export default TableWithAutoPagination;
