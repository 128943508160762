import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { Edit, Delete, ArrowUpward, Check, Close } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";

interface Answer {
  desc: string;
  description?: string;
  axle?: string;
  nextQuizQuestion?: { label: string };
  endQuiz: boolean;
}

interface Question {
  type: string;
  answers: Answer[];
}

interface Props {
  question: Question;
  editAnswer: (answer: Answer, index: number) => void;
  clearAnswer: (answer: Answer) => void;
  moveAnswerUp: (index: number) => void;
}

const TableAnswers: React.FC<Props> = ({
  question,
  editAnswer,
  clearAnswer,
  moveAnswerUp
}) => {
  const t = useTranslation();

  return (
    <Table
      style={{
        marginTop: "10px",
        background: "#f5f5f5",
        borderRadius: "8px",
        overflow: "hidden"
      }}
    >
      <TableHead className="table-light">
        <TableRow className="text-uppercase">
          <TableCell className="font-weight-bold" width="20%">
            {t("screens.quiz.list.actions")}
          </TableCell>
          <TableCell className="font-weight-bold" width="20%">
            {t("screens.quiz.labels.answer")}
          </TableCell>
          <TableCell className="font-weight-bold" width="25%">
            {t("labels_code")}
          </TableCell>
          {(question.type === "matriz" ||
            question.type === "matriz-select") && (
            <TableCell className="font-weight-bold" width="10%">
              {t("screens.quiz.labels.axle")}
            </TableCell>
          )}
          {question.type === "matriz-select" && (
            <TableCell className="font-weight-bold" width="10%">
              {t("screens.quiz.labels.position")}
            </TableCell>
          )}
          {question.type === "color" && (
            <TableCell className="font-weight-bold" width="10%">
              {t("screens.quiz.labels.color")}
            </TableCell>
          )}
          <TableCell className="font-weight-bold" width="25%">
            {t("screens.quiz.labels.next")}
          </TableCell>
          {question.type === "color" && (
            <TableCell className="font-weight-bold" width="15%">
              {t("screens.quiz.list.actions")}s
            </TableCell>
          )}
          <TableCell className="font-weight-bold" width="15%">
            {t("end_quiz")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {question.answers.length ? (
          question.answers.map((a, i) => (
            <TableRow hover key={"answer-" + i}>
              <TableCell>
                <IconButton
                  size="small"
                  title={t("screens.quiz.labels.editAnswer")}
                  onClick={() => {
                    editAnswer(a, i);
                  }}
                >
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  title={t("screens.quiz.labels.deleteAnswer")}
                  onClick={() => clearAnswer(a)}
                >
                  <Delete fontSize="small" />
                </IconButton>
                {i === 0 ? null : (
                  <IconButton size="small" onClick={() => moveAnswerUp(i)}>
                    <ArrowUpward fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>{a.desc}</TableCell>
              <TableCell>{a?.description}</TableCell>
              {question.type === "matriz-select" && (
                <>
                  <TableCell>{a.axle?.split(":")[0]}</TableCell>
                  <TableCell>{a.axle?.split(":")[1]}</TableCell>
                </>
              )}
              {question.type === "matriz" && <TableCell>{a.axle}</TableCell>}
              {question.type === "color" && (
                <TableCell>
                  <span
                    style={{
                      display: "inline-block",
                      background: a.desc,
                      width: "54px",
                      height: "54px"
                    }}
                  ></span>
                </TableCell>
              )}
              <TableCell>{a.nextQuizQuestion?.label}</TableCell>
              <TableCell>{a.endQuiz ? <Check/> : <Close/>}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow className="text-center">
            <TableCell colSpan={5}>
              {t("screens.quiz.labels.noQuestions")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableAnswers;
