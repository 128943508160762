import React, { useState } from "react";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import { Spinner, Col, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { IsValidPassword } from "../../../../utils/validators/IsValidPassword";
import { Button, Grid, TextField } from "@mui/material";
import * as auth from "../../../../store/ducks/auth.duck";
import { connect, useSelector } from "react-redux";
import translate from "@noar-firmenich/commons";

import "./style.scss";

function ModalAlterPassword(props) {
  const locale = useSelector(({ i18n }) => i18n.lang);

  const { closeModal, result } = props;
  const api = new AnanseApiService();
  const [loading, setLoading] = useState("");

  const useStyles = makeStyles(theme => ({
    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    message: {
      fontSize: "1rem"
    },
    spinnerBorder: {
      width: "2rem",
      height: "2rem"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div className="container-modal">
        <span className="title">{translate(locale).alterPassword.title}</span>
        {loading ? (
          <div className={classes.modalBody}>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <h3>{translate(locale).alterPassword.loading}</h3>
              </Col>
            </Row>
            <Row className="show-grid">
              <Spinner
                animation="border"
                className={classes.spinnerBorder}
                size="xs"
              />
            </Row>
          </div>
        ) : null}
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
            newPassword: ""
          }}
          validate={values => {
            const errors = {};

            if (!values.password) {
              errors.password = translate(locale).alterPassword.required;
            }

            if (!values.newPassword) {
              errors.newPassword = translate(locale).alterPassword.required;
            } else if (IsValidPassword(values.newPassword) !== true) {
              errors.newPassword = translate(locale).alterPassword.minimumReqs;
              IsValidPassword(values.newPassword).ERROS_FOUND.map(item => {
                errors.newPassword += " /" + item;
                return errors.newPassword;
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = translate(locale).alterPassword.required;
            } else if (values.newPassword !== values.confirmPassword) {
              errors.confirmPassword = translate(
                locale
              ).alterPassword.labels.matchConfirmation;
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const reqBase = { method: "PUT", url: "/usuario/update-password" };
            try {
              setLoading(true);
              const response = await api.makeHttpRequest({
                ...reqBase,
                data: {
                  senhaAtual: values.password,
                  novaSenha: values.newPassword
                }
              });
              if (response) {
                setLoading(false);
                setSubmitting(false);
                resetForm({});
                result(true, "");
              }
            } catch (error) {
              setLoading(false);
              setSubmitting(false);
              if (error.response && error.response.data) {
                result(false, error.response.data);
              } else {
                result(false, translate(locale).alterPassword.failure);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              className="col-12"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Grid>
                <Grid xs={12} md={12}>
                  <TextField
                    variant="standard"
                    type="password"
                    margin="normal"
                    label={translate(locale).alterPassword.labels.password}
                    className="input-modal-update-password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <TextField
                    variant="standard"
                    type="password"
                    margin="normal"
                    label={translate(locale).alterPassword.labels.newPassword}
                    className="kt-width-full input-modal-update-password"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <TextField
                    variant="standard"
                    type="password"
                    margin="normal"
                    label={
                      translate(locale).alterPassword.labels.confirmPassword
                    }
                    className="kt-width-full input-modal-update-password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                  />
                </Grid>
              </Grid>
              <div className="d-flex justify-content-between pt-2">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={closeModal}
                >
                  {translate(locale).buttons.cancel}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {translate(locale).buttons.change}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ModalAlterPassword));
