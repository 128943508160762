import RCColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import "./style.scss";
import { useState } from "react";

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

type SizeType = {
  width: string;
  height: string;
};

type ColorType = {
  color: string;
  alpha: number;
  open: boolean;
};

const ColorPicker = (props: ColorPickerProps) => {
  const { onChange } = props;

  const [currentColor, setCurrentColor] = useState("");

  const handleChange = (color: ColorType) => {
    setCurrentColor(color.color);
  };

  const handleClose = (e: ColorType) => {
    onChange(currentColor);
  };

  return (
    <RCColorPicker
      color={props.color}
      onChange={handleChange}
      placement="bottomRight"
      style={{ width: "150px", height: "150px" }}
      animation={"slide-up"}
      className={"color-picker"}
      onClose={handleClose}
    />
  );
};

export default ColorPicker;
