import React from "react";
import { Form, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { injectIntl } from "react-intl";

const MAX_FILE_SIZE_MB = 5;

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputId: Math.random().toString() + props.name,
      selectedFileName: "",
      imageUrl: null,
      userSelectedImage: false // Adicionado para rastrear se o usuário selecionou uma imagem
    };

    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showImagePreview = this.showImagePreview.bind(this);
    this.loadDefaultImage = this.loadDefaultImage.bind(this);
  }

  componentDidMount() {
    if (!this.props.currentUploadedFile) {
      setTimeout(() => {
        if (
          this.props.defaultImagePath &&
          !this.state.userSelectedImage &&
          !this.props.currentUploadedFile
        ) {
          this.loadDefaultImage();
        }
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.currentUploadedFile) {
      setTimeout(() => {
        if (
          this.props.defaultImagePath &&
          !this.state.userSelectedImage &&
          !this.props.currentUploadedFile
        ) {
          this.loadDefaultImage();
        }
      }, 1000);
    }
  }

  loadDefaultImage() {
    fetch(this.props.defaultImagePath)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "defaultImage.png", {
          type: "image/png"
        });
        this.handleChange(file); // Aqui passamos diretamente o objeto File
      })
      .catch(err => console.error(err));
  }

  openFileBrowser() {
    const element = document.getElementById(this.state.inputId);
    element.click();
  }

  showImagePreview(url) {
    Swal.fire({
      html: `<img class='image-upload-preview' src='${url}' alt='' />`
    });
  }

  handleChange(e) {
    let file;
    if (e.target && e.target.files) {
      file = e.target.files.item(0);
      this.setState({ userSelectedImage: true }); // Atualiza para indicar que o usuário selecionou uma imagem
    } else {
      // Se não vier de um evento de seleção de arquivo (ou seja, carregar a imagem padrão)
      file = e; // 'e' é o nosso objeto "simulado" de arquivo
    }

    if (!file) {
      return;
    }

    if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
      return Swal.fire(
        this.props.intl.formatMessage({ id: "ERROR" }),
        this.props.intl.formatMessage(
          { id: "FORM.UPLOAD.MAX_FILE_SIZE" },
          { max: MAX_FILE_SIZE_MB }
        )
      );
    }

    this.setState({
      selectedFileName: file.name,
      imageUrl: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null
    });

    this.props.handleChange({
      target: {
        value: file,
        name: this.props.name
      }
    });

    if (e.target && e.target.value) {
      e.target.value = ""; // Limpa o input file, se aplicável
    }
  }

  render() {
    const {
      label,
      placeholder,
      accept,
      validator,
      validations,
      name
    } = this.props;

    const shouldShowSelectedFileName = this.state.selectedFileName;

    return (
      <Form.Group as={Col} lg="6" xs="12">
        <Form.Label>{label}</Form.Label>
        <div>
          <Button
            className="text-white"
            variant="contained"
            size="small"
            color="success"
            onClick={this.openFileBrowser}
          >
            {placeholder}
          </Button>

          {shouldShowSelectedFileName ? (
            this.state.imageUrl ? (
              <img
                className="image-upload-preview"
                src={this.state.imageUrl}
                alt=""
              />
            ) : (
              <span className="mt ml-3 text-dark selected-file-name">
                {this.state.selectedFileName}
              </span>
            )
          ) : (
            this.props.currentUploadedFile && (
              <a
                href={this.props.currentUploadedFile.tempUri}
                target="__blank"
                className="selected-file-name"
                download
              >
                {this.props.accept &&
                this.props.accept.indexOf("image") !== -1 ? (
                  <img
                    className="image-upload-preview"
                    src={this.props.currentUploadedFile.tempUri}
                    alt=""
                  />
                ) : (
                  <span className="mt ml-3 text-info">
                    {this.props.currentUploadedFile.name}
                  </span>
                )}
              </a>
            )
          )}
        </div>

        <Form.Control
          type="file"
          className="d-none"
          accept={accept}
          id={this.state.inputId}
          name={name}
          onChange={this.handleChange}
        />

        {validator.message(
          name,
          shouldShowSelectedFileName ||
            (this.props.currentUploadedFile &&
              this.props.currentUploadedFile.name),
          validations
        )}
      </Form.Group>
    );
  }
}

export default injectIntl(FileUpload);
