import { InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Col, Form } from "react-bootstrap";
import "./style.scss";

type OptionType = {
  value: string;
  label: string;
};

type SelectModalProps = OptionType & {
  options: Array<OptionType>;
  className: string | undefined;
  onChange: (event: SelectChangeEvent<any>) => void;
};

const SelectModal = ({
  value,
  label,
  options,
  className,
  onChange
}: SelectModalProps) => {
  return (
    <Form.Group
      as={Col}
      lg="2"
      xs="12"
      className={className}
      style={{ marginTop: "5px" }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        className={"uppercase"}
        label={label}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <MenuItem
            className={"uppercase"}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Form.Group>
  );
};
export default SelectModal;
