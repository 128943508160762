export const selectData = [
  {
    key: 1,
    value: "checkbox",
    name: "CheckBox"
  },
  {
    key: 2,
    value: "select",
    name: "Select"
  },
  {
    key: 3,
    value: "radio",
    name: "Radio"
  },
  {
    key: 4,
    value: "text",
    name: "Text"
  },
  {
    key: 5,
    value: "matriz",
    name: "Matrix"
  },
  {
    key: 6,
    value: "matriz-select",
    name: "Matrix/Select"
  },
  {
    key: 7,
    value: "color",
    name: "Color"
  },
  {
    key: 8,
    value: "image",
    name: "Image"
  },
  {
    key: 9,
    value: "audio",
    name: "Audio"
  },
  {
    key: 10,
    value: "video",
    name: "Video"
  }
];

export const selectSegmentData = [
  { key: 1, value: "Screening", name: "Screening" },
  { key: 2, value: "Evaluation", name: "Evaluation" },
];
