import React from "react";
import { Button, Icon } from "@mui/material";

class RefreshButton extends React.Component {
  render() {
    const { refreshAction } = this.props;
    return (
      <Button
        variant="outlined"
        className="kt-button-refresh"
        onClick={refreshAction}
      >
        <Icon>refresh</Icon>
      </Button>
    );
  }
}

export default RefreshButton;
