import React from 'react';
import ReactPlayer from 'react-player';

const RenderAudio: React.FC<{ uri: string | undefined }> = ({ uri }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ReactPlayer
        url={uri}
        controls={true}
        width="100%"
        height="50px" // Altura desejada para o player
      />
    </div>
  );
};

export default RenderAudio;
