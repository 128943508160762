import { Link } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { connect } from "react-redux";
import { useTranslation } from "_metronic/i18n/language";

type FormActionsProps = {
  module: string;
  formIsValid?: boolean;
  isEdit?: boolean;
  i18n?: any;
  onCleanForm?: () => void;
};

function FormActions({
  formIsValid,
  onCleanForm,
  isEdit,
  module,
  i18n
}: FormActionsProps) {
  const translate = useTranslation();
  return (
    <Stack
      sx={{ marginTop: "30px" }}
      justifyContent="flex-end"
      alignItems="flex-end"
      direction={{ xs: "column", sm: "row" }}
      spacing={1}
    >
      <Link to={`/admin/${module}`}>
        <Button
          variant="contained"
          color="error"
          type="button"
          className="mr-3"
        >
          {translate("buttons.cancel")}
        </Button>
      </Link>

      <Button
        variant="contained"
        color="warning"
        onClick={onCleanForm}
        className="mr-3"
      >
        {!isEdit ? translate("buttons.clean") : translate("buttons.revert")}
      </Button>

      <Button
        variant="contained"
        color="success"
        type="submit"
        disabled={!formIsValid}
        title={formIsValid ? "Submeter formulário" : "Verifique os campos"}
      >
        {translate("buttons.save")}
      </Button>
    </Stack>
  );
}

const mapStateToProps = (store: any) => ({
  i18n: store.i18n
});

export default connect(mapStateToProps)(FormActions);
