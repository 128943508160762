import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { connect } from "react-redux";

import FormActions from "../../components/FormActions";
import IsValidName from "../../../../utils/validators/IsValidName";
import { QuizApiService } from "../../../../services/Ananse/QuizApiService";
import Loading from "../../../home/components/Loading";
import SelectSearchComponent from "../../../auth/Components/Select2";
import SelectLanguage from "../../components/SelectLanguage";
import MultipleSelectCheckmarks from "./component/MultipleSelectCheckmarks";
import NewSelectItem from "./component/NewSelectItem";
import { TransferList } from "components";

const initialState = {
  name: "",
  campaignId: null,
  campaignName: "",
  languageName: "",
  resetInput: false,
  maxShots: null,
  userId: "",
  nome: "",
  id: null,
  select: [],
  type: "1",
  anamneseId: 0,
  mandatoryAnamnese: false
};

class QuizForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      language: this.convertLanguage()
    };

    this.api = new QuizApiService();

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.submit = this.submit.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const select = [
        {
          key: 1,
          name: this.props.translate.menu.quiz?.title?.quiz //"Identificação"
        }
      ];

      this.setState({ select });
    } catch (error) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        this.props.translate.defaultMessages.loadData,
        "error"
      );
    }
    this.setState({ isLoading: false });
  }

  submit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const reqBase = { method: "POST" };
      const {
        name,
        campaignId,
        language,
        maxShots,
        userId,
        type,
        anamneseId,
        mandatoryAnamnese,
        requiredCampaing
      } = this.state;

      const result = await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          name,
          campaignId,
          language,
          maxShots,
          userId,
          type,
          anamneseId,
          mandatoryAnamnese
        }
      });

      this.afterSubmit(result);
    } catch (e) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        e.response && e.response.status === 400
          ? e.response.data
          : this.props.translate.screens.quiz.errors.create,
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async result => {
    this.setState({
      ...initialState,
      resetInput: true
    });
  };

  handleChangeLanguage = e => {
    this.setState({ language: e.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.maxShots]: e.target.maxShots,
      [e.target.type]: e.target.type
    });
  };

  handleChangeSelect = e => {
    this.setState({
      campaignId: e.id,
      campaignName: e.label,
      resetInput: false
    });
  };

  handleChangeSelectMulti = e => {
    const newId = e.map(usuario => usuario.id).join(";");
    this.setState({
      userId: newId
    });
  };

  formIsValid = () => {
    const { name, campaignId } = this.state;
    const isValid = IsValidName(name) && campaignId !== null;
    return isValid;
  };

  onCleanForm = async () => {
    this.setState({
      ...initialState,
      resetInput: true,
      resetInputFilial: true
    });
  };

  convertLanguage() {
    let { lang } = this.props;

    switch (lang) {
      case "pt":
        return "pt-BR";
      case "en":
        return "en-US";
      default:
        return "en-US";
    }
  }

  checkMandatoryAnamnese = event => {
    const { checked } = event.target;
    this.setState({ mandatoryAnamnese: checked });
  };

  render() {
    window.setPageTitle(
      `${this.props.translate.screens.quiz.pageTitle.create} - Admin`
    );

    const {
      name,
      resetInput,
      maxShots,
      select,
      type,
      anamneseId,
      mandatoryAnamnese,
      userId
    } = this.state;

    return (
      <Card className="mt-3">
        <Loading isLoading={this.state.isLoading} />
        <Card.Header>
          {this.props.translate.screens.quiz.headerTitle}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.submit}>
            {/* row 1 */}
            <Row>
              {/* Nome */}
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.quiz.labels.name} *
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="name"
                  placeholder={
                    this.props.translate.screens.quiz.placeholders.name
                  }
                  maxLength="100"
                  value={name}
                  isInvalid={name && !IsValidName(name)}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              {/* maxShot */}
              { requiredCampaing && <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.quiz.maxShotLabel} *
                </Form.Label>
                <Form.Control
                  type="number"
                  autoComplete="off"
                  name="maxShots"
                  placeholder={this.props.translate.screens.quiz.maxShotLabel}
                  maxLength="100"
                  value={maxShots}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              }

              {/* Campanha */}
              {requiredCampaing && <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.quiz.labels.campaign} *
                </Form.Label>
                <SelectSearchComponent
                  resetInput={resetInput}
                  required={true}
                  handleChange={e => this.handleChangeSelect(e)}
                  className="kt-width-full"
                  label={
                    this.props.translate.screens.quiz.placeholders.campaign
                  }
                  url={`/campaigns/without-quiz?str=`}
                  convertObject={obj => ({
                    id: obj.campaignId,
                    value: obj.campaignId,
                    label: `${obj.name}`,
                    obj
                  })}
                />
              </Form.Group>
              }
              {/* Nome */}
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.quiz.labels?.language}
                </Form.Label>
                <SelectLanguage
                  translate={this.props.translate}
                  handleChangeLanguage={this.handleChangeLanguage}
                  languageValue={this.state.language}
                />
              </Form.Group>

              {/* SelectAnamnese */}
              <Form.Group as={Col} lg="4" xs="12">
                <NewSelectItem
                  url="quiz/anamnese"
                  placeholder={"Selecionar Formulario"}
                  value={anamneseId}
                  onSelect={value => {
                    this.setState({ anamneseId: value });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-3">
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Checkbox
                    color="success"
                    value={mandatoryAnamnese}
                    checked={mandatoryAnamnese}
                    onChange={this.checkMandatoryAnamnese}
                  />
                  <Typography>Formulario Obrigatório?</Typography>
                </Stack>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} lg="8" xs="12" className="mb-0 pt-2">
                <TransferList
                  endPoint={`usuario`}
                  value={userId}
                  handleMultiSelect={this.handleChangeSelectMulti}
                />
                {/* <MultipleSelectCheckmarks
                  placeholder={this.props.translate.screens.quiz.userLabel}
                  url={`/usuario`}
                  handleMultiSelect={this.handleChangeSelectMulti}
                /> */}
              </Form.Group>
            </Row>

            <FormActions
              module="quiz"
              isEdit={false}
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
              submitLabel={this.props.translate.buttons.save}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(QuizForm);
