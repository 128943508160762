import { yupResolver } from "@hookform/resolvers/yup";
import translate from "@noar-firmenich/commons";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";

import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { SelectSearch } from "components";
import FormActions from "components/FormActions";
import { UseSelectorI } from "interfaces";
import FormQuiz from "./FormQuiz";
import FormUser from "./FormUser";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Stack } from "@mui/material";
import { IForm } from "types";
import { useTranslation } from "_metronic/i18n/language";

interface IConvertObject {
  id: number;
  value: number;
  label: string;
  obj: any;
}
const CreateFormUserQuiz: React.FC = () => {
  const params = useParams();
  const history = useHistory();

  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({} as IConvertObject);
  const [form, setForm] = useState({} as IForm);
  const schema = Yup.object().shape({
    formName: Yup.string().required(
      translate('screens.login.validations.required')
    )
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      formName: ""
    }
  });

  useEffect(() => {
    loadingData();
  }, []);

  const onSubmit = async (data: any) => {
    // delete data.address;
    const payload = {
      ...data,
      campaignId: campaign.id
    };

    setLoading(true);
    try {
      const { id }: any = params;
      let reqBase: any;
      if (id) {
        console.log("payload put: ", payload);
        reqBase = { method: "PUT", url: `/form/${id}` };
      } else {
        console.log("payload post: ", payload);
        reqBase = { method: "POST", url: "/form" };
      }

      const response = await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      console.log("response form: ", response);
      setForm(response);
      Swal.fire({
        title: translate('defaultMessages.success'),
        text: translate('crudMessages.successText'),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          // history.push("/admin/form");
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const loadingData = async () => {
    setLoading(true);
    try {
      const { id }: any = params;
      if (id) {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/form/${id}`
        });
        reset({
          formName: response.formName
        });
        setForm(response);
        if (response.campaignId) {
          const cam = await api.makeHttpRequest({
            method: "GET",
            url: `/campaigns/${response.campaignId}`
          });
          setCampaign({
            id: cam.campaignId,
            label: cam.name,
            value: cam.campaignId,
            obj: cam
          });
        }
      }
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
    setCampaign({} as IConvertObject);
  };

  const onFinish = () => {
    history.push("/admin/flow");
  };

  return (
    <div>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="8" xs="12">
                <Form.Label>
                  {translate('screenApp.listPacient.account.name')}*
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder={translate('screens.user.placeholders.name')}
                  {...register("formName")}
                />

                {errors.formName && (
                  <span className="invalid-input">
                    {errors.formName.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate('screens.quiz.labels.campaign')} *
                </Form.Label>
                <SelectSearch
                  url={`/campaigns/without-quiz?str=`}
                  label={translate('screens.quiz.placeholders.campaign')}
                  value={{ label: campaign.label }}
                  convertObject={(obj: any) => ({
                    id: obj.campaignId,
                    label: obj.name,
                    value: obj.campaignId,
                    obj
                  })}
                  handleChange={(e: any) => {
                    setCampaign(e);
                  }}
                />
              </Form.Group>
            </Row>
          </Card.Body>
          <FormActions module="flow" onReset={handleReset} />
        </Form>
      </Card>
      {form.formId ? (
        <Grid2 container spacing={4} sx={{ marginTop: 2 }}>
          <FormQuiz form={form} collection={campaign?.obj?.collection} />
          <FormUser form={form} />
        </Grid2>
      ) : (
        <></>
      )}
      <Stack>
        <Button variant="contained" color="success" onClick={onFinish}>
        {translate('screenApp.quiz.result.buttonFinish')}
        </Button>
      </Stack>

      <Loading isLoading={loading} />
    </div>
  );
};

export default CreateFormUserQuiz;
