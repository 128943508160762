import React from "react";
import Chart from 'react-apexcharts';
import Swal from "sweetalert2";
import Loading from '../../../home/components/Loading';
import { DashboardApiService } from '../../../../services';

import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../partials/content/Portlet";
import DashboardDropdown from '../../../../partials/content/CustomDropdowns/DashboardDropdown';
import { DashBoard } from "pages";


const initialState = {
  campainsChart: [],
  labelsFragrance: [],
  seriesFragrance: [],
  colorsFragrance: [],
  isLoading: false
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new DashboardApiService();
    this.exportFile = this.exportFile.bind(this);
    this.setupChartOptionsFragrance = this.setupChartOptionsFragrance.bind(this);
    this.setupChartOptionsCampaign = this.setupChartOptionsCampaign.bind(this);
  }

  async componentDidMount() {
    await this.setupChartOptionsCampaign();
    await this.setupChartOptionsFragrance();
  }


  getChartWidth = () => {
    if (this.props.width >= 1900) {                           //1920px
      return 500;

    } else if (this.props.width < 1920 && this.props.width >= 1360) {     //1366px
      return 450;

    } else if (this.props.width < 1360 && this.props.width >= 1270) {     //1280px
      return 450;

    } else if (this.props.width < 1270 && this.props.width >= 1010) {     //1024px
      return 400;

    } else if (this.props.width < 1010 && this.props.width >= 760) {      //768px
      return 400;

    } else if (this.props.width <= 375 && this.props.width > 360) {       //Iphone X & Iphone 6/7/8
      return 330;

    } else if (this.props.width > 375 && this.props.width < 760) {        //Mobiles
      return 400;

    } else if (this.props.width <= 360) {                      //Galaxy S5 & Moto G4
      return 300;

    } else {
      return 350;
    }
  };


  setupChartOptionsCampaign = async () => {
    if (!this.state.isLoading) {
      this.setState({isLoading: true});
    }

    this.setState({ campainsChart: [] });

    try {
      const campains = await this.api.makeHttpRequest({
        url: '/shots-campaign?limit=true'
      });

      console.log(campains);

      if (campains && Array.isArray(campains)) {
        await campains.forEach(campain => {
          if (campain.campaignName && Array.isArray(campain.fragrances)) {
            let _labels = [];
            let _series = [];
            let _colors = [];

            campain.fragrances.forEach(fragrance => {
              _labels.push(fragrance.fragranceName);
              _series.push(fragrance.shotsTotalFragrance);
              _colors.push(this.generateColor());
            });

            let chart = {
              campaignName: campain.campaignName,
              campaignId: campain.campaignId,
              labels: _labels,
              series: _series,
              colors: _colors
            };

            this.setState({
              campainsChart: [
                ...this.state.campainsChart,
                chart
              ]
            });
          }
        });
      }
    } catch (error) {
      Swal.fire(
        this.props.translate.defaultMessages?.error,
        this.props.translate.defaultMessages?.loadData,
        'error'
      );
    }

    this.setState({isLoading: false});
  };

  setupChartOptionsFragrance = async () => {
    if (!this.state.isLoading) {
      this.setState({isLoading: true});
    }

    this.setState({ labelsFragrance: [] });
    this.setState({ colorsFragrance: [] });
    this.setState({ seriesFragrance: [] });

    try {
      const shotsFragrance = await this.api.makeHttpRequest({
        url: '/shots-fragrance?limit=true'
      });

      if (shotsFragrance && Array.isArray(shotsFragrance)) {
        await shotsFragrance.forEach(shot => {
          if (shot.shotsTotal && shot.shotsTotal !== 0) {
            this.setState({
              labelsFragrance: [
                ...this.state.labelsFragrance,
                shot.fragranceName
              ]
            });

            this.setState({
              seriesFragrance: [
                ...this.state.seriesFragrance,
                shot.shotsTotal
              ]
            });

            this.setState({
              colorsFragrance: [
                ...this.state.colorsFragrance,
                this.generateColor()
              ]
            });
          }
        });
      }
    } catch (error) {
      Swal.fire(
        this.props.translate.defaultMessages?.error,
        this.props.translate.defaultMessages?.loadData,
        'error'
      );
    }

    this.setState({isLoading: false});
  };

  generateColor = () => {
    return '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
  };

  exportFile = async (url) => {
    this.setState({ isLoading: true });
    try {
      await this.api.download({ url }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      Swal.fire(
        this.props.translate.defaultMessages?.error,
        this.props.translate.defaultMessages?.errorExport,
        'error'
      );
    }

  }

  render() {
    window.setPageTitle('Home - Admin');

    const defaultOptions = {
      tooltip: {
        followCursor: true,
        style: {
          fontSize: '14px',
        },
      },
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.10,
          }
        }
      }
    }

    const optionsFragrance = {
      ...defaultOptions,
      chart: {
        id: "chart2",
        animations: {
          enabled: true,
          speed: 800,
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      colors: this.state.isLoading ? [] : this.state.colorsFragrance,
      labels: this.state.isLoading ? [] : this.state.labelsFragrance,
      series: this.state.isLoading ? [] : this.state.seriesFragrance,
    };

    return (
      <>
        <Loading isLoading={this.state.isLoading} />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12 mt-3">
            <Portlet fluidHeight={true}>
              <PortletHeader
                title={`${this.props.translate.screens?.dashboard?.shotForCampaign}`}
                toolbar={
                  <DashboardDropdown
                    onClickRefresh={() => this.setupChartOptionsCampaign()}
                    translate={this.props.translate}
                    onClickExport={() => this.exportFile(`/export-shot-campaign`)}
                  />
                }
              />
                <PortletBody id={`pie-chart-campaign-fragrance`}>
                  <div className="row">
                    {this.state.campainsChart.map((campainChart, i) => {
                      let opt = {
                        ...defaultOptions,
                        chart: {
                          id: `chart-${i}`,
                          animations: {
                            enabled: true,
                            speed: 800,
                            dynamicAnimation: {
                              enabled: true,
                              speed: 350
                            }
                          }
                        },
                        colors: this.state.isLoading ? [] : campainChart.colors,
                        labels: this.state.isLoading ? [] : campainChart.labels,
                        series: this.state.isLoading ? [] : campainChart.series,
                      };

                      return (
                          <div className="col-md-4 col-sm-12" key={i}>
                            <div className="text-left">
                              <label>{campainChart.campaignName}</label>
                            </div>
                            <Chart
                              options={opt}
                              series={opt.series}
                              type="donut"
                              width={380}
                            />
                          </div>
                      )
                    })}
                  </div>
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-6 col-xs-6 mt-3">
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title={this.props.translate.screens?.dashboard?.shotForFragrance}
                  toolbar={
                    <DashboardDropdown
                      onClickRefresh={() => this.setupChartOptionsFragrance()}
                      translate={this.props.translate}
                      onClickExport={() => this.exportFile('/export-shot-fragrance')}
                    />
                  }
                />

                {!this.state.isLoading
                  && (
                    <PortletBody id="pie-chart-2">
                      <Chart
                        options={optionsFragrance}
                        series={optionsFragrance.series}
                        type="donut"
                        width={this.getChartWidth()}
                      />
                    </PortletBody>
                  )}
              </Portlet>
            </div>
          </div>
      </>
    );
  }
}
