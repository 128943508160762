import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";

import { getTranslation } from "../utils";
import { UseSelectorI } from "interfaces";
import { getPastDate } from "../utils/extractDate";

type PeriodSelectioProps = {
  onStartDateChange: (value: Date | null) => void;
  onEndDateChange: (value: Date | null) => void;
};

export const PeriodSelector = ({
  onStartDateChange,
  onEndDateChange
}: PeriodSelectioProps) => {
  const [startDate, setStartDate] = useState<Date | null>(getPastDate(90));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);

  return (
    <Grid container spacing={2} marginBlock={2}>
      <Grid xs={6}>
        <form>
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              label={getTranslation(locale, "start_Date")}
              value={startDate}
              onChange={(value: Date | null) => {
                setStartDate(value);
                onStartDateChange(value);
              }}
            />
          </LocalizationProvider>
        </form>
      </Grid>
      <Grid xs={6}>
        <form>
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              label={getTranslation(locale, "end_Date")}
              value={endDate}
              onChange={(value: Date | null) => {
                setEndDate(value);
                onEndDateChange(value);
              }}
            />
          </LocalizationProvider>
        </form>
      </Grid>
    </Grid>
  );
};
