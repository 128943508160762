import React from "react";
import { debounce } from "lodash";
import AsyncCreatableSelect from "react-select/async-creatable";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { connect, useSelector } from "react-redux";
import translate from "@noar-firmenich/commons";
import { UseSelectorI } from "interfaces";

interface Props {
  disabled?: boolean;
  className?: any;
  label?: any;
  handleChange?: any;
  value?: any;
  newOption?: any;
  required?: any;
  resetInput?: boolean;
  setNewOption?: any;
  createLabelText?: any;
  convertObject?: any;
  url: any;
}

const SelectSearch = ({
  url,
  convertObject,
  className,
  createLabelText,
  disabled,
  handleChange,
  label,
  newOption,
  required,
  resetInput,
  setNewOption,
  value
}: Props) => {
  const api = new AnanseApiService();
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);

  const getListFromValue = async (inputValue: any) => {
    let searchedList: any[] = [];
    if (inputValue && inputValue.length >= 3) {
      try {
        let searchedData: any;
        const response = await api.makeHttpRequest({
          url: url + encodeURIComponent(inputValue)
        });
        if (response.length > 0) {
          searchedData = response;
        } else {
          searchedData = response.data;
        }
        if (searchedData && searchedData.length > 0) {
          searchedData.forEach((item: any) => {
            const converted = convertObject(item);
            if (searchedList.indexOf(converted) === -1) {
              searchedList.push(converted);
            }
          });
        }
      } catch (error) {
        // console.error(`Erro ao fazer a busca: ${error}`);
      }
    }

    return searchedList;
  };
  const promiseOptions = debounce((inputValue, callback) => {
    getListFromValue(inputValue).then(callback);
  }, 500);
  return (
    <AsyncCreatableSelect
      value={resetInput ? "" : value}
      cacheOptions
      defaultOptions
      isDisabled={disabled}
      loadOptions={promiseOptions}
      placeholder={label}
      noOptionsMessage={() => translate(locale).selectComponent.nothingFound}
      loadingMessage={() =>
        value && value.length >= 3
          ? translate(locale).defaultMessages.loading
          : translate(locale).selectComponent.minChar
      }
      className={className}
      onChange={e => handleChange(e)}
      onInputChange={e =>
        e && setNewOption
          ? setNewOption(true)
          : setNewOption
          ? setNewOption(false)
          : null
      }
      isValidNewOption={(e: any) => e.length > 2 && newOption}
      createOptionPosition={"first"}
      formatCreateLabel={(inputValue: any) =>
        inputValue
          ? createLabelText
            ? `${createLabelText} ${inputValue}`
            : `${translate(locale).selectComponent.create} ${inputValue}`
          : inputValue
      }
      styles={
        required && required.status
          ? {
              control: (base: any) => ({
                ...base,
                borderColor: required.color ? required.color : "#FF0000"
              })
            }
          : { control: (base: any) => ({ ...base }) }
      }
    />
  );
};

export default SelectSearch;
