import React from 'react';
import { useTranslation } from '_metronic/i18n/language';
import { ChangeEvent, useState } from "react";

type Props = {
  data: {
    answerId: number;
    answer: {
      label: string;
    };
    axle: string;
  }[];
};

export const InputMatrixSelectRender = ({ data }: Props) => {
  const translate = useTranslation();
  const rowAnswers = data.filter(item => item.axle.startsWith("Y"));
  const columnAnswers = data.filter(item => item.axle.startsWith("X"));

  const [selectedAnswers, setSelectedAnswers] = useState(
    Array.from(rowAnswers, ({ axle }) => ({
      key: axle.charAt(axle.length - 1),
      value: ""
    }))
  );

  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          {rowAnswers.map(row => {
            const rowIndex = row.axle.split(":")[1];
            return (
              <tr
                key={row.answerId}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <td>{row.answer.label}</td>
                <td>
                  <select
                    value={selectedAnswers.find(v => v.key === rowIndex)?.value}
                    style={{
                      height: "40px",
                      padding: "10px",
                      border: "none",
                      borderBottom: " 1px solid #b3b3b3",
                      backgroundColor: "transparent",
                      outline: "none"
                    }}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      const oldAnswers = selectedAnswers.filter(
                        value => rowIndex !== value.key
                      );
                      setSelectedAnswers([
                        ...oldAnswers,
                        { key: rowIndex, value: e.target.value }
                      ]);
                    }}
                  >
                    <option>{translate("screenApp_quiz_selectOption")}</option>
                    {columnAnswers
                      .filter(
                        ({ axle }) => axle.charAt(axle.length - 1) === rowIndex
                      )
                      .map(col => (
                        <option key={col.answerId} value={col.answer.label}>
                          {col.answer.label}
                        </option>
                      ))}
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
