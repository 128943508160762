import React, { useState } from "react";
import { Modal } from "@mui/material";
import Loading from "../../home/components/Loading";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import { AnanseApiService } from "../../../services/Ananse/AnanseApiService";
import { useSelector } from "react-redux";
import translate from "@noar-firmenich/commons";
import {
  Container,
  StyledInput,
  ButtonForm,
  CancelButton,
  SubmitButton,
  SuccessMessage
} from "./style";

const ForgotPassword = ({ closeModal, bg }) => {
  const [email, setEmail] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailChange = e => {
    setEmail(e.target.value);
  };
  const locale = useSelector(({ i18n }) => i18n.lang);

  const emailValidation = async () => {
    if (email === "") {
      setError(translate(locale).screens.forgotPassword.error.invalidEmailText);
      setDisabledButton(false);
      return false;
    }

    if (!IsValidEmail(email)) {
      setError(
        translate(locale).screens.forgotPassword.error.invalidEmailTitle
      );
      setDisabledButton(false);
      return false;
    }

    setError("");
    return true;
  };

  const closeBothModals = () => {
    setSuccess(false);
    closeModal();
  };

  const sendEmail = async email => {
    setIsLoading(true);
    const api = new AnanseApiService();
    return await api.makeHttpRequest({
      url: "/usuario/recuperar-senha",
      method: "POST",
      data: {
        email
      }
    });
  };

  const handleEmailSend = async () => {
    setDisabledButton(true);
    if ((await emailValidation()) === false) return;

    const successOnEmailSend = () => {
      setIsLoading(false);
      setError("");
      setSuccess(true);
    };

    const errorOnEmailSend = () => {
      setIsLoading(false);
      setError(translate(locale).screens.forgotPassword.error.relatedEmail);
      return false;
    };

    const sender = sendEmail(email);
    sender
      .then(successOnEmailSend)
      .catch(errorOnEmailSend)
      .finally(() => setDisabledButton(false));
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container bg={bg}>
        <span className="forgot-title">
          {translate(locale).screens.forgotPassword.forgotTitle}
        </span>
        <span className="forgot-subtitle">
          {translate(locale).screens.forgotPassword.forgotSubTitle}
        </span>

        <StyledInput
          placeholder={translate(locale).screens.forgotPassword.emailInput}
          value={email}
          error={error !== "" ? true : false}
          onChange={handleEmailChange}
          autoFocus={true}
          helperText={error !== "" && error}
        />

        <ButtonForm>
          <CancelButton disabled={disabledButton} onClick={closeModal}>
            {translate(locale).buttons.cancel}
          </CancelButton>
          <SubmitButton
            className="btn btn-primary"
            disabled={disabledButton}
            onClick={handleEmailSend}
          >
            {translate(locale).buttons.send}
          </SubmitButton>
        </ButtonForm>
      </Container>

      <Modal
        open={success}
        onClose={closeBothModals}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SuccessMessage>
          <span>
            {translate(locale).screens.forgotPassword.success.message}
          </span>
        </SuccessMessage>
      </Modal>
    </>
  );
};

export default ForgotPassword;
