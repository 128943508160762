import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Card,
  Stack
} from "@mui/material";
import FileUploadImage from "../UpImage";
import { FileUpload, RenderAudio, RenderImage, RenderVideo } from "components";
import { QuestionApiService } from "app/services/Ananse/QuestionApiService";
import { IAnswer, IQuizQuestion, RequestPost } from "../../interfaces";
import { useTranslation } from "_metronic/i18n/language";
import MediaUploadComponent from "./components/MediaUploadComponent";
import MediaButtonsComponent from "./components/MediaButtonsComponent";
import MediaTitleComponent from "./components/MediaTitleComponent";

interface FileUploadProps {
  responseQuestion: IQuizQuestion;
  question: IQuizQuestion;
  answer?: any;
  modal: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  showMedia: boolean;
}

interface ILoading {
  loading: boolean;
  message: string;
}

interface LoadingDialogProps {
  open: boolean;
  message?: string;
}

const serviceQuestionApi = new QuestionApiService();

const ResponseModalMedia = ({
  responseQuestion,
  question,
  modal,
  handleClose,
  showMedia
}: FileUploadProps) => {
  const [arrayImagens, setArrayImagens] = useState<RequestPost[]>([]);
  const [loading, setLoading] = useState({} as ILoading);
  const translate = useTranslation();

  const LoadingDialog: React.FC<LoadingDialogProps> = ({
    open,
    message = translate("screens.loading.title")
  }) => {
    return (
      <Dialog open={open} aria-labelledby="loading-dialog-title">
        <DialogContent sx={{ textAlign: "center", p: 4 }}>
          <CircularProgress color="success" size={40} sx={{ mb: 2 }} />
          <Typography variant="body1">{message}</Typography>
        </DialogContent>
      </Dialog>
    );
  };

  const getFileByFileOwnerId = useCallback(async () => {
    // setLoading({
    //   loading: true,
    //   message: translate("screens.loading.message")
    // });
    try {
      let prepareData: RequestPost[] = [];
      for (const a of question.answers) {
        const oldFileOwnerId = a.quizQuestionAnswerId;
        const findAnswer = responseQuestion.answers.find(
          f => f.answerId === a.answerId
        );
        if (oldFileOwnerId) {
          const reqBase = {
            method: "GET",
            url: `by-fileownerid?fileOwnerId=${oldFileOwnerId}`
          };
          const response = await serviceQuestionApi.makeHttpRequest(reqBase);
          if (response[0]) {
            const body = {
              ...response[0],
              newImage: false,
              fileOwnerId: findAnswer?.quizQuestionAnswerId?.toString() || "",
              oldFileOwnerId: oldFileOwnerId?.toString() || ""
            };
            prepareData.push(body);
          } else {
            // console.log("not found image: ", oldFileOwnerId);
          }
        }
      }
      setArrayImagens(prepareData);
    } catch (error) {}
    setLoading({} as ILoading);
  }, [question, responseQuestion]);

  useEffect(() => {
    getFileByFileOwnerId();
  }, [getFileByFileOwnerId]);

  const handleChange = (e: any, answer: IAnswer) => {

    const findAnswer = !answer.answerId
      ? responseQuestion.answers.find((f: any) => f.quizQuestionAnswerId === answer.quizQuestionAnswerId)
      : responseQuestion.answers.find(f => f.answerId === answer.answerId);

    const newImageData: RequestPost = {
      file: e.target.value,
      name: e.target.value.name,
      type: e.target.name,
      fileOwnerType: "5",
      fileOwnerId: findAnswer?.quizQuestionAnswerId?.toString() || "",
      oldFileOwnerId: answer.quizQuestionAnswerId?.toString() || "",
      newImage: true
    };

    const existingIndex = arrayImagens.findIndex(
      item =>
        item.oldFileOwnerId?.toString() ===
        newImageData.oldFileOwnerId?.toString()
    );

    if (existingIndex !== -1) {
      const updatedArray = [...arrayImagens];
      updatedArray[existingIndex] = {
        ...newImageData,
        fileId: updatedArray[existingIndex].fileId,
        path: updatedArray[existingIndex].path
      };
      setArrayImagens(updatedArray);
    } else {
      setArrayImagens(v => [...v, newImageData]);
    }
  };

  const handleUpload = async () => {
    setLoading({
      loading: true,
      message: translate("screenApp.listPacient.account.InfoSaving")
    });
    try {
      for (const currentUploadedImage of arrayImagens) {
        if (!currentUploadedImage) {
          alert("Selecione um arquivo antes de fazer o upload.");
          return;
        }

        try {
          const formData = new FormData();
          formData.append("File", currentUploadedImage.file);
          formData.append("FileOwnerId", currentUploadedImage.fileOwnerId);
          formData.append("FileOwnerType", currentUploadedImage.fileOwnerType);
          formData.append("Name", currentUploadedImage.name);
          formData.append("Path", currentUploadedImage.path || "");

          if (
            (currentUploadedImage.fileId &&
              currentUploadedImage.fileOwnerId !==
                currentUploadedImage.oldFileOwnerId &&
              currentUploadedImage.tempUri) ||
            !currentUploadedImage.file
          ) {
            formData.append(
              "FileId",
              currentUploadedImage.fileId?.toString() || ""
            );
            const reqBase = {
              method: "PUT",
              url: `updatefileownerid/${currentUploadedImage.fileId}/${currentUploadedImage.fileOwnerId}`
            };
            const response = await serviceQuestionApi.makeHttpRequest({
              ...reqBase,
              data: formData
            });
          } else {
            const reqBase = { method: "POST", url: "questionanswerfile" };
            const response = await serviceQuestionApi.makeHttpRequest({
              ...reqBase,
              data: formData
            });
          }
        } catch (error) {
          console.error("Erro durante o upload:", error);
        }
      }
    } catch (error) {
      console.error("Erro ao subir imagens:", error);
    } finally {
      handleClose();
      setLoading({} as ILoading);
    }
  };

  const findImagens = useCallback(
    (a: any) => {
      return (
        arrayImagens.find(v => {
          return (
            v?.fileOwnerId?.toString() ===
              a?.quizQuestionAnswerId?.toString() ||
            v?.oldFileOwnerId?.toString() ===
              a?.quizQuestionAnswerId?.toString()
          );
        }) || ({} as RequestPost)
      );
    },
    [arrayImagens]
  );

  return (
    <div>
      <LoadingDialog open={loading?.loading} message={loading?.message} />
      {question.answers?.length !== 0 && (
        <>
          <Dialog
            open={modal}
            keepMounted
            maxWidth="lg"
            fullWidth
            style={{ zIndex: 900 }}
          >
            {responseQuestion.type && (
              <MediaTitleComponent
                type={responseQuestion.type}
                showMedia={showMedia}
              />
            )}

            {question.answers.some(answer => answer.answerId !== undefined) ? (
              <DialogContent>
                <Grid
                  container
                  spacing={2}
                  columns={{ xs: 4, sm: 8, md: 10 }}
                  sx={{ flexGrow: 1, margin: 2 }}
                >
                  {question.answers.map((a: any, i: any) => {
                    const media = findImagens(a);
                    return (
                      <Grid
                        item
                        key={"answer-" + i}
                        justifyContent="center"
                        alignItems="center"
                        xs={2}
                        sm={4}
                        md={3}
                      >
                        <Card>
                          <Stack
                            padding={0.5}
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Typography>
                              {a.answer?.label || a.answer?.desc}
                            </Typography>

                            <MediaUploadComponent
                              type={responseQuestion.type}
                              showMedia={showMedia}
                              handleChange={handleChange}
                              a={a}
                              media={media}
                            />

                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            ) : (
              <DialogContent>
                <Grid
                  container
                  spacing={2}
                  columns={{ xs: 4, sm: 8, md: 10 }}
                  sx={{ flexGrow: 1, margin: 2 }}
                >
                  {responseQuestion.answers.map((a: any, i: any) => {
                    const media = findImagens(a);
                    return (
                      <Grid
                        item
                        key={"answer-" + i}
                        justifyContent="center"
                        alignItems="center"
                        xs={2}
                        sm={4}
                        md={3}
                      >
                        <Card>
                          <Stack
                            padding={0.5}
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Typography>
                              {a.answer?.label || a.answer?.desc}
                            </Typography>

                            <MediaUploadComponent
                              type={responseQuestion.type}
                              showMedia={showMedia}
                              handleChange={handleChange}
                              a={a}
                              media={media}
                            />

                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            )}

            {responseQuestion.type && (
              <MediaButtonsComponent
                type={responseQuestion.type}
                showMedia={showMedia}
                handleClose={handleClose}
                handleUpload={handleUpload}
              />
            )}
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ResponseModalMedia;
