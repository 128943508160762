import React from "react";
import Button from "@mui/material/Button/";
import Dialog from "@mui/material/Dialog/";
import DialogActions from "@mui/material/DialogActions/";
import DialogContent from "@mui/material/DialogContent/";
import DialogContentText from "@mui/material/DialogContentText/";
import DialogTitle from "@mui/material/DialogTitle/";
import translate from "@noar-firmenich/commons";
import { useSelector } from "react-redux";

export default function ConfirmChangeStatusDialog({
  open,
  closeDialog,
  confirm,
  title,
  message
}) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title && title !== "" ? title : translate(locale).changeStatus.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && message !== ""
              ? message
              : translate(locale).changeStatus.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeDialog}>
            {translate(locale).buttons.cancel}
          </Button>
          <Button variant="contained" onClick={confirm} color="secondary" autoFocus>
            {translate(locale).buttons.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
