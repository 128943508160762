import React from "react";

import "./Styles.scss";

const En = () => {
  return (
    <div className="privacy-page">
      <h2>
        <strong>PRIVACY POLICY</strong>
      </h2>

      <p>
        We at <strong>NOAR</strong> take your privacy and the protection of your
        data seriously and we are committed in fulfilling the guidelines of the
        general data protection law (LGPD) regarding the use of personal data.
        In this sense we developed this <strong>Privacy Notice</strong>, which
        aims to inform you, users and partners from <strong>NOAR</strong>, as we
        process your personal data, what your rights are and how you can
        exercise them.
        <br />
        <br />
        We hope that the information helps you to understand the processing of
        your data and that it demonstrates the
        <strong>NOAR</strong> is committed to privacy, data protection and
        information security.
        <br />
        <br />
        <br />
        <strong>
          1. BASIC CONCEPTS: WHAT DO YOU NEED TO KNOW TO UNDERSTAND THIS POLICY?
        </strong>
        <br />
        <br />
        In order to simplify your reading of this document, we present some
        useful definitions for your interpretation, 13.709/18, also known as the
        general data protection law, or LGPD:
        <br />
        <br />
        <strong>Personal Data:</strong> It is information related to a natural
        person and that is capable of identifying the person or become
        identification possible. Examples of personal data that may allow your
        identification are: Name, CPF, telephone, e-mail, license plate of your
        vehicle etc.;
        <br />
        <br />
        <strong>Processing:</strong> It is all the way we can use your personal
        data, including, but not limiting to following activities: collecting,
        storing, querying, using, sharing, transmitting, classifying,
        reproduction, deletion and evaluation. This term (and others derived
        from it) will not be used with capital letters in this document.
        <br />
        <br />
        <strong>Holder:</strong> It is you, the natural person to whom the
        personal data refer.
        <br />
        <br />
        <strong>Company:</strong> We are NOAR
        <br />
        <br />
        <br />
        <strong>2. HOW DO WE COLLECT PERSONAL DATA IN THE HEALTH APP?</strong>
        <br />
        <br />
        Your personal data may be collected through the following actions,
        namely:
        <ul>
          <li>
            User registration in the HEALTH application, in which we collect the
            user&apos;s name, email, CPF and gender and telephone number.
          </li>
          <br />
          <li>
            Registration of patients in the application, in which we collect the
            name, email, CPF and gender and telephone number of the user.
          </li>
          <br />
          <li>Location of the user when performing an exam</li>
        </ul>
        <br />
        <br />
        <br />
        <strong>3. WHY IS DATA COLLECTED ?</strong>
        <br />
        <br />
        User data is collected to enable app usage and billing. Patient data is
        registered to allow the issuance of the reports of the tests carried
        out.
        <br />
        <br />
        The user's location at the time of the test is used to allow the
        statistical treatment of the reports issued, allowing the elaboration of
        clinical research on its results and its technical improvement.
        <br />
        <br />
        <br />
        <strong>4. DOES NOAR SHARE YOUR PERSONAL DATA?</strong>
        <br />
        <br />
        No, NOAR does not share your personal data. In the case of using data
        collected in clinical studies, a anonymization and aggregation of
        information, preventing any personal identification.
        <br />
        <br />
        <br />
        <strong>5. STORAGE OF PERSONAL DATA</strong>
        <br />
        <br />
        NOAR has a personal data retention policy in line with applicable law.
        Your data will be stored only for the period necessary to fulfill the
        purpose for which they were collected, principally for the performance
        of the contract, unless there is any other reason for maintaining it,
        such as, for example, example, compliance with legal, regulatory,
        contractual obligations and regular exercise of rights.
        <br />
        <br />
        We always perform a technical analysis to determine the appropriate
        retention period for each type of personal data collected, considering
        its nature, need for collection and purpose for which it will be
        treated, as well as possible retention needs for the fulfillment of
        obligations or the protection of rights.
        <br />
        <br />
        In other cases, we will delete our databases or anonymize them, in which
        case you will not can be further identified.
        <br />
        <br />
        <br />
        <strong>6. HOW DO WE PROTECT YOUR PERSONAL DATA?</strong>
        <br />
        <br />
        Our responsibility is to take care of your personal data and use them
        only for the purposes described in this policy. To guarantee your
        privacy and the protection of your personal data, NOAR adopts all
        measures technical and organizational measures for the protection of
        your data. Therefore, we warn you that we will not ask for your
        password, except when you log in to official NOAR channels, and you are
        responsible for keeping your password confidential. platform access.
        <br />
        <br />
        <br />
        <strong>7. HOLDER'S RIGHTS</strong>
        <br />
        <br />
        Personal data are yours and Brazilian law, through the general data
        protection law - LGPD and others legislation, guarantees you a series of
        rights related to them. We are committed to compliance of these rights
        and we will, in sequence, explain what these rights are and how you can
        exercise them:
        <br />
        <br />
        <strong>Confirmation and Access:</strong> You can ask the company to
        confirm the existence of treatment of your data personal so that, if so,
        you can access them, including by requesting copies of the records we
        have about you
        <br />
        <br />
        <strong>Correction:</strong> You can request correction of your personal
        data if it is incomplete, inaccurate or outdated
        <br />
        <br />
        <strong>Anonymization, blocking or deletion:</strong> You can request
        (a) the anonymization of your data personal, so that they can no longer
        be related to you and therefore cease to be personal data; (b) the
        blocking of your personal data, temporarily suspending your ability to
        process them for certain purposes; and (c) to deletion of your personal
        data, in which case we must delete all your personal data without
        possibility of reversal;
        <br />
        <br />
        <strong>Portability:</strong> You may request that the Company provide
        your personal data in a format structured and interoperable with a view
        to its transfer to a third party, provided that such transfer does not
        infringe the proprietary company's intellectual property or business
        secret;
        <br />
        <br />
        <strong>Information regarding sharing:</strong> You have the right to
        know which entities public and private with which the Company makes
        shared use of your personal data. We will maintain, in item 3 of this
        Policy, a indication of our relationships with third parties that may
        involve the sharing of personal data. In any case, if you have questions
        or want more details, you have the right to ask us for this information.
        Depending on the if so, we may limit the information provided to you if
        its disclosure would infringe intellectual property or business secret
        of the Company;
        <br />
        <br />
        <strong>
          Information about the possibility of not consenting:
        </strong>{" "}
        You have the right to receive clear information and complete about the
        possibility and consequences of not providing consent, when requested by
        the company. O their consent, where necessary, must be free and
        informed. Therefore, whenever we ask for your consent, you are free to
        deny it – in these cases, it is possible that some services cannot be
        provided;
        <br />
        <br />
        <strong>Consent revocation:</strong> If you have consented to the
        processing of your personal data, you can always choose to withdraw your
        consent. However, this will not affect the legality of any processing
        carried out prior to revocation. If you withdraw your consent, we may be
        unable to provide you with certain services, but we will notify you when
        this occurs;
        <br />
        <br />
        <strong>Obposition:</strong> The law authorizes the processing of
        personal data even without your consent or a contract with us. in these
        situations, we will only process your personal data if we have
        legitimate reasons to do so, such as, for example, when necessary to
        ensure the safety of our highways. If you do not agree with any purpose
        of processing of your personal data, you may object, requesting its
        interruption.
        <br />
        <br />
        <br />
        <strong>IMPORTANT NOTICES</strong>
        <br />
        <br />
        <strong>
          For your security, whenever you submit a request to exercise your
          rights, the Company may request some additional information and/or
          documents so that we can prove your identity, seeking to prevent
          fraud. We do this to ensure everyone's safety and privacy.
        </strong>
        <br />
        <strong>
          In some cases, the company may have legitimate reasons for failing to
          comply with a request to exercise rights. These situations include,
          for example, cases where a disclosure of specific information could
          violate intellectual property rights or business secrets of the
          company or third parties, as well as cases in which requests exclusion
          of data cannot be met due to the existence of the company's obligation
          to retain data, whether to comply with legal, regulatory obligations
          or to enable the company or third parties to defend themselves in
          disputes in any nature.
        </strong>
        <br />
        <br />
        <strong>
          Also, some requests may not be responded to immediately, but the
          Company undertakes to respond all requests within a reasonable time
          and always in compliance with applicable law.
        </strong>
        <br />
        <br />
        If you have any questions about these issues or how you can exercise
        your rights, please feel free to contact us. to contact us through the
        channels provided at the end of this Policy.
        <br />
        <br />
        <br />
        <strong>8. INTERNATIONAL TRANSFER OF PERSONAL DATA</strong>
        <br />
        <br />
        Some data may be stored in other countries, as NOAR uses or will use the
        cloud-based system computing. If applicable, all data protection
        regulations will be complied with.
        <br />
        <br />
        <br />
        <strong>9. COMMUNICATION CHANNEL</strong>
        <br />
        <br />
        If you believe that your personal data has been treated in a manner
        inconsistent with this policy or your choices as the holder of your
        personal data, or, if you have questions, comments or suggestions
        related to this privacy notice, we request that you contact our
        <strong>In charge data (DPO)</strong>, through the e-mail address{" "}
        <strong>“contato@noarbrasil.com.br”</strong> or by
        <strong>phone number 11 2361-5163.</strong>
        <br />
        <br />
        <br />
        <strong>10. PRIVACY NOTICE UPDATE</strong>
        <br />
        <br />
        As we are always looking to improve our services and the way we operate,
        this privacy notice may be updated at any time by XXXXX to reflect the
        improvements made. In this way, we recommend periodic visit to this page
        so that you are aware of the changes made.
      </p>
    </div>
  );
};
export default En;
