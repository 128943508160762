import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import FormActions from "../../components/FormActions";
import { AppInput, FileUpload } from "../../../../partials/form";
import withFormValidation from "../../../../hoc/withFormValidation";
import { FragranceApiService, CategoryApiService } from "../../../../services";
import SelectSearchComponent from "../../../auth/Components/Select2";
import FormHelper from "../../../../helpers/FormHelper";
import Loading from "../../../home/components/Loading";

import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic";

const initialState = {
  name: "",
  description: "",
  categoryId: "",
  categoryName: "",
  image: "",
  pt: "",
  en: "",
  es: "",
  fr: "",
  de: "",
  it: "",
  secret: "",
  fragranceNameId: "",
  currentUploadedImage: null,
  currentUploadedSecret: null,
  shouldCreateNewCategory: false
};

class FragranceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new FragranceApiService();
    this.categoryApiService = new CategoryApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.props.onCleanForm.bind(this);
    this.formHelper = new FormHelper(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
  }

  componentDidMount() {
    this.formHelper.loadFromService();
  }

  mapModelToState(model) {
    return {
      name: model.name,
      description: model.description,
      categoryId: model.categoryId,
      categoryName: model.category.name,
      currentUploadedImage: model.image,
      currentUploadedSecret: model.secret,
      fragranceNameId: model.fragranceNameId,
      pt: model.fragranceNames.pt,
      en: model.fragranceNames.en,
      es: model.fragranceNames.es,
      fr: model.fragranceNames.fr,
      de: model.fragranceNames.de,
      it: model.fragranceNames.it
    };
  }

  mapStateToModel() {
    const formData = new FormData();
    formData.append("name", this.state.name);

    if(this.state.description) {
      formData.append("description", this.state.description);
    } else {
      formData.append("description", '');
    }
    
    formData.append("description", this.state.description);
    formData.append("categoryId", this.state.categoryId);
    if (this.state.fragranceNameId) {
      formData.append("fragranceNameId", this.state.fragranceNameId);
      formData.append(
        "fragranceNames.fragranceNameId",
        this.state.fragranceNameId
      );
    }

    if(this.state.pt) {
      formData.append("fragranceNames.pt", this.state.pt);
    } else {
      formData.append("fragranceNames.pt", '');
    }

    if(this.state.en) {
      formData.append("fragranceNames.en", this.state.en);
    } else {
      formData.append("fragranceNames.en", '');
    }

    if(this.state.es) {
      formData.append("fragranceNames.es", this.state.es);
    } else {
      formData.append("fragranceNames.es", '');
    }

    if(this.state.fr) {
      formData.append("fragranceNames.fr", this.state.fr);
    } else {
      formData.append("fragranceNames.fr", '');
    }

    if(this.state.de) {
      formData.append("fragranceNames.de", this.state.de);
    } else {
      formData.append("fragranceNames.de", '');
    }

    if(this.state.it) {
      formData.append("fragranceNames.it", this.state.it);
    } else {
      formData.append("fragranceNames.it", '');
    }

    if (this.state.image) {
      formData.append("image.file", this.state.image);
    }

    if (this.state.secret) {
      formData.append("secret.file", this.state.secret);
    }

    return formData;
  }

  async handleChangeCategory(e) {
    this.setState({ categoryName: "", categoryId: "" });
    if (e.target) {
      return this.handleChange(e);
    }

    this.props.showSpinner();
    try {
      const category = await this.categoryApiService.create({ name: e.value });
      this.setState({
        categoryId: category.categoryId,
        categoryName: category.name
      });
    } catch (e) {
      Swal.fire(
        this.props.translate.defaultMessages.error,
        e.response && e.response.status === 400
          ? e.response.data
          : this.props.translate.screens.fragrance.categories.createError,
        "error"
      );
    }

    this.props.hideSpinner();
  }

  handleChargeFragranceNames = e => {};

  render() {
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${this.props.translate.screens.fragrance.pageTitle.edit} - Admin`
      );
    } else {
      window.setPageTitle(
        `${this.props.translate.screens.fragrance.pageTitle.create} - Admin`
      );
    }

    const {
      name,
      description,
      categoryId,
      currentUploadedImage,
      image,
      de,
      en,
      es,
      fr,
      pt,
      it
    } = this.state;

    const defaultImagePath = require("../../../../../assets/images/defaultImageFragrance.png");

    return (
      <Card className="mt-3">
        <Loading isLoading={this.state.isLoading} />
        <Card.Header>
          {this.props.translate.screens.fragrance.mainData}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.formHelper.submit}>
            <Row>
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.category} *
                </Form.Label>
                <SelectSearchComponent
                  handleChange={this.handleChangeCategory}
                  name="categoryId"
                  className="kt-width-full"
                  label={
                    this.props.translate.screens.fragrance.placeholders
                      .selectCategory
                  }
                  resetInput={
                    !this.state.categoryId && !this.state.categoryName
                  }
                  url={`/categories/quick-search?str=`}
                  value={
                    this.state.categoryName
                      ? { label: this.state.categoryName }
                      : undefined
                  }
                  convertObject={obj => ({
                    id: obj.categoryId,
                    value: obj.categoryId,
                    label: obj.name,
                    target: {
                      value: obj.categoryId,
                      name: "categoryId"
                    }
                  })}
                  newOption={this.state.shouldCreateNewCategory}
                  setNewOption={shouldCreateNewCategory =>
                    this.setState({ shouldCreateNewCategory })
                  }
                  createLabelText={
                    this.props.translate.screens.fragrance.labels.create
                  }
                />

                {this.props.validator.message(
                  "categoryId",
                  categoryId,
                  "required"
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}
                  {" *"}
                </Form.Label>
                <AppInput
                  type="text"
                  name="name"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={name}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|min:3|max:100"}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}{" "}
                  {this.props.translate.screenApp.menu.languages.portuguese.toLowerCase()}
                </Form.Label>
                <AppInput
                  type="text"
                  name="pt"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={pt}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|min:3|max:100"}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}{" "}
                  {this.props.translate.screenApp.menu.languages.english.toLowerCase()}
                </Form.Label>
                <AppInput
                  type="text"
                  name="en"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={en}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|min:3|max:100"}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}{" "}
                  {this.props.translate.screenApp.menu.languages.spanish.toLowerCase()}
                </Form.Label>
                <AppInput
                  type="text"
                  name="es"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={es}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|min:3|max:100"}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}{" "}
                  {this.props.translate.screenApp.menu.languages.french.toLowerCase()}
                </Form.Label>
                <AppInput
                  type="text"
                  name="fr"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={fr}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|min:3|max:100"}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}{" "}
                  {this.props.translate.screenApp.menu.languages.german.toLowerCase()}
                </Form.Label>
                <AppInput
                  type="text"
                  name="de"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={de}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|min:3|max:100"}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.name}{" "}
                  {this.props.translate.screenApp.menu.languages.italian.toLowerCase()}
                </Form.Label>
                <AppInput
                  type="text"
                  name="it"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders.name
                  }
                  maxLength="100"
                  value={it}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|min:3|max:100"}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {this.props.translate.screens.fragrance.labels.description}
                </Form.Label>
                <AppInput
                  type="text"
                  name="description"
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders
                      .description
                  }
                  maxLength="255"
                  value={description}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"max:255"}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <FileUpload
                  label={this.props.translate.screens.fragrance.labels.image}
                  placeholder={
                    this.props.translate.screens.fragrance.placeholders
                      .selectImage
                  }
                  defaultImagePath={defaultImagePath}
                  name="image"
                  file={image}
                  currentUploadedFile={currentUploadedImage}
                  handleChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required"}
                  accept={"image/*"}
                  translate={this.props.translate}
                />

              </Form.Group>
            </Row>

            <FormActions
              module="fragrances"
              formIsValid={true}
              onCleanForm={this.onCleanForm}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default withRouter(withFormValidation(FragranceForm, initialState));
