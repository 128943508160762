import React from "react";
import { Button, DialogActions } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  type: string;
  showMedia: boolean;
  handleClose: () => void;
  handleUpload: () => void;
}

const MediaButtonsComponent = ({
  type,
  showMedia,
  handleClose,
  handleUpload
}: Props) => {
  const translate = useTranslation();
  const closeButtonLabel = translate("buttons.close");

  let uploadButtonLabel;
  if (type === "image") {
    uploadButtonLabel = showMedia
      ? closeButtonLabel
      : translate("buttons.saveImage");
  } else if (type === "audio") {
    uploadButtonLabel = showMedia
      ? closeButtonLabel
      : translate("button_saveAudio");
  } else if (type === "video") {
    uploadButtonLabel = showMedia
      ? closeButtonLabel
      : translate("button_saveVideo");
  }

  return (
    <DialogActions>
      <Button
        sx={{ flex: 1 }}
        onClick={showMedia ? handleClose : handleUpload}
        variant="contained"
        color="success"
      >
        {uploadButtonLabel}
      </Button>
    </DialogActions>
  );
};

export default MediaButtonsComponent;
