
import React from 'react';
import { debounce } from "lodash";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import {connect} from 'react-redux';
import translate from '@noar-firmenich/commons';

class SelectSearchComponent extends React.Component {

  constructor(props) {
    super(props);
    this.api = new AnanseApiService();
  }

  getListFromValue = async (inputValue) => {
    let searchedList = [];
    if (inputValue && inputValue.length >= 3) {
      try {
        const searchedData = await this.api.makeHttpRequest({
          url: this.props.url + encodeURIComponent(inputValue)
        })

        if (searchedData && searchedData.length > 0) {
          searchedData.forEach((item) => {
            const converted = this.props.convertObject(item);
            if (searchedList.indexOf(converted) === -1) {
              searchedList.push(converted)
            }
          });
        }
      } catch (error) {
        // console.error(`Erro ao fazer a busca: ${error.message}`);
      }
    }

    return searchedList
  }

  promiseOptions = debounce((inputValue, callback) => {
    this.getListFromValue(inputValue)
      .then(callback);
  }, 500);

  render() {
    const { disabled, className, label, handleChange, value, newOption, required, resetInput, setNewOption, createLabelText } = this.props;
    return (
      <AsyncCreatableSelect
        value={resetInput? "" : value}
        cacheOptions
        defaultOptions
        isDisabled={disabled}
        loadOptions={this.promiseOptions}
        placeholder={label}
        noOptionsMessage={() => translate(this.props.i18n.lang).selectComponent.nothingFound}
        loadingMessage={() => value && value.length >= 3
          ? translate(this.props.i18n.lang).defaultMessages.loading
          : translate(this.props.i18n.lang).selectComponent.minChar}
        className={className}
        onChange={(e) => handleChange(e)}
        onInputChange={(e) => e && setNewOption ? setNewOption(true):setNewOption ? setNewOption(false) : null}
        isValidNewOption={e => e.length > 2 && newOption}
        createOptionPosition={"first"}
        formatCreateLabel={(inputValue) => inputValue? createLabelText? `${createLabelText} ${inputValue}` : `${translate(this.props.i18n.lang).selectComponent.create} ${inputValue}` : inputValue}
        styles={required && required.status? {
          control: base => ({
            ...base,
            borderColor: required.color? required.color : '#FF0000',
          }),
        }: {control: base => ({...base})}}
      />
    );
  }
}

const mapStoreToProps = store => ({
  i18n : store.i18n
});

export default connect(mapStoreToProps, null)(SelectSearchComponent);
